import { SDTObject } from "davel";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { View } from "react-native";
import { extraSmallSpacer } from "../kelp-bar/styles";
import { TextSubTitle } from "../kelp-bar/text";
import { capitalizeAllWords, getShortName } from "./davel-utils";
import { Paragraph } from "../kelp-bar/constants";

//  TODO: Remove any type?
//  TODO:  Change to SDTValueRenderer, I think that makes a little more sense.
//  TODO:  Remove any type?

export interface SDTRendererParams<T = any, M = any> {
  sdt: T;
  topLevel?: boolean;
  metadata?: M;
  value?: any;
  autoSubmit?: boolean;
  key?: string;
  update: (value: any) => void;
  name?: string;
  getSchemaFromType?: (type: string) => SDTObject | undefined;
}
export type SDTRenderer = (params: SDTRendererParams) => React.ReactElement<any>;

export interface SDTViewRendererParams<T = any> {
  sdt: T;
  value: any;
  name: string;
  metadata?: any;
  topLevel?: boolean;
}
export type SDTViewRenderer = (params: SDTViewRendererParams) => Promise<React.ReactElement<any>>;

interface DavelFieldProps {
  required?: boolean;
  style?: any;
  type?: 'info' | 'warning' | 'error';
  children: any;
  name: string;
}

//  TODO!!:  Davel should NOT have hard-coded styles (other than the default), and should accept styles from the host app.
//  TODO:  Define a TS definition file for create a custom styled Davel Form!
export const DavelField = ({ required, style, children, type = 'info', name }: DavelFieldProps) => (
  <ErrorBoundary fallback={<Paragraph>An error occurred while rendering the '{ name }' field.</Paragraph>}>
    <View style={[{ backgroundColor: '#fdfdfd', borderRadius: 15, padding: 15, display: 'flex', flexDirection: 'column' }, style]}>
      <TextSubTitle style={{ color: '#333333', fontSize: 16, fontFamily: 'Poppins-Medium', paddingLeft: 1 }}>{capitalizeAllWords(getShortName(name))}</TextSubTitle>
      <TextSubTitle style={{ color: '#888888', fontSize: 12, fontFamily: 'Poppins-Medium', paddingLeft: 1 }}>{required ? 'REQUIRED' : 'OPTIONAL'}</TextSubTitle>
      <View style={{ height: extraSmallSpacer }} />
      {children}
    </View>
  </ErrorBoundary>
);
