import Amplify, { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Clipboard, ScrollView, Text, TextInput, TouchableOpacity } from "react-native";
import { View } from 'react-native-web';
import { AuthContext } from "../../packages/kelp-bar/auth";
import { Picker } from '../../packages/kelp-bar/picker';
import { primaryFontFamily, primaryFontFamilyHeavy } from '../../packages/kelp-bar/styles';
import { SystemHeader } from '../../packages/kelp-bar/system-header';
import { awsConfig, Header } from '../gallery/constants';
import { getConfigFromStorage, getCredentials, HessiaConfig, setConfigInStorage } from './config.service';
import { EntityContext } from "./plugins/entity-plugin";
import { GroupCard } from '../../packages/kelp-bar/group-card';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Paragraph, SubHeader } from '../../packages/kelp-bar/constants';
import { LogContext, LogProvider } from './LogProvider';

//  TODO:  Replace!
Amplify.configure(awsConfig);

//  CONSIDER:  Should this be INJECTED by the "Entity" Plugin??
//  CONSIDER:  Could / should we use a NounService instead of doing this manually??

//
//  Storage Helpers
//

//  REFERENCE:  GPT4
async function sizeofLocalStorageBytes() {
  let total = 0;
  for (let key in localStorage) {
    if (localStorage.hasOwnProperty(key)) {
      // Each character takes 2 bytes, so multiply by 2
      const value = await AsyncStorage.getItem(key);
      if (value) {
        total += (value?.length + key.length) * 2;
      }
    }
  }
  return total;
}


async function sizeOfLocalStorageMB () {
  const bytes = await sizeofLocalStorageBytes();
  const mb = bytes / (1024 * 1024);
  return mb;
}

const maxCapacityMB = 5;
const maxCapacityBytes = 5 * 1024 * 1024; // 5 MB in bytes

//  REFERENCE:  GPT4
async function remainingLocalStorage() {
  const usedBytes = await sizeofLocalStorageBytes();
  const remainingBytes = maxCapacityBytes - usedBytes;
  const remainingMB = remainingBytes / (1024 * 1024); // Convert bytes back to MB
  return remainingMB;
}


export interface Profile {
  username: string;
  email?: string;
  sub?: string;
  identityId: string;
}

/**
 * NOTE:  Partially Generated by GPT
 * TODO:  Make a library of AWS / Amplify tools.
 * @returns 
 */

//  NOTE:  I HATE that I can't see WHERE and WHY a Network request is being made, or a UI component being shown, etc.  I need to be able to get immediate answers to these things.  I need to go back to CS.
export const useProfile = () => {
  const [userInfo, setUserInfo] = useState<Profile | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const credentials = await Auth.currentCredentials();
        const identityIdFromIdentityPool = credentials.identityId;
        const { username, attributes } = user;
        setUserInfo({ username, identityId: identityIdFromIdentityPool, ...attributes });
      } catch (error) {
        console.error('Error fetching user info:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  return userInfo;
};

export const DebugPanel = () => {

  const { authState } = React.useContext(AuthContext);
  const { events, eventTypes } = React.useContext(LogContext);

  return (
    <View style={{ height: 30, alignItems: 'flex-start', justifyContent: 'center', paddingHorizontal: 15, borderTopColor: '#eeeeee', borderTopWidth: 1, backgroundColor: '#fafafa' }}>
      <Paragraph>{authState.user ? "Logged In" : "Logged Out"}</Paragraph>

      <SubHeader>Events</SubHeader>
      <Paragraph>{ JSON.stringify(events) }</Paragraph>

      <SubHeader>Event Types</SubHeader>
      <Paragraph>{ JSON.stringify(eventTypes) }</Paragraph>
    </View>
  );
}

export const Settings = () => {

  const [storedConfig, setStoredConfig] = React.useState<HessiaConfig | undefined>(undefined);
  const [_config, _setConfig] = React.useState<HessiaConfig | undefined>(undefined);
  const { syncState } = React.useContext(EntityContext);
  const profile = useProfile();
  const previousConfigRef = React.useRef<HessiaConfig | undefined>(undefined);

  const updateConfig = async () => {
    if (!_config) { return; }
    if (_config?.storage === 'cloud') {
      _setConfig({ ..._config, s3Config: undefined });
      previousConfigRef.current = _config;
    }
  }

  const loadConfig = async () => {
    const _storedConfig = await getConfigFromStorage();

    //  Cloud - If we're loading from the cloud, update the credentials.
    if (_storedConfig?.storage === 'cloud') {
      const credentials = await getCredentials();
    }
    _setConfig(_storedConfig);
    setStoredConfig(_storedConfig);
    previousConfigRef.current = _storedConfig;
  }

  React.useEffect(() => {
    loadConfig();
  }, []);

  React.useEffect(() => {
    if (JSON.stringify(previousConfigRef.current) !== JSON.stringify(_config)) {
      updateConfig();
    }
  }, [_config]);

  const { logout } = React.useContext(AuthContext);

  const logoutHessia = async () => {
    await AsyncStorage.clear();
    logout();
  }

  const [size, setSize] = React.useState(0);
  const [remaining, setRemaining] = React.useState(0);

  const setSizes = async () => {
    const _remaining = await remainingLocalStorage();
    const _size = await sizeOfLocalStorageMB();
    setSize(_size);
    setRemaining(_remaining);
  };

  React.useEffect(() => {
    setSizes();
  }, []);


  if (!_config) {
    return <Text>Loading Config</Text>
  }

  const saveSettings = async () => {
    await setConfigInStorage(_config);
    setStoredConfig(_config);
  };

  // const awsEnabled = _config?.storage == 'aws';
  const dirty = (JSON.stringify(_config) != JSON.stringify(storedConfig));

  // const toggleAws = () => {
  //   _setConfig({ ..._config, storage: _config.storage === 'aws' ? 'local' : 'aws' });
  // }

  const updateS3Config = (field: string, value: string) => {
    const s3Config = _config.s3Config || { bucket: "", bucketKey: "", region: "", credentials: {} };
    _setConfig({ ..._config, s3Config: { ...s3Config, [field]: value } });
  }


  /**
   * TODO:  Display PROFILE Picture.
   * TODO:  Display USERNAME
   * TODO:  Update EMAIL
   */

  const pick = (value: "custom" | "local" | "cloud") => {
    _setConfig({ ..._config, storage: value });
  }

  return (
    <View style={{ backgroundColor: 'white', flex: 1 }}>

      <SystemHeader breadcrumbs={false} system={{ name: "Account", icon: { type: "feather", name: "user" } }} />

      <Text>Total Size (MB):  {maxCapacityMB}</Text>
      <Text>Used (MB): {size}</Text>
      <Text>Remaining (MB): {remaining}</Text>

      <ScrollView style={{ padding: 30 }}>
        {/* We want to show the user... there are three possiblities?  We are LOGGED OUT, or we're LOGGED IN.  Then, it's up to us what we do...  */}

        {
          profile && profile.email && (
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Text style={{ fontFamily: primaryFontFamily }}>Logged in as: </Text>
              <View style={{ marginVertical: 10, width: 'auto', backgroundColor: '#d4e9ff', borderRadius: 15, height: 30, paddingHorizontal: 15, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontFamily: primaryFontFamily, color: '#5cacff', marginLeft: 5 }}>{profile.email}</Text>
              </View>
            </View>

          )
        }

        {
          profile && profile.username && (
            <TouchableOpacity onPress={() => { Clipboard.setString(profile.username); alert("Saved!"); }} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Text style={{ fontFamily: primaryFontFamily }}>Logged in as: </Text>
              <View style={{ marginVertical: 10, width: 'auto', backgroundColor: '#d4e9ff', borderRadius: 15, height: 30, paddingHorizontal: 15, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontFamily: primaryFontFamily, color: '#5cacff', marginLeft: 5 }}>{profile.username}</Text>
              </View>
            </TouchableOpacity>

          )
        }

        {
          profile && profile.sub && (
            <TouchableOpacity onPress={() => { Clipboard.setString(profile?.sub || ""); alert("Saved!"); }} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Text style={{ fontFamily: primaryFontFamily }}>Logged in as: </Text>
              <View style={{ marginVertical: 10, width: 'auto', backgroundColor: '#d4e9ff', borderRadius: 15, height: 30, paddingHorizontal: 15, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontFamily: primaryFontFamily, color: '#5cacff', marginLeft: 5 }}>{profile.sub}</Text>
              </View>
            </TouchableOpacity>

          )
        }

        <Header style={{ fontFamily: primaryFontFamilyHeavy }}>Sources</Header>

        <Text style={{ fontFamily: primaryFontFamilyHeavy, marginLeft: 5 }}>Cloud</Text>
        <GroupCard>
          <Text>This is your space in the Hessia Cloud</Text>
          {profile ? <Text>Because you're logged in, you have a space!</Text> : <Text>You are not logged in to Hessia.</Text>}
        </GroupCard>

        <Text style={{ fontFamily: primaryFontFamilyHeavy, marginLeft: 5 }}>Local</Text>
        <GroupCard>
          <Text>The local space is where data is processed and is always enabled.</Text>
        </GroupCard>

        <Text style={{ fontFamily: primaryFontFamilyHeavy, marginLeft: 5 }}>Custom</Text>
        <GroupCard>
          <Text>Used to send to your OWN AWS bucket:</Text>
          <Text>Use Custom Bucket?</Text>
          {/* <Switch value={awsEnabled} onValueChange={toggleAws} /> */}
          <TextInput placeholder="Bucket" value={_config.s3Config?.bucket} onChangeText={(value) => updateS3Config("bucket", value)} />
          <TextInput placeholder="Bucket Key" value={_config.s3Config?.bucketKey} onChangeText={(value) => updateS3Config("bucketKey", value)} />
          <TextInput placeholder="Key" value={_config.s3Config?.credentials.key} onChangeText={(value) => updateS3Config("key", value)} />
          <TextInput placeholder="Region" value={_config.s3Config?.region} onChangeText={(value) => updateS3Config("region", value)} />
          <TextInput placeholder="Secret" value={_config.s3Config?.credentials.secret} onChangeText={(value) => updateS3Config("secret", value)} />

          <Picker grow={true} value={_config.storage} onChange={pick} items={[{ value: 'local', label: 'Local' }, { value: 'cloud', label: 'Cloud' }, { value: 'custom', label: 'Custom' }]} />
        </GroupCard>

        {
          dirty && <Button title="Save" onPress={saveSettings} />
        }

        <Text>Saved?</Text>
        <Text>{syncState}</Text>

        <Header>Debug</Header>
        <DebugPanel />

        <Button title="Logout" onPress={() => { logoutHessia() }} />
      </ScrollView>
    </View>
  );
}