import { createStackNavigator } from "@react-navigation/stack";
import Amplify from 'aws-amplify';
import { CorePluginClass } from "halia";
import { HaliaComponentPlugin, registerHOC, registerHOCRegister, removeHOC } from 'halia-native';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from "../../packages/kelp-bar/auth";
import { useSizes } from "../../packages/kelp-bar/sizes-helper";
import { primaryFontFamilyHeavy } from '../../packages/kelp-bar/styles';
import { awsConfig } from '../gallery/constants';
import { Authenticate } from "./authenticate";
import { HessiaProvider } from './HessiaProvider';
import { EventType, LogContext } from "./LogProvider";
import { LeftPanel, MainPanel, NavContext } from './NavigationProvider';
import { EntityPluginContext, EntitySystem } from "./plugins/entity-plugin";
import { Icon, Image, Pressable, Text, View } from './react-native-hessia';
import { SearchBar, SearchResults } from "./search-bar";
import { Settings } from "./Settings";
import { System } from "./systems";
import { Set, Widget, WidgetWrapper } from './utils';

Amplify.configure(awsConfig);

/**
 * The Hessia Plugin!
 * 
 * This is the Plugin which mounts Hessia into the Halia App and makes it possible to register SYSTEMS as the FUNDAMENTAL thing.
 * These are simlar to "Apps" in some ways, but where a system doesn't NEED a front-end.  They are a space injected with RESOURCES and the INSTALLER can do whatever it wants with it.
 * 
 */
export class Hessia2Plugin extends CorePluginClass {

  public static details = {
    name: "Hessia",
    description: "Hessia 2 Core Plugin",
    dependencies: [HaliaComponentPlugin.details.id],
    id: "hessia2"
  }

  //  DONE!:  Get a NEW token when we are configured for cloud!
  //  DONE!:  Do NOT store S3 Config in storage unless it's custom!
  //  DONE!!!:  Use Amplify when we are configured as authenticated so we can get the new tokens!
  //  TODO:  Scope local config to USER (either Guest or Authenticated)
  //  TODO:  IF we are logged in with Cognito, THEN we should use THOSE credentials instead of the default set!!!

  public registerContext = (Context: React.Context<any>, initialState: any) => {
    this.registerHOC(({ children }) => (
      <Context.Provider value={initialState}>
        {children}
      </Context.Provider>
    ))
  };

  public registerHOC = (wrapper: any) => {
    registerHOC("hessia2", wrapper);
  }

  public removeHOC = (wrapper: any) => {
    removeHOC("hessia2", wrapper);
  }

  //  CONSIDER:  Do NOT like "ManuLayout" Plugin... it's essentially a component that we're using to wrap the registerNode thing.  Instead, we can have Hessia just couple with it directly.  We can also have Hessia just be its OWN app without Halia plugins  Then we CAN have a separate app for every other one!  The WHOLE idea though was to minimize differences in deployment between apps and stuff hmm... Makes for a larger build though hmm... 

  public install = async (program: any, { haliaComponentPlugin }: { haliaComponentPlugin: HaliaComponentPlugin }) => {
    registerHOCRegister("hessia2");
    haliaComponentPlugin.registerChildComponent(() => <HessiaProvider hessia2Plugin={this} />);
    return this;
  }
}

//  CONSIDER:  We often hav ethe SAME thing but in different settings.. in this case, we want to inject a Menu, but whether top or bottom is determined by screen size... which dynamically changes the code path.  CAN have one component adjust OR can have two components... in a sense, it's the SAME thing... IF we can map each piece of the component to the other, then it's a false dichotomy.

//  CONSIDER:  I feel like I LIKE the idea of adding explicit widgets to panels... but I'm not totally crazy about having left panel and all that as a combined window system.  Maybe OVERLAY these thigns?  Like a LeftPanel system and also a Content system? Hmm...

const HessiaLeftPanel = () => {
  const { leftMenuWidgetSet: { add, items } } = React.useContext(Hessia2Context);
  return (
    <View style={{ flex: 1, backgroundColor: 'white', padding: 15 }}>
      {
        items.map(_item => <WidgetWrapper editMode={false} widget={_item} />)
      }
    </View>
  );
}

const HessiaLeftPanelWidget: Widget = {
  id: "hessia-left-panel-widget",
  name: "Hessia Left Panel Widget",
  description: "Content for the Left Panel",
  component: HessiaLeftPanel,
  pluginId: Hessia2Plugin.details.id
};


const HessiaMenuBottom = () => {

  const { setSelectedView, showPanel, setShowPanel } = React.useContext(NavContext);
  const entityPlugin = React.useContext(EntityPluginContext);

  return (
    <View style={{ height: 60, width: '100%', backgroundColor: 'white', flexDirection: 'row', borderBottomColor: '#eeeeee', paddingHorizontal: 60, borderTopColor: '#eeeeee', borderTopWidth: 1, alignItems: 'center' }}>

      <Icon onPress={() => setSelectedView({ name: "settings" })} style={{ flex: 1 }} type="feather" name="user" size={22} color="#555555" />
      <View style={{ flex: 1 }} />

      <Icon onPress={() => setSelectedView({ name: "extensions" })} style={{ flex: 1 }} type="feather" name="box" size={22} color="#555555" />
      <View style={{ flex: 1 }} />

      <Icon onPress={async () => { const entity = await entityPlugin?.entityService.createEntity({ id: uuidv4(), owners: [] }); setSelectedView({ name: EntitySystem.name }, { selectedEntity: entity }); }} style={{ flex: 1 }} type="feather" name="plus-circle" size={22} color="#555555" />
      <View style={{ flex: 1 }} />

      <Icon onPress={() => setSelectedView({ name: "search" })} style={{ flex: 1 }} type="feather" name="search" size={22} color="#555555" />
      <View style={{ flex: 1 }} />

      <Icon onPress={() => setShowPanel(!showPanel)} style={{ flex: 1 }} type="feather" name="menu" size={22} color="#555555" />

    </View>
  );
}

const HessiaMenuBottomWidget: Widget = {
  id: "hessia-menu-bottom-widget",
  name: "Hessia Menu Bottom Widget",
  description: "Hessia Bottom Widget",
  component: HessiaMenuBottom,
  pluginId: Hessia2Plugin.details.id
};


export const HessiaMenuTop = () => {

  const { setSelectedView, showPanel, setShowPanel } = React.useContext(NavContext);
  const entityPlugin = React.useContext(EntityPluginContext);

  return (
    <View style={{ height: 60, width: '100%', backgroundColor: 'white', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1 }}>

      {/* Logo */}
      <View style={{ height: '100%', width: 250, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20, borderRightWidth: 0, borderRightColor: '#eeeeee', borderBottomColor: '#eeeeee', borderBottomWidth: 0 }}>
        <Image style={{ height: 35, width: 35, borderRadius: 15, marginLeft: 0 }} source={require("../../assets/stickers/Hessia Logo 6.png")} />
        <View style={{ width: 12 }} />
        <Text style={{ fontFamily: 'Outfit-SemiBold', fontWeight: "400", fontSize: 20, color: '#666666' }}>Hessia</Text>
      </View>


      <View style={{ flex: 1, backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', paddingHorizontal: 30, borderTopColor: '#eeeeee', borderTopWidth: 1 }}>

        <SearchBar style={{ flex: 1 }} />

        <View style={{ flex: 1 }} />

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>

          <Icon style={{ marginHorizontal: 10 }} onPress={() => setShowPanel(!showPanel)} type="feather" name="sidebar" size={22} color="#555555" />
            {/* NOTE:  Used to be "container" and it looks cool! */}
          <Icon style={{ marginHorizontal: 10 }} onPress={() => setSelectedView({ name: "extensions" })} type="feather" name="box" size={22} color="#555555" />
          <Icon style={{ marginHorizontal: 10 }} onPress={async () => { const entity = await entityPlugin?.entityService.createEntity({ id: uuidv4(), owners: [] }); setSelectedView({ name: EntitySystem.name }, { selectedEntity: entity }); }} type="feather" name="plus-circle" size={22} color="#555555" />
          <Icon style={{ marginLeft: 10 }} onPress={() => setSelectedView({ name: "settings" })} type="feather" name="user" size={22} color="#555555" />

        </View>

      </View>
    </View>
  );
}

const HessiaMenuTopWidget: Widget = {
  id: "hessia-menu-Top-widget",
  name: "Hessia Menu Top Widget",
  description: "Hessia Top Widget",
  component: HessiaMenuTop,
  pluginId: Hessia2Plugin.details.id
};

//  TODO:  Consider making this more complex for MULTIPLE windows each with different settings and track the window DEPENDENCIES as windows choose to launch child-windows.


const HessiaWorkspace = () => {

  const { systems } = React.useContext(Hessia2Context);

  const systemRef = React.useRef(systems);
  systemRef.current = systems;


  const { isDesktop } = useSizes();


  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row' }}>
        {isDesktop && <WidgetWrapper style={{ width: '100%' }} editMode={false} widget={HessiaMenuTopWidget} />}
      </View>
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <LeftPanel />
        <MainPanel />
      </View>
      {!isDesktop && <WidgetWrapper style={{ width: '100%' }} editMode={false} widget={HessiaMenuBottomWidget} />}
    </View>
  );
}
const StackNav = createStackNavigator();


export const HessiaMountedEvent: EventType = { name: "Hessia Mounted", description: "HessiaApp Mounted", id: "hessia-mounted" };
export const HessiaAuthenticatedEvent: EventType = { name: "Hessia Authenticated", description: "HessiaApp Authenticated", id: "hessia-authenticated" };

export const HessiaApp = () => {

  // CONSIDER:  SHOULD be able to INJECT login stuff with a pluign instead of having it here explicitly.  Enable it as a composable feature.

  const { registerEventType, logEvent } = React.useContext(LogContext);

  const { authState } = React.useContext(AuthContext);
  const { systems, leftMenuWidgetSet } = React.useContext(Hessia2Context);
  const { registerView } = React.useContext(NavContext);

  //  TODO:  Remember the AMAZING functional invocation / callstack I had in Habor!??  I'd REALLY like to do something like that here!!!

  React.useEffect(() => {

    //  Log Authenticated Event
    if (authState.user) {
      logEvent({ eventType: HessiaAuthenticatedEvent.id, time: new Date() });
    }

  }, [authState]);

  React.useEffect(() => {

    //  Register System Views
    systems.forEach(system => {
      registerView({
        id: system.id,
        name: system.name,
        component: system.component,
        pluginId: system.id  //  TODO:  Also note the fact that Hessia2Plugin added it ON BEHALF.
      });
    });
  }, [systems]);


  React.useEffect(() => {

    //  Register Log Events
    registerEventType(HessiaMountedEvent);
    registerEventType(HessiaAuthenticatedEvent);

    //  Log Mount Event
    logEvent({ eventType: HessiaMountedEvent.id, time: new Date() });

    registerView({
      id: "search",
      name: "search",
      component: SearchResults,
      pluginId: Hessia2Plugin.details.id
    });

    registerView({
      id: "settings",
      name: "settings",
      component: Settings,
      pluginId: Hessia2Plugin.details.id
    });

    //  TODO:  We SHOULD be able to see these views in Hessia as WELL as their usage!
    registerView({
      id: "left-panel",
      name: "left-panel",
      component: () => <WidgetWrapper widget={HessiaLeftPanelWidget} editMode={false} />,
      pluginId: Hessia2Plugin.details.id
    });

    leftMenuWidgetSet.add(SystemSelectorWidget);
  }, []);

  return (
    <>
      {
        authState.user ? <HessiaWorkspace /> : (
          <StackNav.Navigator screenOptions={{ headerShown: false }}>
            <StackNav.Screen name="Authenticate" component={({ navigation, route }) => <Authenticate mode={route?.params?.mode} />} />
          </StackNav.Navigator>
        )
      }
    </>
  );
}

export interface Hessia2Context {
  installSystem: (system: System, navigate?: boolean) => void;
  getSystemById: (id: string) => System | undefined;
  systems: System[];
  selectSystem: React.Dispatch<React.SetStateAction<System | undefined>>;
  selectedSystem?: System;
  hessia2Plugin?: Hessia2Plugin;
  leftMenuWidgetSet: Set<Widget>;
}

export const Hessia2Context = React.createContext<Hessia2Context>({ leftMenuWidgetSet: { add: () => null, items: [] }, selectSystem: (value) => null, selectedSystem: undefined, installSystem: (system, navigate) => null, getSystemById: (id: string) => undefined, systems: [] });


//  CONSIDER:  Why do we need to register this?  Can we do it dynamically based on id passed to the HOC register?  
//  TODO:  We use an HOCRegister so we can mount additional components.  HOWEVER, this breaks isolation as only one can be registered.  We will want to fix that!

//  TODO:  Make halia MODULES that auto-register with REACT.  The idea is, EVERYTHING should be in context.  If we inject something into a parent, then the issue is, we need to make sure it still has access to our context... perhaps that's where we can use portals?  Instead of injecting raw components, we can use a portal? hmm... 


export const SystemSelector = () => {

  const { setSelectedView } = React.useContext(NavContext);
  const { systems, selectedSystem } = React.useContext(Hessia2Context);
  const [collapsed, setCollapsed] = React.useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  }

  const SystemSelectionWidgets: Widget[] = systems.map(system => ({
    id: system.id + "-selector",
    name: `${system.name} Selector`,
    pluginId: system.id, //  TODO:  Needs more nuance for the system that's injecting AND Hessia 2
    component: () => (
      <Pressable onPress={() => { setSelectedView({ name: system.name }); }} style={{ paddingHorizontal: 7, alignItems: 'center', justifyContent: 'flex-start', height: 40, flexDirection: 'row', borderColor: '#eeeeee', borderWidth: 0, marginVertical: 3, borderRadius: 10, marginLeft: 10 }}>
        <Icon size={18} type={system.icon.type} name={system.icon.name} color={selectedSystem?.id === system.id ? "#f63d75" : "#666666"} />
        <Text style={{ fontFamily: 'Outfit-SemiBold', color: selectedSystem?.id === system.id ? "#f63d75" : "#888888", fontSize: 16, marginLeft: 20 }}>{system.name}</Text>
      </Pressable>
    )
  }))

  return (
    <Pressable onPress={toggle} style={{ width: '100%', borderRadius: 10, borderWidth: collapsed ? 1 : 0, borderColor: '#eeeeee' }}>
      <View style={{ height: 40, paddingHorizontal: 15, alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', marginBottom: 2 }}>
        <Icon style={{ marginRight: 20 }} name="grid" type="feather" color="#777" size={18} />
        <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 16, color: "#555" }}>Apps</Text>
        <View style={{ flex: 1, minWidth: 35 }} />
        <View style={{ height: 22, paddingHorizontal: 7, alignItems: 'center', justifyContent: 'center', borderRadius: 5, borderWidth: 1, borderColor: '#eee' }}>
          <Text style={{ fontFamily: 'Outfit-SemiBold', fontSize: 14, color: "#888" }}>{systems.length}</Text>
        </View>
        <Icon style={{ marginLeft: 5 }} name={collapsed ? "chevron-left" : "chevron-down"} type="feather" color="#ddd" size={18} />
      </View>

      {
        !collapsed && SystemSelectionWidgets.map(widget => <WidgetWrapper editMode={false} widget={widget} />)
      }

    </Pressable>
  )
};

export const SystemSelectorWidget: Widget = {
  id: "system-selector-widget",
  name: "System Selector",
  pluginId: Hessia2Plugin.details.id,
  component: SystemSelector
};
