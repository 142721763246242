import AsyncStorage from '@react-native-async-storage/async-storage';
import { SDTObject } from 'davel';
import { EventEmitter } from 'events';
import { CorePluginClass, Program } from 'halia';
import { TestPlugin } from 'halia-native';
import * as React from 'react';
import { Button, ScrollView, Text, TouchableOpacity } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { AaroLandingPlugin } from '../aaro-landing/aaro-landing';
import { AaroPlugin } from '../aaro/aaro-core';
import { AssistantAaroExtension } from '../aaro/extensions/assistant.extension';
import { Category2Plugin } from '../aaro/extensions/category.extension';
import { CheckinAaroExtension } from '../aaro/extensions/checkin.extension';
import { DashboardPlugin } from '../aaro/extensions/dashboard.extension';
import { DietAaroExtension } from '../aaro/extensions/diet.extension';
import { HabitPlugin } from '../aaro/extensions/habits.extension';
import { HierarchyPlugin } from '../aaro/extensions/hierarchy.extension';
import { ProgressPlugin } from '../aaro/extensions/progress.extension';
import { RankingPlugin } from '../aaro/extensions/ranking.extension';
import { RewardExtension } from '../aaro/extensions/rewards.extension';
import { Routine2Plugin } from '../aaro/extensions/routine.extension';
import { SprintPlugin } from '../aaro/extensions/sprint.extension';
import { TaskAaroExtension } from '../aaro/extensions/tasks.extension';
import { MenuLayoutPlugin } from '../elements/menu-layout-plugin/menu-layout-plugin';
import { Splitter, Window } from '../elements/splitter/splitter';
import { GalleryPlugin } from '../gallery/gallery-plugin';
import { AccountabilityPlugin } from '../hessia-plugins/accountability-plugin';
import { AppsPlugin } from '../hessia-plugins/apps-plugin/apps-plugin';
import { AttachmentPlugin } from '../hessia-plugins/attachment-plugin/attachment-plugin';
import { AudioPlugin } from '../hessia-plugins/audio-plugin';
import { AuthPlugin } from '../hessia-plugins/auth-plugin/auth-plugin';
import { CategoryEntityPlugin } from '../hessia-plugins/category-plugin/category-entity-plugin';
import { CheckinEntity } from '../hessia-plugins/checkin-plugin/checkin-entity-plugin';
import { CheckinEvents } from '../hessia-plugins/checkin-plugin/checkin-events-plugin';
import { CheckinPlugin } from '../hessia-plugins/checkin-plugin/checkin-plugin';
import { ComponentPlugin } from '../hessia-plugins/component-plugin';
import { ComposerPlugin } from '../hessia-plugins/composer-plugin';
import { ContextPlugin } from '../hessia-plugins/context-plugin/context-plugin';
import { DebugPlugin } from '../hessia-plugins/debug-plugin/debug-plugin';
import { DueDatePlugin } from '../hessia-plugins/due-date-plugin';
import { EdgePlugin } from '../hessia-plugins/edge-plugin/edge-plugin';
import { ModelAppPlugin } from '../hessia-plugins/entities-apps-plugin';
import { EntityIdentityPlugin } from '../hessia-plugins/entity-identity-plugin';
import { EntityPlugin } from '../hessia-plugins/entity-plugin';
import { EventPlugin } from '../hessia-plugins/events-plugin';
import { HaborPlugin } from '../hessia-plugins/habor-plugin';
import { HessiaPlugin } from '../hessia-plugins/hessia-plugin';
import { ImagePlugin } from '../hessia-plugins/image-plugin';
import { ModelEntityPlugin } from '../hessia-plugins/model-entity-plugin';
import { ModelPlugin } from '../hessia-plugins/model-plugin/model-plugin';
import { AccessPlugin } from '../hessia-plugins/model-plugins/access-plugin';
import { ActivityPlugin } from '../hessia-plugins/model-plugins/activity-plugin';
import { IdentityPlugin } from '../hessia-plugins/model-plugins/identity-plugin';
import { NamedObjectPlugin } from '../hessia-plugins/model-plugins/named-object-plugin';
import { ParentPlugin } from '../hessia-plugins/model-plugins/parent-plugin';
import { PriorityPlugin } from '../hessia-plugins/model-plugins/priority-plugin';
import { MoodPlugin } from '../hessia-plugins/mood-plugin';
import { NotePlugin } from '../hessia-plugins/notes-plugin';
import { NumberPlugin } from '../hessia-plugins/number-plugin';
import { OrchestratorPlugin } from '../hessia-plugins/orchestrator-plugin';
import { PagePlugin } from '../hessia-plugins/page-plugin';
import { PlaidPlugin } from '../hessia-plugins/plaid-plugin';
import { PlaygroundPlugin } from '../hessia-plugins/playground-plugin';
// import { PrimitivesPlugin } from '../hessia-plugins/primitives-plugin';
import { PropertiesPlugin } from '../hessia-plugins/properties-plugin';
import { RatingPlugin } from '../hessia-plugins/rating-plugin';
import { RequestRecordPlugin } from '../hessia-plugins/request-record-plugin';
import { ResourcePlugin } from '../hessia-plugins/resource-plugin';
import { RewardPlugin } from '../hessia-plugins/reward-plugin';
import { RouterPlugin } from '../hessia-plugins/router-plugin';
import { SetPlugin } from '../hessia-plugins/set-plugin/set-plugin';
import { SetSelectorPlugin } from '../hessia-plugins/set-selector-plugin/set-selector-plugin';
import { SpacePlugin } from '../hessia-plugins/space-plugin/space-plugin';
import { StatusPlugin } from '../hessia-plugins/status-plugin';
import { StringPlugin } from '../hessia-plugins/string-plugin';
import { SymbolPlugin } from '../hessia-plugins/symbol-plugin/symbol-plugin';
import { SystemEntityPlugin } from '../hessia-plugins/system-plugin/system-entity-plugin';
import { SystemPlugin } from '../hessia-plugins/system-plugin/system-plugin';
import { TagPlugin } from '../hessia-plugins/tag-system';
import { TimelinePlugin } from '../hessia-plugins/timeline-plugin';
import { TrackerPlugin } from '../hessia-plugins/tracker-plugin';
import { TrackingPlugin } from '../hessia-plugins/tracking-plugin';
import { PropertyPlugin } from '../hessia2/plugins/property-plugin';
import { Entity2Plugin } from '../hessia2/plugins/entity-plugin';
import { Hessia2Plugin } from '../hessia2/Hessia2Plugin';
import { PrimitivesPlugin } from '../hessia2/plugins/content-plugin';
import { OverwebPlugin } from '../overweb/overweb.plugin';
import { HaliaStudioPlugin } from './halia-studio-plugin';
import { ProjectPlugin } from '../aaro/extensions/project.extension';
import { FunctionPlugin } from '../hessia2/plugins/function-plugin';
import { Apps2Plugin } from '../hessia2/plugins/app.plugin';
import { ServiceRegistryPlugin } from '../hessia2/plugins/register.service';
import { PatternPlugin } from '../hessia2/plugins/class-plugin';
import { HaliaPluginLinkPlugin } from '../hessia2/plugins/plugin-link';

const uuidv4 = require('uuid/v4');

export const hessiaPlugins2: (typeof CorePluginClass)[] = [Hessia2Plugin, Entity2Plugin, PropertyPlugin, PatternPlugin, PrimitivesPlugin, AaroPlugin, HabitPlugin, ProgressPlugin, RankingPlugin, DietAaroExtension, TaskAaroExtension, CheckinAaroExtension, RewardExtension, AssistantAaroExtension, DashboardPlugin, HierarchyPlugin, Category2Plugin, Routine2Plugin, SprintPlugin, ProjectPlugin, Apps2Plugin, ServiceRegistryPlugin, HaliaPluginLinkPlugin, FunctionPlugin];
//  FunctionPlugin

export interface HaliaApp {
  id: string;
  name: string;
  stack: ({ id: string })[];
}

export interface AppSelectorProps { haliaAppPlugin: HaliaAppPlugin };
interface AppSelectorState {
  apps: HaliaApp[];
  selectedApp?: HaliaApp;
}
class AppSelector extends React.Component<AppSelectorProps, AppSelectorState> {
  constructor(props: AppSelectorProps) {
    super(props);
    this.state = { apps: [], selectedApp: undefined }
  }

  //  CONCERN:  This is a STATE mirror from the parent context... I don't really like this.  WHY can't we just force a re-render the parent's pieces?  BECAUSE... REACT checks if they changed in the elements to determine whether or not to update.  It's JUST a SINGLE hierarchy.. rememember we want MULTIPLE HM!

  public setApps = (apps: HaliaApp[]) => {
    this.setState({ apps });
  }

  public setSelectedApp = (app?: HaliaApp) => {
    this.setState({ selectedApp: app });
  }

  public componentDidMount = () => {
    this.setApps(this.props.haliaAppPlugin.apps);
    this.setSelectedApp(this.props.haliaAppPlugin.selectedApp);
    this.props.haliaAppPlugin.onSelectedApp(app => this.setSelectedApp(app));
    this.props.haliaAppPlugin.onAppsChanged(apps => this.setApps(apps));
  }

  public render = () => {
    const AppView = ({ app }: { app: HaliaApp, haliaAppPlugin: HaliaAppPlugin }) => {
      return (
        <TouchableOpacity style={{ backgroundColor: '#dddddd', borderRadius: 15, height: 150, alignItems: 'center', justifyContent: 'center' }} onPress={() => this.props.haliaAppPlugin.selectApp(app)}>
          <Text>{app.name}</Text>
          <Button onPress={() => this.props.haliaAppPlugin.deleteApp(app)} title="Delete" />
        </TouchableOpacity>
      );
    }

    const haliaAppSchema: SDTObject = {
      type: "object",
      properties: {
        name: { type: "keyword" },
        id: { type: "keyword" },
        stack: { type: "array", itemType: { type: "object", properties: { id: { type: "keyword" } } } }
      }
    }


    // const newStore = createStore(rootReducer);
    return (
      <Splitter horizontal={false}>
        <Window>
          <FlatGrid data={this.state.apps} renderItem={({ item: app }) => <AppView haliaAppPlugin={this.props.haliaAppPlugin} app={app} />} />
        </Window>
        <Window>
          <ScrollView style={{}}>
            {/* TODO:  Buid Davel Form WITHOUT the Redux Store!!  OR... optional integration hm! */}
            {
              // this.state.selectedApp ?
              // <Text>{ JSON.stringify(this.state.selectedApp) }</Text> :
              // <DavelForm key={ new Date().getTime() } value={ this.state.selectedApp } schema={ haliaAppSchema } onSubmit={ (hApp) => this.props.haliaAppPlugin.updateApp(hApp) } /> :
              // <Text>No App Selected</Text>
            }

            {/* <Text>{ JSON.stringify(this.state.selectedApp) }</Text> */}
          </ScrollView>
        </Window>
      </Splitter>
    );
  }
}


export class HaliaAppPlugin extends CorePluginClass {

  private defaultAppName!: string;
  private halia!: HaliaStudioPlugin;

  public static details = {
    id: "haliaAppPlugin",
    name: "Halia App Plugin",
    dependencies: [MenuLayoutPlugin.details.id, HaliaStudioPlugin.details.id],
  }

  constructor(public options: { defaultAppName: string }) {
    super();
    this.defaultAppName = options.defaultAppName;
  }

  public emitter = new EventEmitter();

  public getAppByName = (apps: HaliaApp[], name: string) => {
    const app = apps.find(app => app.name === name);
    if (app) { return app } else { return undefined; }
  }

  public getApps = async () => {
    try {
      const appsString = await AsyncStorage.getItem("apps");
      if (!appsString) { return []; }
      const apps: HaliaApp[] = JSON.parse(appsString);
      return apps;
    } catch (err) {
      return [];
    }
  }


  public initApps = async () => {

    // const apps = await this.getApps();
    //  TODO-IMPORTANT:  For our BadArt DEPLOYMENT... do we really want HaliaStudio?  It CAN just be launched here hm!
    const apps: HaliaApp[] = [];

    //  Register Bad Art Studio
    const badArtName = "Bad Art Studio";
    const plugins = [GalleryPlugin];
    const badArtApp = { id: uuidv4(), name: badArtName, stack: plugins.map(plugin => ({ id: plugin.details.id })) };
    const _badArtApp = this.getAppByName(apps, badArtName);
    if (!_badArtApp) {
      apps.push(badArtApp);
    }

    //  Register Hessia
    const hessiaName = "Hessia";
    const hessiaPlugins: (typeof CorePluginClass)[] = [AuthPlugin, HaborPlugin, MenuLayoutPlugin, OrchestratorPlugin, HessiaPlugin, SystemPlugin, EntityPlugin, EntityIdentityPlugin, ImagePlugin, AudioPlugin, StringPlugin, EdgePlugin, PropertiesPlugin, AttachmentPlugin, SymbolPlugin, NotePlugin, TagPlugin, AppsPlugin, ModelPlugin, ComponentPlugin, ContextPlugin, SpacePlugin, ModelPlugin, ModelEntityPlugin, AccessPlugin, NamedObjectPlugin, IdentityPlugin, ModelAppPlugin, PagePlugin, HabitPlugin, ActivityPlugin, ComposerPlugin, AccountabilityPlugin, CheckinPlugin, DebugPlugin, DueDatePlugin, EventPlugin, ParentPlugin, PriorityPlugin, NumberPlugin, PrimitivesPlugin, RequestRecordPlugin, ResourcePlugin, RewardPlugin, RouterPlugin, SetPlugin, SetSelectorPlugin, StatusPlugin, TimelinePlugin, TrackingPlugin,
      , CheckinEntity, CheckinEvents, SystemEntityPlugin, CategoryEntityPlugin, PlaidPlugin, MoodPlugin, TrackerPlugin, RatingPlugin, PlaygroundPlugin, OverwebPlugin];
    const hessiaApp = { id: uuidv4(), name: hessiaName, stack: hessiaPlugins.map(plugin => ({ id: plugin.details.id })) };
    const _hessiaApp = this.getAppByName(apps, hessiaName);
    if (!_hessiaApp) {
      apps.push(hessiaApp);
    }

    //  Register Hessia2
    const hessiaName2 = "Hessia2";
    //  Type2Plugin, Notes2Plugin, CorePlugin, WorkplacePlugin, S3SyncPlugin, EntityPlugin, EntityIdentityPlugin, ImagePlugin, AudioPlugin, StringPlugin, EdgePlugin, PropertiesPlugin, AttachmentPlugin, SymbolPlugin, NotePlugin, TagPlugin, AppsPlugin, ModelPlugin, ComponentPlugin, ContextPlugin, SpacePlugin, ModelPlugin, ModelEntityPlugin, AccessPlugin, NamedObjectPlugin, IdentityPlugin, ModelAppPlugin, PagePlugin, HabitPlugin, ActivityPlugin, ComposerPlugin, AccountabilityPlugin, CategoryPlugin, CheckinPlugin, DebugPlugin, DueDatePlugin, EventPlugin, ParentPlugin, PriorityPlugin, ProjectPlugin, NumberPlugin, PrimitivesPlugin, RequestRecordPlugin, ResourcePlugin, RewardPlugin, RouterPlugin, SetPlugin, SetSelectorPlugin, StatusPlugin, TimelinePlugin, TrackingPlugin, WorkspacePlugin, CheckinEntity, CheckinEvents, SystemEntityPlugin, CategoryEntityPlugin, PlaidPlugin, MoodPlugin, TrackerPlugin, DietPlugin, RatingPlugin, PlaygroundPlugin
    const hessiaApp2 = { id: uuidv4(), name: hessiaName2, stack: hessiaPlugins2.map(plugin => ({ id: plugin.details.id })) };
    const _hessiaApp2 = this.getAppByName(apps, hessiaName2);
    if (!_hessiaApp2) {
      apps.push(hessiaApp2);
    }

    //  Register Test
    const testName = "Test";
    const testPlugins: (typeof CorePluginClass)[] = [TestPlugin];
    const testApp = { id: uuidv4(), name: testName, stack: testPlugins.map(plugin => ({ id: plugin.details.id })) };
    const _testApp = this.getAppByName(apps, testName);
    if (!_testApp) {
      apps.push(testApp);
    }

    //  Register Overweb
    const overwebName = "Overweb";
    const overwebPlugins = [OverwebPlugin];
    const overwebApp = { id: uuidv4(), name: overwebName, stack: overwebPlugins.map(plugin => ({ id: plugin.details.id })) };
    const _overwebApp = this.getAppByName(apps, overwebName);
    if (!_overwebApp) {
      apps.push(overwebApp);
    }

    //  Register Stickers
    const stickersName = "Stickers";
    const stickersPlugns = [...hessiaPlugins2, AaroPlugin, HabitPlugin, ProgressPlugin, RankingPlugin];
    const stickersApp = { id: uuidv4(), name: stickersName, stack: stickersPlugns.map(plugin => ({ id: plugin.details.id })) };
    const _stickersApp = this.getAppByName(apps, stickersName);
    if (!_stickersApp) {
      apps.push(stickersApp);
    }

    const aaroLandingName = "Aaro Landing";
    const aaroLandingPlugns = [AaroLandingPlugin];
    const aaroLandingApp = { id: uuidv4(), name: aaroLandingName, stack: aaroLandingPlugns.map(plugin => ({ id: plugin.details.id })) };
    const _aaroLandingApp = this.getAppByName(apps, aaroLandingName);
    if (!_aaroLandingApp) {
      apps.push(aaroLandingApp);
    }

    this.setApps(apps);
  }

  /**
   * Install a Plugin into the Selected App
   */
  public installPlugin = (plugin: typeof CorePluginClass) => {
    alert("Installing Plugin!")
    const originalApp = this.selectedApp;
    if (!originalApp) { throw "Cannot install the plugin without a selelcted app." }
    const newApp: HaliaApp = { ...originalApp, stack: [...originalApp.stack, { id: plugin.details.id }] }
    this.updateApp(newApp);
  }

  /**
   * Uninstall a Plugin from the Selected App
   */
  // public uninstallPlugin = (plugin: typeof CorePluginClass) => {
  //   const originalApp = this.selectedApp;
  //   if (!originalApp) { throw "Cannot install the plugin without a selelcted app." }
  //   const newApp: HaliaApp = { ...originalApp, stack: [...originalApp.stack] };
  //   const originalPluginIndex = newApp.stack.findIndex(_plugin => _plugin.id === plugin.details.id);
  //   if (!originalPluginIndex) { throw "No installed Plugin with the given ID." }
  //   newApp.stack.splice(originalPluginIndex, 1);
  //   this.updateApp(newApp);
  // }

  //  TODO:  Support external app providers!  Hmm.. make this pluggable? Hmm...

  public apps: HaliaApp[] = [];

  //  NOTE:  This acts as the central point of app change. hmm.. MAYBE do this with Redux? Hmm... idk.  I DO want this to all work on the BACKEND too though.. MAYBE just make a SMALL and SIMPLE reactive lib hm!  THEN with the Halia thing make sure we wire it up hm!  We DID use React for the blocks interesting hmmm... FOR NOW.. let's push emmitters hm... 
  public setApps = (apps: HaliaApp[]) => {
    this.apps = apps;
    //  TODO:  Don't like how it's coupled here.. DO want this to be part of the framework ... mabye? Hm... Along with state dependence and stuff?  FOR NOW, let's keep pushing the emitters? Hmm...
    //  TODO:  We don't "know" HOW this is going to be received from this piece oe CODE!  DO want a VISUAL represetionat!!  SAME thing I mentioned in my notes from long ago hm!!!
    this.emitter.emit("apps-updated", apps);
  }

  /**
   * Create a new Halia App
   */
  public createApp = () => {

    //  CONSIDER:  Create APP TEMPLATES for people to pull into their space for editing.

    //  TODO:  Reenable project plugin

    // const updatedApps = [...this.apps, badArtApp, hessiaApp];
    // AsyncStorage.setItem("apps", JSON.stringify(updatedApps));

    // this.setApps(updatedApps);
    alert("Needs to be implemented");
  }

  public updateApp = (app: HaliaApp) => {
    const appsCopy = [...this.apps];
    const appIndex = appsCopy.findIndex(_app => _app.id === app.id);
    const existingApp = appsCopy[appIndex];
    appsCopy[appIndex] = { ...existingApp, ...app };
    AsyncStorage.setItem("apps", JSON.stringify(appsCopy));
    console.log("Updatng App")
    this.setApps(appsCopy);
    //  NOTE:  We do not need to "selectApp", because it's still the same one, and that would trigger a cycle.  
    // this.selectApp(app);
  }

  public deleteApp = (app: HaliaApp) => {
    const appsCopy = [...this.apps];
    const appIndex = this.apps.findIndex(_app => _app.name === app.name);
    appsCopy.splice(appIndex, 1);
    this.setApps(appsCopy);
    if (app.id === this.selectedApp?.id) { this.selectApp(undefined) }
    AsyncStorage.setItem("apps", JSON.stringify(appsCopy));
    console.log("Deleting Item")
  }

  //
  //  App Selection
  //
  public selectedApp: HaliaApp | undefined;
  public selectApp = (app?: HaliaApp) => {

    this.selectedApp = app;
    this.emitter.emit("selected-app", app);
    if (!app || !app.stack) { return; }

    //  NOTE:  This app.stack is more like a pseudo-stack.. a stack representaiton, because it encodes what WILL be in the stack.  A CONFIG.
    const plugins = app.stack.map(stackItem => {
      //  TODO:  Decouple this??  Like... this is APPS ... should this have its OWN import system for discovering Plugins?  FOR NOW, let's leave it in Halia hmm...
      let plugin = this.halia.knownPlugins.find(plugin => plugin.details.id == stackItem.id) as (typeof CorePluginClass);
      if (!plugin) {
        plugin = { details: { id: stackItem.id, name: "MISSING", description: 'Missing ' + stackItem.id, dependencies: [] } } as any
        //  TODO:  Throw an actual Error object.
        // alert(`ERROR STACK`)
        throw `The '${stackItem.id}' Plugin could not be found.  Has the Plugin been enabled?  If so, has it been renamed?`;
      }
      return plugin;
    });
    this.halia.setPlugins(plugins);
  }

  public onSelectedApp = (cb: (app: HaliaApp) => void) => { this.emitter.addListener("selected-app", cb); }
  public unregisterOnSelectedApp = (cb: (app: HaliaApp) => void) => { this.emitter.removeListener("selected-app", cb); }

  public onAppsChanged = (cb: (apps: HaliaApp[]) => void) => { this.emitter.addListener("apps-updated", cb); }

  public getDefaultAppName = () => {

    //  Default App
    return this.defaultAppName;
    // return "Bad Art Studio";
  }

  //
  //  Create App
  //
  public onCreatePressed = (cb: () => void) => { this.emitter.addListener("create-app", cb); }

  private updateAppPlugins = () => {
    if (!this.selectedApp) { return; }
    const installedPlugins = this.halia.getInstalledPlugins();
    this.updateApp({
      ...(this.selectedApp as any),
      stack: installedPlugins.map(plugin => {
        const id: string = plugin.details.id || "MISSING ID";
        return {
          id
        }
      })
    })
  }

  public install = async (program: Program, { menuLayout, halia }: { menuLayout: MenuLayoutPlugin, halia: HaliaStudioPlugin }): Promise<HaliaAppPlugin> => {

    this.halia = halia;

    await this.initApps();

    //  TODO:  Register a dismount handler in case we start using Plugins as "Components" that can be unmounted.
    //  TODO:  Really only need to resave if the plugins changed.  When selecting an app, we will end up re-saving.  I think it's fine for now.
    halia.onPluginsUpdated(this.updateAppPlugins);

    //  TODO:  Decouple this!
    //  Register Default Apps

    //  Check Hessia 
    // if (!this.apps.find(app => app.name === "Hessia")) {

    //   const hessiaPlugins: (typeof CorePluginClass)[] = [ImagePlugin, AppsPlugin, EdgePlugin, AuthPlugin, HaborPlugin, MenuLayoutPlugin, OrchestratorPlugin, HessiaPlugin, SystemPlugin, EntityPlugin, SpacePlugin, ModelPlugin, SetPlugin, NumberPlugin, ContextPlugin, ComposerPlugin, ModelPlugin, ComponentPlugin, DueDatePlugin, TestPlugin, ProjectPlugin, AccessPlugin, HabitPlugin, AccountabilityPlugin, ActivityPlugin, CategoryPlugin, RelationPlugin, CheckinPlugin, AudioPlugin, EventPlugin, CheckinEvents, PagePlugin, EntityMenuPlugin, EntitiesHAppPlugin, SymbolPlugin, EntityIdentityPlugin, PropertiesPlugin, StringPlugin];
    //   const hessiaApp = { id: uuidv4(), name: "Hessia", stack: hessiaPlugins.map(plugin => ({ id: plugin.details.id })) };

    //   const updatedApps = [...this.apps, hessiaApp];
    //   AsyncStorage.setItem("apps", JSON.stringify(updatedApps));
    //   this.setApps(updatedApps);
    // }

    // //  Check Bad Art Studio 
    // if (!this.apps.find(app => app.name === "Bad Art Studio")) {

    //   const appName = "Bad Art Studio";
    //   const plugins = [HaliaComponentPlugin, GalleryPlugin];
    //   const badArtApp = { id: uuidv4(), name: appName, stack: plugins.map(plugin => ({ id: plugin.details.id })) };

    //   const updatedApps = [...this.apps, badArtApp];
    //   AsyncStorage.setItem("apps", JSON.stringify(updatedApps));
    //   this.setApps(updatedApps);
    // }

    //  Get Default
    const defaultAppName = this.getDefaultAppName();
    if (defaultAppName) {
      const app = this.apps.find(app => app.name == this.getDefaultAppName());
      this.selectApp(app);
    }


    menuLayout.registerMenuItem("Apps", {
      headerRight: () => <Button onPress={this.createApp} title="CREATE" />,
      name: "Apps",
      component: () => <AppSelector haliaAppPlugin={this} />,
      icon: { type: "material", name: "apps" }
    });

    return this;
  }

}
