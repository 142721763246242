import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation, useNavigationState } from "@react-navigation/native";
import * as React from 'react';
import { Text, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native";
import { Icon } from "react-native-elements";
import { useSizes } from "../../packages/kelp-bar/sizes-helper";
import { capitalizeAllWords } from '../davel-ui/davel-utils';
const uuid = require('uuid/v4');

export type Icon = { name: string, type: string };

export const CustomBreadcrumbs = ({ crumbs, containerStyle, textStyle }: { crumbs: Crumb[], containerStyle?: ViewStyle, textStyle?: TextStyle }) => {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', ...containerStyle }}>
      {crumbs.map((crumb, index) => (
        <React.Fragment key={crumb.text}>
          <Text onPress={crumb.onPress} style={{ fontFamily: 'Outfit-SemiBold', fontSize: 16, color: '#555555', ...textStyle }}>
            {capitalizeAllWords(crumb.text)}
          </Text>
          {index !== crumbs.length - 1 && (
            <Text style={{ fontFamily: 'Outfit-SemiBold', fontSize: 16, color: '#aaaaaa', marginHorizontal: 10, ...textStyle }}>/</Text>
          )}
        </React.Fragment>
      ))}
    </View>
  );
}

export interface Crumb {
  text: string;
  onPress: () => void;
}

/**
 * Displays React Navigation Breadcrumbs
 */
export const Breadcrumbs = ({ crumbs, containerStyle = {}, textStyle = {} }: { crumbs?: Crumb[], containerStyle?: ViewStyle, textStyle?: TextStyle }) => {
  if (crumbs) {
    return <CustomBreadcrumbs crumbs={crumbs} containerStyle={containerStyle} textStyle={textStyle} />
  } else {
    return <ReactNavigationBreadcrumbs containerStyle={containerStyle} textStyle={textStyle} />
  }
};


/**
 * Displays React Navigation Breadcrumbs
 */
export const ReactNavigationBreadcrumbs = ({ containerStyle = {}, textStyle = {} }: { containerStyle?: ViewStyle, textStyle?: TextStyle }) => {

  const routes = useNavigationState(state => state.routes);
  const navigation = useNavigation();

  // const isStack = !!(navigation as any).pop;
  // const isDrawer = !!(navigation as any).openDrawer;
  // const isTab = !(navigation as any).pop && !(navigation as any).openDrawer;

  //  CONSIDER:  Do we still need this?  
  // If it's a tab navigator, modify the routes array to show only the current route's name
  // let displayedRoutes = isTab ? [{ name: currentRoute.name } as any] : routes;

  const displayedRoutes = routes;
  const crumbs: Crumb[] = displayedRoutes.map(route => ({ text: route.name, onPress: () => navigation.navigate(route.name as never) }))
  return <CustomBreadcrumbs crumbs={crumbs} containerStyle={containerStyle} textStyle={textStyle} />
};

export const SystemIcon = ({ icon }: { icon: Icon }) => {

  return (
    <View style={{ padding: 10, borderRadius: 5, height: 40, width: 40, alignItems: 'center', justifyContent: 'center' }}>
      <Icon size={25} type={icon?.type || "ionicon"} name={icon?.name || "info"} color="#555555" />
    </View>
  );
}
export const IconTitle = ({ name, icon, onPress }: { name: string, icon: Icon, onPress?: () => void }) => {
  return (
    <TouchableOpacity onPress={() => onPress ? onPress : alert("No onPress handler for IconTitle!")} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: "flex-start" }}>
      <Icon size={25} type={icon?.type || "ionicon"} name={icon?.name || "info"} color="#555555" />
      <Text style={{ fontFamily: 'Outfit-SemiBold', fontSize: 18, color: '#555555', paddingLeft: 7 }}>{name}</Text>
    </TouchableOpacity>
  );
}

export const SystemHeader = ({ style, crumbs, system, children, breadcrumbs = true }: { style?: ViewStyle, crumbs?: Crumb[], breadcrumbs?: boolean, system: { name: string, icon: any }, children?: React.ReactElement[] | React.ReactElement }) => {
  return (
    <View style={[{ backgroundColor: 'white', height: 60, flexDirection: 'row', alignItems: 'center', paddingHorizontal: 15, borderBottomColor: '#f8f8f8', borderBottomWidth: 1 }, style]}>
      <IconTitle icon={system.icon} name={breadcrumbs ? "" : system.name} />
      {breadcrumbs && <Breadcrumbs crumbs={ crumbs } />}
      <View style={{ flex: 1 }} />
      {children}
    </View>
  );
}