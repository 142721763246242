import * as React from 'react';
import { TextInput, TextStyle, View } from 'react-native';
import { DavelField, SDTRendererParams } from '../../davel-ui-tools';
import { primaryFontFamily } from '../../../kelp-bar/styles';


export const KeywordFieldStyle = [
  {

    paddingLeft: 0,
    paddingRight: 0,
    fontFamily: primaryFontFamily,
    fontSize: 15,
    color: '#555555',
    height: 30,
    lineHeight: 30,
    borderBottomColor: '#888888',
    borderBottomWidth: 2
  },
  // getRaisedStyle({ raisedHeight: RaisedHeight.low })
];

export const KeywordField = ({ style, value, update }: { style: TextStyle, value: string, update: (value: string) => void }) => {
  return (
    <TextInput
      style={style}
      onChangeText={update}
      // onBlur={input.onBlur}
      // onFocus={input.onFocus}
      value={value}
      autoCapitalize="none"

      //  HACK:  This is a hack to make sure Array elements stay on focus.. the problem is, for some nested forms and stuff, the array fields lose focus because elements are UNMOUNTED whenn they should NOT be.  This will only really help when the ONE thing we're editing is being re-mounted, and it's glitchy!  I think a more robust solution is to ensure even async things are not re-rendered unless necessary? Hmm
      //  NOTE:  ONLY focus when we do NOT have a defined value.  I BELIEVE this auto-focus feature removes the value!
      autoFocus={!value}
    />
  );
};

export const sdtKeywordRenderer = ({ sdt, key, value, update, name }: SDTRendererParams) => (
  <DavelField required={sdt.required} name={name}>
    <KeywordField style={KeywordFieldStyle as TextStyle} update={update} value={value} />
  </DavelField>
);
