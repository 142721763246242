import Amplify from 'aws-amplify';
import * as React from 'react';
import { Image, ScrollView, Text, View } from 'react-native';
import { AuthenticationWidget } from '../../packages/kelp-bar/auth';
import { CenteredView } from '../../packages/kelp-bar/utils';
import { useSizes } from '../gallery/sizes-helper';
import { awsConfig } from '../gallery/constants';


export const Authenticate = ({ mode }: { mode: "login" | "register" }) => {

  const { isDesktop } = useSizes();
  Amplify.configure(awsConfig);

  return (
    <View style={{ flex: 1, display: 'flex', backgroundColor: isDesktop ? '#fafafa' : 'white' }}>
      <ScrollView contentContainerStyle={{ minHeight: '100%' }} style={{ flex: 1 }}>
        <CenteredView containerProps={{ style: { flex: 1 } }} style={{ alignItems: isDesktop ? 'center' : 'stretch', justifyContent: isDesktop ? 'center' : undefined, flex: 1, paddingHorizontal: undefined, height: '100%' }}>
          <View style={{ width: '100%', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            {
              isDesktop && <Image resizeMode='repeat' style={{ height: '100%', width: '100%', position: 'absolute' }} source={require("../../assets/stickers/playstation-pattern.png")} />
            }

            <View style={{ backgroundColor: 'white', borderRadius: 20, width: isDesktop ? 550 : '100%', borderWidth: isDesktop ? 1 : 0, borderColor: '#f5f5f5', overflow: 'hidden' }}>
              <View style={{ height: 100, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Image style={{ height: 40, width: 40, borderRadius: 15, marginLeft: 0 }} source={require("../../assets/stickers/Hessia Logo 6.png")} />
                <View style={{ width: 12 }} />
                <Text style={{ fontFamily: 'Outfit-SemiBold', fontWeight: "400", fontSize: 22, color: '#555555' }}>Hessia</Text>
              </View>
              <AuthenticationWidget mode={mode} />
            </View>
            {/* <Image resizeMode='cover' style={{ height: '100%', width: '100%', position: 'absolute' }} source={require("../../assets/stickers/background_example_5.png")} /> */}
          </View>
        </CenteredView>
      </ScrollView >
    </View >
  )
}
