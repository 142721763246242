import { SDTObject } from "habor-sdk";
import * as React from 'react';
import { TextInput, View } from "react-native";
import { DropDown } from '../../../kelp-bar/drop-down';
import { extraSmallSpacer, medSpacer } from "../../../kelp-bar/styles";
import { TextSubTitle } from "../../../kelp-bar/text";
import { DavelFormButton, SDTComponent } from "../../davel-ui";
import { SDTRendererParams } from '../../davel-ui-tools';
import { capitalizeAllWords, getShortName } from "../../davel-utils";
import { KeywordFieldStyle } from '../keyword/keyword-field';

//  PROBLEM:  In order to render an extended property, we need to know the NAME of the property.  This is becaue the VALUE is stored in the Redux Tree under that key... So, without that name, we don't have a reasonable way to register the properties.  This is because HERE is where we're creating the fields which will read the Redux tree.  We don't read the tree here... Either way... I'd like to remove the Redux dependency!  It complicates the operation and it's unecessary!
//            FOR NOW, we can consider passing the VALUE as well?

export interface ExtensibleFieldProps {
  sdtObject: SDTObject;
  name?: string;
  metadata?: any;
  value: any;
  update: (value: any) => void;
}

export interface ExtensibleFieldState {
  nextPropName: string;
  // extendedProps: string[];
  fields: any[];
  // nextPropType: AllTMSDTs;
}

export class ExtensibleFieldInput extends React.Component<ExtensibleFieldProps, ExtensibleFieldState> {

  constructor(props: ExtensibleFieldProps) {
    super(props);
    this.state = {
      nextPropName: '',
      // nextPropType: { type: "any" },
      // extendedProps: [],
      fields: []
    };
  }

  private updateNextPropName = (text: string) => {
    this.setState({ nextPropName: text });
  }

  // public componentDidMount = async () => {

  //   //  Unpack
  //   const { sdtObject, value } = this.props;

  //   //  Type Guard
  //   //  TODO:  Throw here?
  //   if (!isObject(value)) { return; }

  //   //  Iterate Known Properties
  //   for (const propName in value) {

  //     //  Get Properties
  //     const properties = sdtObject.properties || {};

  //     //  Add Extended Properties (not properties with explicit schemas in the property list)
  //     //  TODO:  HOW will we store the propType for the new fields??  We SHOULD be able to encode this?  Hmmm... OR... maybe it doesn't matter?  It's just an open ended thing?  We permit ANY supported JSON primitive?
  //     if (Object.keys(properties).indexOf(propName) == -1) {
  //       await this.addProp(propName);
  //     }
  //   }

  // }

  private addProp = async (propName: string) => {

    //  Unpack Props
    const { sdtObject, name, metadata, value, update } = this.props;

    //  Validate the Name is Unique
    //  TODO:  Validate when the user is creating the field too, and disable the submit button.
    const propertyCollision = sdtObject.properties ? Object.keys(sdtObject.properties).indexOf(propName) > -1 : false;
    const extendedCollision = Object.keys(value).indexOf(propName) > -1;
    if (propertyCollision || extendedCollision) { throw new Error(`Cannot create property '${propName}', because it already exists.`) }

    //  Update Extended Props
    // const updatedExtendedProps = [...extendedProps, propName];

    //  NOTE:  Extended props are just properties that exist in the "value", but not in the "schema".  
    //  TODO:  In the future support LOTS of extension systems, and infinite complexity.  Also, consider more complexity in the itemType system here.  I KNOW this is super general, but I'm doin that on purpose!

    update({ ...value, [propName]: undefined });

  }

  private submitNextProp = async () => {
    this.addProp(this.state.nextPropName);
    this.setState({ nextPropName: "" });
  }

  public render() {

    const { sdtObject, value, name, update, metadata } = this.props;
    const { nextPropName } = this.state;

    //  Get the Extended Property Names
    const extendedProps: string[] = [];
    for (const propName in value) {
      const inSchema = sdtObject.properties ? Object.keys(sdtObject.properties).indexOf(propName) > -1 : false;
      if (!inSchema) {
        extendedProps.push(propName);
      }
    }

    //  Render Fields
    const fields: any[] = [];
    for (const propName of extendedProps) {
      //  TODO:  I HATE how this name is coupled with this!
      const nestedName = (name === 'root') ? propName : `${name}.${propName}`;
      const itemType = sdtObject.itemType || { type: 'any' };
      const field = <SDTComponent {...{ sdt: itemType, update: (_fieldValue) => { update({ ...value, [propName]: _fieldValue }); }, name: nestedName, metadata, value: value[propName] }} />;
      fields.push(field);
    }

    //  TODO:  Create a way to de-couple the Davel form styles from these files and this explicitl rendering tree... This tree should be able making the forms and underlying connections, NOT the specific styles.
    return (
      <View>
        {fields}
        <View style={{}}>
          <View style={{ height: medSpacer }} />
          <TextSubTitle style={{ color: '#333333', fontSize: 16, fontFamily: 'Poppins-Medium', paddingLeft: 1 }}>Property Name</TextSubTitle>
          <View style={{ height: extraSmallSpacer }} />
          <TextInput autoCapitalize="none" style={KeywordFieldStyle} onChangeText={this.updateNextPropName} value={nextPropName} />
          <View style={{ height: medSpacer }} />
          <DavelFormButton title='Create Property' onPress={this.submitNextProp} />
        </View>
      </View>
    );
  }
}

//  NOTE:  AH!  We likely STILL want an identifier within the Schema.  This is a way to uniquely identify a field within the tree!  For Object it's a specific name, for Array it's a NUMBER mm!
//  NOTE:  This COULD be treated as a React Component instead of a basic function?  Needs to switch to props though.
//  CONCERN:  Is it OK to let the 'value' default to an empty object here?
export const sdtObjectRenderer = ({ sdt, topLevel, metadata, value = {}, update, name }: SDTRendererParams) => {

  const { properties = {}, itemType } = sdt;

  //  Create the Field Map
  //  NOTE:  THIS is basically what the "Davel Form" is doing!  So... consider just using this hm!
  const fields: any[] = [];

  //  Render Known Properties
  for (const propertyName of Object.keys(properties)) {
    //  TODO-NEXT:  Build STATE within the object renderer for EACH property!  Then use that sub-state as the "update" field!
    //  TODO:  Make sure it's OK to add a colon... Davel names should probably not contain colons.
    //  HEADBASH:  WOW, react-form doesn't seem to support a period in the name... loading initial value fails with period.
    const nestedName = (name === undefined) ? propertyName : `${name}.${propertyName}`;
    const propertySDT = properties[propertyName];
    const field = <SDTComponent {...{ update: (fieldValue) => { update({ ...value, [propertyName]: fieldValue }) }, sdt: propertySDT, name: nestedName, metadata, value: (value || {})[propertyName] }} />
    fields.push(field);
  }

  //  Add Space
  const spacedFields = fields.map((field) => {
    return (
      <View>
        {field}
        <View style={{ height: medSpacer }} />
      </View>
    );
  });

  // const header = topLevel ? null : (
  //   <View style={{ borderColor: 'black', borderWidth: 3, display: 'flex', alignSelf: 'flex-start', padding: 7, borderRadius: 4 }}>
  //     <TextSubTitle key={`${name}:title`}>{ getShortName(name) }</TextSubTitle>
  //   </View>
  // );

  console.log("Renderring Object Field!");
  const Container = topLevel ? View : DropDown as any;
  return (
    <View key={name} style={{ display: 'flex', flexDirection: 'column' }}>
      {/* TODO:  Support description through type metadata? */}
      {/* <Text key={`${name}:subtitle`}>{ description }</Text> */}
      <Container key={`${name}:view`} title={capitalizeAllWords(getShortName(name))}>
        {/* <Text>{ `Object Value: ${ JSON.stringify(value) }` }</Text> */}
        {spacedFields}
        {sdt.extensible ? <ExtensibleFieldInput value={value} update={update} name={name} sdtObject={sdt} /> : null}
      </Container>
    </View>
  );
};
