import { flattenObject } from "../../packages/kelp-bar/utils";

const getNestedValue = (path: string[], obj: any): any => {

  //  Duplicate Array
  const pathClone = [...path];

  //  Get the Key
  const key = pathClone[0];
  pathClone.splice(0, 1);

  //  Get the Value
  const value = obj[key];

  //  Check Undefined
  if (value == undefined) { return undefined; }

  //  Check Empty Path
  if (!pathClone.length) {
    return obj[key];
  }
  
  return getNestedValue(pathClone, obj[key]);
}

export const componentSettingBindings: ComponentSettingBinding[] = [];

export interface FieldSetting {
  sdtType: string;  //  CONSIDER:  In the future, consider support for more advanced Field Selection... This includes SPECIFIC DT Options that should be met for the match to succeed instead of 'sdtType'.
  options?: any;  //  The DT Options that must match for this Field Setting to apply.
  component: string;  //  The ID of the HaborComponent.
  settings?: any;  //  User specified settings for this Field.
  props?: any;  //  TODO:  When we do a field Override, we have some USER settings for the field.  This may include max / min, etc... EVENTAULLY, this might tie in with Rules / Modifiers.  But FOR NOW, this is specified by the user when they select the component.  HOWEVER... How will we deal with the Davel props required for Redux Form?
}

export interface FieldSettings {
  selections: FieldSetting[];
}

//  Create a Setting for the "Number" Field
//  TODO:  Now that the setting configuration has changed, we want to target a component... In this case, we can target a router and make settings based on the "sdtType" prop?  Something like this.s
const numberFieldSetting: ComponentSettingBinding = {
  context: {
    name: "field-setting"
  },

  //  TODO:  Support ALTERNATIVE entry methods!?  MAYBE this can be hard-coded by the system?  MAYBE we DON'T want to use the primitive Context system for this!?  BUT, it's JUST another syste we can use to express shit!!  COOL!?
  //  TODO-CONSUMPTION:  Support PHOTOS of my PRODUCTS!?
  //  TODO-CONSUMPTION:  Support AGGREGATIONS and custom UIs for this system!?  THIS is an example of a system that COULD have been built with native code, BUT by buildin gin Hessia we get ALL the standardized Hessia features!?  AND what's SUPER cool is we can fuck with it on our PHONE and BUILD extend, WHATEVER VISUALLY!!!!  EVENTUALLY.. !!! Haha!!
  setting: {
    selections: [
      {
        sdtType: "number",
        component: "SliderHaborComponent",
        props: {
          value: 100,  //  TODO:  These should come from the data type, NOT the component!  But... maybe this is OK for now.
          minimumValue: 0,
          maximumValue: 500
        }
      }
    ]
  }
}

componentSettingBindings.push(numberFieldSetting);

/**
 * Used to determine whether or not the Settings bound to 'settingContext' apply to the immediate 'currentContext' (no parent matching).
 * This is accomplished by determening whether or not 'currentContext' has the same value for every path through 'settingContext'.
 * @param settingContext 
 * @param currentContext 
 */
 const doesContextApplyImmediate = (settingContext: HaborComponentContext, currentContext: HaborComponentContext) => {

  //  Remove the Parent
  const settingContextNoParent = { ...settingContext };
  delete settingContextNoParent['parent'];

  //  Flatten 'settingContext'
  const flatSettings = flattenObject(settingContextNoParent);

  //  Iterate and check ALL values
  for (const flatValue of flatSettings) {
    const currentContextValue = getNestedValue(flatValue.path, currentContext);
    if (currentContextValue != flatValue.value) {
      return false;
    }
  }

  return true;
}

/**
 * Used to determine whether or not the Settings bound to 'settingContext' apply to 'currentContext'.
 * This is accomplished by determening whether or not 'currentContext' has the same value for every nested key in 'settingContext'.
 * @param settingContext 
 * @param currentContext 
 */
const doesContextApply = (settingContext?: HaborComponentContext, currentContext?: HaborComponentContext): boolean => {

  //  Handle Undefined
  //  ALWAYS apply an undefined SettingContext.  This means it applies globally... BUT, it CAN be overridden!  
  //  TODO:  Write a method for caclculting the final set of settings by comparing overrides for specificity!
  //  NOTE:  This can happen when there is NO settingContext defined on a Binding, OR when the parent is undefined.  In both cases, the context applies.
  if (settingContext == undefined) { return true }  

  //  We require an object to process.  This MAY indicate that we've exceeded the 'currentContext' stack with elements remaining in hte 'settingContext' stack.  This indicates that we did NOT find a corresponding match for every element in 'settingContext'!
  if (currentContext == undefined) { return false }

  //  Check if 'setingContext' matches ANYTHING in the 'currentContext' chain.
  //  We want to work our way through the 'currentContext' chain for 'settingContext' node.
  let currentContextNode: HaborComponentContext | undefined = currentContext;
  while (currentContextNode) {
    const doesSettingApply = doesContextApplyImmediate(settingContext, currentContextNode);
    if (doesSettingApply) { break; }
    currentContextNode = currentContextNode.parent;
  }

  //  Check for Match
  if (currentContextNode == undefined) { return false; }

  //  NOW, check if the 'settingContext' parent applies
  const parentApplies = doesContextApply(settingContext.parent, currentContextNode.parent);

  return parentApplies;

  //  Brainstorm:
  //  TODO:  Support Parent
  //  The equivalency test for 'settingContext.parent' is unique:  By our definition, 'settingContext' may only apply if 'settingContext.parent' has already applied to 'currentContext'.  It will apply ONLY if it's parent ALSO applies... We form a CHAIN where the ORDER of applications must be held for the final node to apply.  BUT it doesn't need to be through an IMMEDIATE parent unless specified (consider CSS).  We want to make sure that A parent matched, not the immediate parent.  We just want to make sure that there's a A matching sequence in the chain.  FOR NOW, let's not support "parent".
  //  Therefore, if 'settingContext.parent' applies to 'currentContext.parent', then 'settingContext.parent' is said to be equivalent to 'currentContext.parent'.
  //  AGAIN, as I'm building this I'm realizing we're PROBABLY going in the wrong direction.  ESPECIALLY when we take parents into accout.  It makes more sense to go forward.  Now... when we're rendering, we really only care about answering the questions for the current set of chains.  So... We can create a Trie structure for the chains.
  //  UPDDATE:  Actually... MAYBE it DOES make sense to work backward... because we can have a TON of "Divs" before we fianlly reach the 3 that matter... SO, let's assume we'll work backward.  THEN, we only give up once we've iterated the WHOLE tree of ACTUAL element but we still have unmatched contexts in our SettingContext!!
  //  PLAN:  We're going to iterate the tree in reverse matching with the current setting object.  If all the parents have matches in the real tree, then we're GOOD.  If not, then we don't match.
  //  Iterate UP the tree for EACH of our defined parents.  If they DON'T match the real one, then continue.  If there are no real ones left, then stop.
  //  NOTE:  Keep in mind that we ARE going to be calling "doesContextApply", so we can use recursion!  In this case, we just need to be sure the CURRENT parent applies!  When we call the function, we'll know whether or not the parent of that does, because it will iterate until it hits!  If it DOESN'T hit ANYWHERE in the chain, then we DON'T match!
  //  Ah!  That MAY be critical... the "settingContext" itself ONLY needs to match a parent to apply!  As soon as it matches ANY parent, it is good, as long as the PARENTS also have a corresponding node.

};

/**
 * Binds a list of ComponentSetting objects to a ComponentSettingContext.
 * TODO:  Consider applying the same list of settings to MULTIPLE Contexts at the same time?  
 * TODO:  Consider creating setting "Classes" MUCH like CSS.  Then we can apply settings in the SAME way.
 * REALIZATION:  WHOA... this is really cool.  This IS a way to apply settings to components with something VERY similar to CSS!  MAYBE we can use this for Styles too??
 */
 export interface ComponentSettingBinding {
  context?: HaborComponentContext;
  setting: any;
}


export interface HaborComponentContext {
  props?: any;
  name: string;
  parent?: HaborComponentContext;
}

/**
 * Returns the settings applied to 'context'.
 * @param context 
 */
 export const getAppliedSettings = (context: HaborComponentContext): any => {

  //  TODO:  Make this more effieicnet! We shouldn't have to iterate ALL the bindings!
  const appliedBindings: ComponentSettingBinding[] = [];
  for (const binding of componentSettingBindings) {
    const contextApplies = doesContextApply(binding.context, context);
    if (contextApplies) {
      appliedBindings.push(binding);
    }
  }

  //  TODO-IMPORTANT:  Process Setting Overrides in the right order.  Higher specificity = higher preference.

  //  Flatten Settings
  let settings: any = {};
  for (const binding of appliedBindings) {
    settings = { ...settings, ...binding.setting };
  }

  return settings;
}