
import * as React from 'react';
import * as RN from 'react-native';
import { WidgetContext } from './utils';
import * as RNE from "react-native-elements";
import { LongPressGestureHandler, TapGestureHandler } from 'react-native-gesture-handler';


//
//  Wrapped Components (Hessia React Native)
//

/**
 * Wraps a component to be pressable with context.
 */
export const ContextWrapper = ({ children }: { children: any }) => {
  const widgetContext = React.useContext(WidgetContext);
  return widgetContext ? (
    <LongPressGestureHandler onGestureEvent={() => alert(JSON.stringify(widgetContext))}>
      {children}
    </LongPressGestureHandler>
  ) : (
    <>
      {children}
    </>
  );
}

/**
 * Make a widget "contextualized" by supporting context info with interaction (e.g. long-press).
 * CONSIDER:  We could make a less common gesture like tapping twice be the context display.
 */
export const contextualizeComponent = (Component: any) => {
  return (props: any) => (
    <ContextWrapper>
      <Component {...props} />
    </ContextWrapper>
  )
}

export const View = contextualizeComponent(RN.View);
export const Text = contextualizeComponent(RN.Text);
export const Pressable = contextualizeComponent(RN.Pressable);
export const TextInput = contextualizeComponent(RN.TextInput);
export const Image = contextualizeComponent(RN.Image);
export const Icon = contextualizeComponent(RNE.Icon);
export const Button = contextualizeComponent(RN.Button);
export const ScrollView = contextualizeComponent(RN.ScrollView);
