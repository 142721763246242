import * as React from 'react';
import { NavContext } from './NavigationProvider';

//
//  SearchContext
//

interface SearchContext {
  search: string,
  setSearch: (search: string) => void
};

export const SearchContext = React.createContext<SearchContext>({ search: "", setSearch: () => null });

export const SearchProvider = ({ children }) => {

  const [searchTerm, setSearchTerm] = React.useState("");
  const { setSelectedView } = React.useContext(NavContext);

  React.useEffect(() => {
    setSelectedView({ name: "search" });
  }, [searchTerm]);

  return (
    <SearchContext.Provider value={{ search: searchTerm, setSearch: setSearchTerm }}>
      {children}
    </SearchContext.Provider>
  );
}
