/**
 * Service Registry Meta-Model
 * 
 * A Service used to model Hessia Services.
 */

import { CorePluginClass } from "halia";
import React from "react";
import { NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { Entity2Plugin, EntityTable } from "./entity-plugin";
import { Entity } from "./entity-service";
import { Hessia2Context, Hessia2Plugin, System } from "../Hessia2Plugin";
import { SystemHeader } from "../../../packages/kelp-bar/system-header";

//  TODO:  Rename System2 -> HessiaService

const Registry = () => {
  const { systemEntities } = React.useContext(ServiceRegisteryContext);
  return (
    <>
      <SystemHeader system={ServiceRegistry} breadcrumbs={ false } />
      <EntityTable entities={systemEntities} />
    </>
  )
}

export interface ServiceRegistryContext {
  systemEntities: NounServiceInstanceInternal<Entity>[];
};

const ServiceRegisteryContext = React.createContext<ServiceRegistryContext>({ systemEntities: [] });

const ServiceRegistry: System = {
  pinned: false,
  priority: 4,
  //  CONSIDER:  These match better with "service" since they "provide" something.  Meet the criteria for that LANGUAGE.
  name: "Systems",
  description: "Registry of Systems",
  id: "service-registry",
  emoji: "",
  color: "#eeeeee",
  component: Registry,
  icon: { name: "server", type: "feather" },
  primaryColor: "black",
  backgroundColor: "#eeeeee"
}

export class ServiceRegistryPlugin extends CorePluginClass {

  public static details = {
    name: "System Registry",
    description: "System Registry Meta-Model",
    dependencies: [Hessia2Plugin.details.id, Entity2Plugin.details.id],
    id: "system-registry"
  }

  public install = async (program: any, { hessia2, entity2 }: { hessia2: Hessia2Plugin, entity2: Entity2Plugin }) => {

    hessia2.registerHOC(({ children }) => {

      const { installSystem, systems } = React.useContext(Hessia2Context);
      const [systemEntities, setSystemEntities] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);

      /**
       * Checks if an Entity exists for each system and creates it if necessary
       * CONSDIER:  We COULD "Project" the services as entities instead of creating them explicitly.
       */

      //  CONSIDER:  I don't like that each entity we create has the ID of the system itself... that doesn't make a ton of sense to me.  Generally a thing is created (arranged) for a specific things / purpose.  We MODEL it as being a part of some system.  But in reality it's not really... that's why I'm not crazy about it having a special ID.  It's just an entity with a given set of constraints.  In this case, THIS system has an associated ID.  FOR NOW, we can just use the ID.
      const createSystemsIfNeeded = async () => {
        const _systemEntities: NounServiceInstanceInternal<Entity>[] = [];
        for (const system of systems) {
          const systemEntityId = entity2.entityService.generateEntityIdentifier(system);
          const systemEntity = await entity2.entityService.getEntityNounById(systemEntityId);
          if (!systemEntity) {
            const internalEntity = await entity2.entityService.createEntity({
              name: system.name,
              id: systemEntityId,
              description: system.description,
              owners: [ServiceRegistry]
            });
            _systemEntities.push(internalEntity);
          } else {
            _systemEntities.push(systemEntity);
          }
        }
        setSystemEntities(_systemEntities);
      }

      React.useEffect(() => {
        installSystem(ServiceRegistry, true);
      }, []);

      React.useEffect(() => {
        createSystemsIfNeeded();
      }, [systems]);

      return (
        <ServiceRegisteryContext.Provider value={{ systemEntities }}>
          {children}
        </ServiceRegisteryContext.Provider>
      );
    });

    return this;
  }
}
