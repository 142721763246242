import * as _ from "lodash";
import { isObject } from "lodash";
import * as React from 'react';
import { View, ViewProps } from 'react-native';
import { useSizes } from "../../packages/kelp-bar/sizes-helper";
import { desktopMarginSize, maxWidth, mobileMarginSize, tabletMarginSize } from './constants';
import Svg, { Path } from "react-native-svg";
import { CommonActions } from "@react-navigation/native";

export const validateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$/.test(mail)) {
    return (true)
  }
  return (false)
}


export interface Settings {
  marginSize: number;
}

export const useSettings = () => {

  const { isDesktop, isTablet, isMobile, sizeConfig: { desktopBreakpoint, tabletBreakpoint } } = useSizes();
  const marginSize = isMobile ? mobileMarginSize : isTablet ? tabletMarginSize : desktopMarginSize;

  return {
    marginSize
  }

}

export interface CenteredViewProps extends ViewProps {
  containerProps?: ViewProps;
}

/**
 * A View Centered in a Full Width Container
 * CONSIDER:  WHY are we creating a new thing JUST for this pattern when we COULD also have ahh!!! It's the SAME as in life!!! It's up to US if we want to name it a duck or a "bird with a bill".  AHH!!!  It's TOTALLY up to US.  We do what's EFFICIENT and our BRAINS do it based on association hm!  We name things when they're COMMON but they ARE a composition of things hm!  SO ... we MAY want to reference this, and then break some of its rules but either way, it's STILL just an encoding!  A POJECTION HM!
 * @param props 
 * @returns 
 */
export const CenteredView = (props: CenteredViewProps) => {

  const { isDesktop, isTablet, isMobile, sizeConfig: { desktopBreakpoint, tabletBreakpoint } } = useSizes();
  const { marginSize } = useSettings();

  const containerProps = props.containerProps || {};
  const containerStyle = containerProps.style || {};
  const centeredViewStyle = props.style || {};

  return (
    <View {...props.containerProps} onLayout={props?.containerProps?.onLayout} style={[{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }, containerStyle]}>
      <View style={[{ maxWidth, width: '100%', height: '100%' }, centeredViewStyle]}>
        {props.children}
      </View>
    </View>
  );
}

export interface FlatValue {
  path: string[];
  value: any;
}

/**
 * Flattens to [{ path, value }] Array
 */
export const flattenObject = (obj: any): FlatValue[] => {
  const flatValues: FlatValue[] = [];
  for (const key in obj) {
    const value = obj[key];
    if (isObject(value)) {
      const nestedFlatValues = flattenObject(value).map(value => ({ value: value.value, path: [key, ...value.path] }));
      flatValues.push(...nestedFlatValues);
    } else {
      flatValues.push({ path: [key], value });
    }
  }
  return flatValues;
}

/**
 *  Flattens to an Object
 *  REFERENCE:  GPT4
 */
 export const flattenObjectToObject = (obj, prefix = '') => {
  return _.reduce(obj, (acc, value, key) => {
    const fullPath = prefix ? `${prefix}.${key}` : key;
    if (_.isObject(value) && !Array.isArray(value)) {
      _.assign(acc, flattenObjectToObject(value, fullPath));
    } else {
      acc[fullPath] = value;
    }
    return acc;
  }, {});
}

export interface CustomSVG {
  color: string;
  size: number;
}

export const FilledBolt = ({ color, size, ...props }: CustomSVG) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path fill={color} d="m5.67 9.914l3.062-4.143c1.979-2.678 2.969-4.017 3.892-3.734c.923.283.923 1.925.923 5.21v.31c0 1.185 0 1.777.379 2.148l.02.02c.387.363 1.003.363 2.236.363c2.22 0 3.329 0 3.704.673l.018.034c.354.683-.289 1.553-1.574 3.29l-3.062 4.144c-1.98 2.678-2.969 4.017-3.892 3.734c-.923-.283-.923-1.925-.923-5.21v-.31c0-1.185 0-1.777-.379-2.148l-.02-.02c-.387-.363-1.003-.363-2.236-.363c-2.22 0-3.329 0-3.704-.673a1.084 1.084 0 0 1-.018-.034c-.354-.683.289-1.552 1.574-3.29Z" />
    </Svg>
  );
};

export const EntityIcon = ({ color, size, ...props }: CustomSVG) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path strokeWidth="2" fill={color} d="M3.5 5.25c0-.966.784-1.75 1.75-1.75h3a.75.75 0 0 0 0-1.5h-3A3.25 3.25 0 0 0 2 5.25v3a.75.75 0 0 0 1.5 0v-3Zm17 0a1.75 1.75 0 0 0-1.75-1.75h-3a.75.75 0 0 1 0-1.5h3A3.25 3.25 0 0 1 22 5.25v3a.75.75 0 0 1-1.5 0v-3ZM5.25 20.5a1.75 1.75 0 0 1-1.75-1.75v-3a.75.75 0 0 0-1.5 0v3A3.25 3.25 0 0 0 5.25 22h3a.75.75 0 0 0 0-1.5h-3Zm15.25-1.75a1.75 1.75 0 0 1-1.75 1.75h-3a.75.75 0 0 0 0 1.5h3A3.25 3.25 0 0 0 22 18.75v-3a.75.75 0 0 0-1.5 0v3ZM13.82 8.227a2.25 2.25 0 0 0-3.64 0l-3.054 4.2C6.044 13.914 7.106 16 8.946 16h6.108c1.84 0 2.902-2.086 1.82-3.573l-3.054-4.2Z" />
    </Svg>
  );
};

export const TriangleIcon = ({ color, size, ...props }: CustomSVG) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...props}>
      <Path fill={color} d="M11.99 1.968c1.023 0 1.97.521 2.512 1.359l.103.172l7.1 12.25l.062.126a3 3 0 0 1-2.568 4.117L19 20H5l-.049-.003l-.112.002a3 3 0 0 1-2.268-1.226l-.109-.16a3 3 0 0 1-.32-2.545l.072-.194l.06-.125L9.366 3.516a3 3 0 0 1 2.625-1.548z" />
    </Svg>
  );
};

export const BoltIcon = ({ color, size, ...rest }: CustomSVG) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <Path fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7Z" />
    </Svg>
  );
};


/**
 *  Navigates to Root Screen
 */
export const navigateToScreen = (screenName, navigation) => {
  navigation.dispatch(
    CommonActions.reset({
      index: 0,
      routes: [{ name: screenName }],
    }),
  );
};