import * as React from 'react';
import { HaliaStudioPlugin } from './halia-studio-plugin';
// import { HaliaStudioPlugin } from './halia-studio-plugin';

export interface HaliaPluginState {
  //  TODO:  This is a HUGE sign that the two CAN and PROBABLY SHOLD be COMBINED and THIS can be part of the FRAMEWORK and NOT a part of this thing ughh!!
  haliaPlugin: HaliaStudioPlugin;
  //  TODO:  Generalie to more than just "apps"... we're building an ENCODING  hmmmm.. COULD be a FUNCTION or pLUGIN,e tc hm!
}

//  NOTE:  This (undefined as any) type cast represents a LACK of encoding of the RULE that it WILL be deifned before USAGE HM!
export const HaliaPluginContext = React.createContext<HaliaPluginState>({ haliaPlugin: undefined as any });
