
import * as React from 'react';
import { ActivityIndicator, View } from 'react-native';
import { primaryColor } from './constants';

export const PageLoader = (props) => {
  const { loading, children } = props;
  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator size={ 50 } color={ primaryColor } />
      </View>
    );
  } else {
    return children;
  }
};
