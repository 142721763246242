
// import { Provider as AntProvider } from '@ant-design/react-native';
// import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
import { NavigationContainer } from '@react-navigation/native';
import * as Davel from 'davel';
import { Asset } from 'expo-asset';
import * as HaborSDK from 'habor-sdk';
import * as React from "react";
import { ActivityIndicator, StatusBar, View } from 'react-native';
import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { HessiaPlugin } from '.';
import { Router } from '../../../packages/isomorphic-router/react-router';
import { unpackAsset } from '../../../packages/unpack/unpack';
import { HOCRegister } from 'halia-native';
import { HessiaAppMain } from './app-main';
import { navigationRef } from './RootNavigation';
import { primaryColor } from '../../gallery/constants';

console.disableYellowBox = true;


//
//  Redux + ReduxForm Configuration
//

const rootReducer = combineReducers({
  form: formReducer,
});

export const store = createStore(rootReducer);


export class HessiaApp extends React.Component<{ hessiaPlugin: HessiaPlugin }> {

  state = {
    fontsAreLoaded: false
  };

  async componentWillMount() {

    //  NOTE:  On July 17, 2024, I moved this "Davel Configuration" into this to stop the Hessia I registrations from occurring.  I'm slowly moving the pieces into Hessia II.  The idea is to make EVERYTHING work locally first.  In retrospect, it MAY have been easier to stick with what we had before and work through Habor, but I think taking this plunge will help the overall architecture.

    alert("Mounting Hessia!");

    //
    //  Davel Configuration
    //
    const typespace = new Davel.TypeSpace();

    //  Set the TypeSpace in Davel
    Davel.selectTypeSpace(typespace);

    //  Set the TypeSpace in HaborSDK
    HaborSDK.initDavel(typespace);

    //  Register Types
    //  CONSIDER:  Move the SDT type to the AllTMSDT type in the SDK?
    //  CONSIDER:  Intall these with a Halia Plugin!
    Davel.registerSDTDeserializer("statement", HaborSDK.HSDTStatementParser as any);
    Davel.registerSDTDeserializer("noun", HaborSDK.TMSerializedNounParser as any);
    Davel.registerSDTDeserializer("user-function", HaborSDK.HSDTUserFunctionParser as any);
    Davel.registerSDTDeserializer("relationship", HaborSDK.HSDTRelationshipParser as any);
    Davel.registerSDTDeserializer("color", HaborSDK.HSDTColorParser as any);
    Davel.registerSDTDeserializer("custom-type", HaborSDK.HSDTCustomTypeParser as any);
    Davel.registerSDTDeserializer("derived", HaborSDK.HSDTDerivedParser as any);

    const assetsPath = `../../.../../../assets`;

    await Asset.loadAsync([
      unpackAsset(require("../../../assets/icons/Dashboard.png")),
      unpackAsset(require('../../../assets/icons/DashboardBlack.png')),
      unpackAsset(require('../../../assets/icons/DashboardWhite.png')),
      unpackAsset(require('../../../assets/icons/Entities.png')),
      unpackAsset(require('../../../assets/icons/EntitiesBlack.png')),
      unpackAsset(require('../../../assets/icons/EntitiesWhite.png'))
    ]);

    this.setState({ fontsAreLoaded: true });
  }

  render() {
    if (!this.state.fontsAreLoaded) {
      return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicator size={50} color={primaryColor} />
        </View>
      );
    }

    // return <WebView 
    //   style={{ width: '100%', height: '100%' }}
    //   source={{ uri: 'https://expo.io' }}
    // />

    //  THOUGHT:  I THINK it can make sense to make a REGISTER space at the top level where we can inject components like "Alert" and stuff for doing something like imperative APIs?  FOR NOW, I'll just use Navigation and route to a thing.
    return (
      <Router>
        <Provider store={store}>
          <NavigationContainer ref={navigationRef} independent={true}>
            <StatusBar hidden={false} />
            <HOCRegister id="hessia">
              <HessiaAppMain hessiaPlugin={this.props.hessiaPlugin} />
            </HOCRegister>
          </NavigationContainer>
        </Provider>
      </Router>
    );
  }
}
