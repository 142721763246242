import React from "react";
const uuidv4 = require('uuid/v4');

//  CONSIDER:  We COULD make these entities as well... or at least have a way to SHOW them as such without getting into storage.

export interface EventType {
  name: string;
  id: string;
  description: string;
}

export interface Event {
  eventType?: string;
  time: Date;
  note?: string;
}

interface EventInternal extends Event {
  id: string;
}

export interface LogContext {
  logEvent: (event: Event) => void;
  registerEventType: (eventType: EventType) => void;
  events: EventInternal[];
  eventTypes: EventType[];
}

export const LogContext = React.createContext<LogContext>({ logEvent: () => null, registerEventType: () => null, events: [], eventTypes: []});

export const LogProvider = ({ children }) => {

  const [events, setEvents] = React.useState<EventInternal[]>([]);
  const [eventTypes, setEventTypes] = React.useState<EventType[]>([]);

  const registerEventType = (eventType: EventType) => {
    setEventTypes(_eventTypes => {
      if (_eventTypes.find(_eventType => _eventType.id === eventType.id)) {
        return [..._eventTypes, eventType];
      } else {
        return [..._eventTypes];
      }
    });
  };

  const logEvent = (event: Event) => {
    setEvents(_events => {
      const id = uuidv4();
      return [..._events, { ...event, id }];
    });
  };

  return (
    <LogContext.Provider value={{ logEvent, events, registerEventType, eventTypes }}>
      {children}
    </LogContext.Provider>
  );
}