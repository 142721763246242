/**
 * Halia Plugins Meta-Model
 * 
 * A Service used to model Halia Plugins
 */

import { CorePluginClass } from "halia";
import React from "react";
import { SystemHeader } from "../../../packages/kelp-bar/system-header";
import { NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { HaliaPluginContext } from "../../halia-studio/halia-context";
import { Hessia2Context, Hessia2Plugin } from "../Hessia2Plugin";
import { Entity2Plugin, EntityTable } from "./entity-plugin";
import { Entity } from "./entity-service";
import { NavContext } from "../NavigationProvider";
import { System } from "../systems";

const HaliaPluginEntitylist = () => {
  const { haliaPluginEntites } = React.useContext(HaliaPluginLinkContext);
  return (
    <>
      <SystemHeader system={HaliaPluginLinkSystem} breadcrumbs={false} />
      <EntityTable entities={haliaPluginEntites} />
    </>
  )
}

export interface PluginLinkContext {
  haliaPluginEntites: NounServiceInstanceInternal<Entity>[];
};

// TODO:  PROVIDE the relationship between plugins so it can be observed!

const HaliaPluginLinkContext = React.createContext<PluginLinkContext>({ haliaPluginEntites: [] });

const HaliaPluginLinkSystem: System = {
  pinned: false,
  priority: 4,
  //  CONSIDER:  These match better with "service" since they "provide" something.  Meet the criteria for that LANGUAGE.
  name: "Modules",
  description: "Represents Halia Plugins as Hessia Entities",
  id: "halia-plugin-link-system",
  emoji: "",
  color: "#eeeeee",
  component: HaliaPluginEntitylist,
  icon: { name: "box", type: "feather" },
  primaryColor: "black",
  backgroundColor: "#eeeeee"
}

export class HaliaPluginLinkPlugin extends CorePluginClass {

  public static details = {
    name: "System Plugins",
    description: "System Plugins Meta-Model",
    dependencies: [Hessia2Plugin.details.id, Entity2Plugin.details.id],
    id: "system-Plugins"
  }

  public install = async (program: any, { hessia2, entity2 }: { hessia2: Hessia2Plugin, entity2: Entity2Plugin }) => {

    hessia2.registerHOC(({ children }) => {


      const { haliaPlugin } = React.useContext(HaliaPluginContext);
      const { registerView } = React.useContext(NavContext);
      const { installSystem, systems } = React.useContext(Hessia2Context);
      const [haliaPluginEntites, setHaliaPluginEntites] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);

      const plugins = haliaPlugin.getKnownPlugins();

      /**
       * Checks if an Entity exists for each system and creates it if necessary
       * CONSDIER:  We COULD "Project" the services as entities instead of creating them explicitly.
       */

      //  CONSIDER:  I don't like that each entity we create has the ID of the system itself... that doesn't make a ton of sense to me.  Generally a thing is created (arranged) for a specific things / purpose.  We MODEL it as being a part of some system.  But in reality it's not really... that's why I'm not crazy about it having a special ID.  It's just an entity with a given set of constraints.  In this case, THIS system has an associated ID.  FOR NOW, we can just use the ID.
      //  TODO:  Generalize this for OTHER systems which want to have entity representation.
      const createPluginEntitiesIfNeeded = async () => {
        const _haliaPluginEntites: NounServiceInstanceInternal<Entity>[] = [];
        for (const plugin of plugins) {
          //  NOTE:  This ID will change if the object changes.
          const entityId = entity2.entityService.generateEntityIdentifier(plugin);
          const entity = await entity2.entityService.getEntityNounById(entityId);
          if (!entity) {
            const internalEntity = await entity2.entityService.createEntity({

              //  CONSDIER:  We can either tag this as a primitiv like a refrence... which means the structure is validated within that symbolic representation.  OR, we can link the "type" (Plugin / System) using a reference.  In EITHER case, we're coupling a SYMBOL ("Plugin" / "System") with THIS thing AND we're coupling a concept ("Type").  So it's LARGELY the same.  It's just a matter of where the relationship is encoded.  It CAN be encoded on this entity itself, EMBEDDED, or it can be encoded externally.  Eventually it WILL look more external if we index.
              name: plugin.details.name,
              id: plugin.details.id,
              description: plugin.details.description,
              owners: [HaliaPluginLinkSystem]
            });
            _haliaPluginEntites.push(internalEntity);
          } else {
            _haliaPluginEntites.push(entity);
          }
        }
        setHaliaPluginEntites(_haliaPluginEntites);
      }

      React.useEffect(() => {
        installSystem(HaliaPluginLinkSystem, true);
        registerView({
          name: "extensions",
          component: HaliaPluginEntitylist
        });
      }, []);

      React.useEffect(() => {
        createPluginEntitiesIfNeeded();
      }, [systems]);

      return (
        <HaliaPluginLinkContext.Provider value={{ haliaPluginEntites }}>
          {children}
        </HaliaPluginLinkContext.Provider>
      );
    });

    return this;
  }
}
