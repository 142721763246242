
import * as React from 'react';
import { Text } from 'react-native-paper';
import { System } from '../../hessia2/Hessia2Plugin';
import { TagSetPlugin } from './tag.template';

export class SprintPlugin extends TagSetPlugin {

  public PluginContext = React.createContext<SprintPlugin | undefined>(undefined);

  public static details = {
    ...TagSetPlugin.details,
    name: 'Sprint',
    description: 'Introduces Sprints',
    id: "sprint"
  }

  public tagSetId = "sprint-type";

  public system: System = {
    name: "Sprint",
    description: "Adds Sprints",
    id: "sprint-system",
    icon: { name: "boat-outline", type: "ionicon" },
    emoji: "",
    color: "#eeeeee",
    primaryColor: "#aaaaaa",
    component: () => <Text>Sprint System</Text>,
    backgroundColor: "#555555"
  }
}