import * as React from 'react';
import * as RN from 'react-native';
import { Icon } from 'react-native-elements';
import { TextParagraph } from '../../packages/kelp-bar/text';

const styles = RN.StyleSheet.create({
  column: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'white',
    flexDirection: 'row'
  },
  handle: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 1,
    flexShrink: 0,
    flexGrow: 0,
    backgroundColor: '#eeeeee', // Make the handle slightly visible
  },
});


//  REFERENCE:  GPT-4
export const ResizableColumn = (props: RN.ViewProps = { style: {} }) => {
  const [width, setWidth] = React.useState((props.style as any)?.width); // Initial width
  const [isHovered, setIsHovered] = React.useState(false); // Is mouse over the handle?

  const panResponder = React.useRef(RN.PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onPanResponderMove: (evt, gestureState) => {
      setWidth(gestureState.moveX);
    },
    onPanResponderEnd: () => {

    }
  })).current;

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <RN.View style={[styles.column, props.style, { width }]}>
      <RN.View style={{ flex: 1 }}>
        {props.children}
      </RN.View>
      <RN.View
        style={[styles.handle, { width: isHovered ? 5 : 1 }]}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...panResponder.panHandlers}
      />
    </RN.View>
  );
};

export interface NamedObject {
  name: string;
  description?: string;
  icon?: { type: string, name: string };
  foregroundColor?: string;
  backgroundColor?: string;
}

export interface IDObject {
  id: string;
}

//  CONSIDER:  Can also remove
//  CONSIDER:  May be able to use a library for this.
export interface Set<T extends IDObject> {
  add: (item: T) => void;
  items: T[];
}

export function useSet<T extends IDObject>(initial: T[] = []): Set<T> {
  const [ items, setItems ] = React.useState<T[]>(initial);
  const add = (item: T) => {
    setItems(_items => {
      if (!_items.find(_item => _item.id === item.id)) {
        return [..._items, item ];
      } else {
        return _items;
      }
    });
  };
  return {
    add,
    items
  }
}

export interface Widget extends NamedObject, IDObject {
  component: any;
  pluginId: string;
}

export interface WidgetContext {
  parentContext?: WidgetContext;
  widget: Widget;
}
export const WidgetContext = React.createContext<WidgetContext | undefined>(undefined);

export const WidgetWrapper = ({ editMode = false, widget, style }: { editMode: boolean, widget: Widget, style?: RN.ViewStyle }) => {

  const parentContext = React.useContext(WidgetContext);

  const [collapsed, setCollapsed] = React.useState(false);

  const { component: Widget, name } = widget;

  //  TODO:  Go to an Entity for the Widget (meta-model).
  //  TODO:  Support NESTED widgets.
  const onLongPressDefault = () => {
    alert(JSON.stringify({ widget, parentContext }));
  }

  return (
    <WidgetContext.Provider value={{ parentContext, widget: widget }}>
      <RN.Pressable style={ style } onLongPress={onLongPressDefault}>
        {
          editMode ? (
            <RN.View style={{ backgroundColor: '#fcfcfc', borderRadius: 5, borderColor: '#eeeeee', borderWidth: 1 }}>
              <RN.View style={{ borderBottomColor: '#eeeeee', borderBottomWidth: 1, justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center', padding: 15 }}>
                <Icon name="drag-indicator" type="material" color="#999999" />
                <RN.View style={{ width: 10 }} />
                <TextParagraph style={{ color: "#777777" }}>{name}</TextParagraph>
                <RN.View style={{ flex: 1 }} />
                <Icon name={collapsed ? "chevron-back" : "chevron-down"} onPress={() => setCollapsed(!collapsed)} type="ionicon" color="#999999" />
              </RN.View>
              {
                !collapsed && (
                  <RN.View style={{ padding: 15 }}>
                    <Widget />
                  </RN.View>
                )
              }
            </RN.View>
          ) : <Widget />
        }
      </RN.Pressable>
    </WidgetContext.Provider>
  );

}
