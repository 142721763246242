/**
 * Provides a Panel Interface for Main, Left, etc.
 */

import React from "react";
import { Animated, View } from "react-native";
import { useSizes } from "../../packages/kelp-bar/sizes-helper";
import { Settings } from "./Settings";
import { ResizableColumn, Widget } from "./utils";

//  NOTE:  Representing the DEFINITIONS as entities in the application domain lets us MANAGE (save, show, etc) these any way we'd like.

// interface ViewComponent extends Component {
//   component
//   name: string;
//   style?: ViewStyle;
//   children?: Component[];
// }

interface ComponentSelection {
  name: string;
  params?: any;
};

export interface NavContext {

  //  Left
  selectedViewLeft: ComponentSelection;
  selectedViewParamsLeft?: any;
  setSelectedViewLeft: (view: ComponentSelection, params?: any) => void;
  showPanel: boolean;
  setShowPanel: (showPanel: boolean) => void;

  //  Main
  selectedView: ComponentSelection;
  selectedViewParams?: any;
  setSelectedView: (view: ComponentSelection, params?: any) => void;

  //  View Registration
  registerView: (view: Widget) => void;
  views: Widget[];
}

//  CONSIDER:  We COULD use JSX to define HESSIA components if we write a Babel tool!

const DefaultViewSelection: ComponentSelection = { name: "home", params: {} };
const DefaultViewSelectionLeft: ComponentSelection = { name: "left-panel", params: {} };

export const NavContext = React.createContext<NavContext>({ selectedViewLeft: DefaultViewSelectionLeft, setSelectedViewLeft: () => null, showPanel: false, setShowPanel: () => null, registerView: (view: Widget) => null, views: [], selectedView: DefaultViewSelection, setSelectedView: () => null });

export const NavProvider = ({ children }) => {

  const { isDesktop } = useSizes();

  //  Main Panel
  const [selectedView, _setSelectedView] = React.useState<ComponentSelection>(DefaultViewSelection);
  const [selectedViewParams, _setselectedViewParams] = React.useState<any>({});
  const setSelectedView = (view: ComponentSelection, params: any) => {
    _setSelectedView(view);
    _setselectedViewParams(params);
    if (!isDesktop) {
      setShowPanel(false);
    }
  }

  //  Left Panel
  const [showPanel, setShowPanel] = React.useState(isDesktop ? true : false);
  const [selectedViewLeft, _setSelectedViewLeft] = React.useState<ComponentSelection>(DefaultViewSelectionLeft);
  const [selectedViewParamsLeft, _setselectedViewParamsLeft] = React.useState<any>({});
  const setSelectedViewLeft = (view: ComponentSelection, params: any) => {
    _setSelectedViewLeft(view);
    _setselectedViewParamsLeft(params);
  }



  const [views, setViews] = React.useState<Widget[]>([]);

  const registerView = (view: Widget) => {
    setViews((views) => {
      if (!views.find(_view => _view.name === view.name)) {
        return [...views, view];
      } else {
        return views;
      }
    });
  };

  return (
    <NavContext.Provider value={{ setSelectedViewLeft, selectedViewLeft, selectedViewParamsLeft, showPanel, setShowPanel, selectedView, setSelectedView, registerView, views, selectedViewParams }}>
      {children}
    </NavContext.Provider>
  );
}

export const useSelectedView = (selectedView: ComponentSelection) => {
  const { views } = React.useContext(NavContext);
  const SelectedView = views.find(view => view.name == selectedView.name);
  const SelectedViewComponent = SelectedView?.component;
  return SelectedViewComponent;
}

export const MainPanel = () => {
  const { selectedView, selectedViewParams } = React.useContext(NavContext);
  const SelectedViwComponent = useSelectedView(selectedView);
  return (
    <View style={{ flex: 1 }}>
      {SelectedViwComponent && <SelectedViwComponent {...selectedViewParams} />}
    </View>
  );
}

export const LeftPanel = () => {

  const { showPanel } = React.useContext(NavContext);
  const { isDesktop } = useSizes();

  const { selectedViewLeft, selectedViewParamsLeft } = React.useContext(NavContext);
  const SelectedViwComponent = useSelectedView(selectedViewLeft);

  const panelWidth = React.useRef(new Animated.Value(0)).current;  //  Start On-Screen

  React.useEffect(() => {
    Animated.spring(panelWidth, {
      toValue: showPanel ? 250 : 0,
      useNativeDriver: false,
    }).start();
  }, [showPanel]);

  return (
    <>
      {
        showPanel && <ResizableColumn style={{ zIndex: 2, position: !isDesktop ? 'absolute' : undefined, width: panelWidth, height: '100%', borderRightWidth: 1, borderRightColor: '#eeeeee' }} >
          <SelectedViwComponent {...selectedViewParamsLeft} />
        </ResizableColumn>
      }
    </>
  );

}