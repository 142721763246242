import { FontAwesome } from '@expo/vector-icons';
import * as React from 'react';
import { TouchableOpacity, View } from "react-native";
import { medSpacer, smallSpacer } from './styles';
import { TextParagraph } from './text';

export interface DropDownProps extends React.ComponentProps<any> {
  title: string;
}

export interface DropDownState {
  open: boolean;
}

export class DropDown extends React.Component<DropDownProps, DropDownState> {
  constructor(props: DropDownProps) {
    super(props);
    this.state = { open: false };
  }
  public toggleDropdown = () => {
    this.setState({ open: !this.state.open });
  }
  public render() {
    return (
      <View>
        <TouchableOpacity onPress={this.toggleDropdown} style={{ backgroundColor: '#DDDDDD', borderRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', padding: smallSpacer }} >
          {this.state.open ? <FontAwesome name="angle-down" size={32} color="#555555" /> : <FontAwesome name="angle-right" size={32} color="#555555" />}
          <View style={{ width: medSpacer }} />
          <TextParagraph style={{ color: '#333333', fontSize: 16, fontFamily: 'Poppins-Medium' }}>{this.props.title}</TextParagraph>
        </TouchableOpacity>
        <View style={{ height: this.state.open ? undefined : 0, overflow: 'hidden' }}>
          {this.props.children}
        </View>
      </View>
    );
  }
}