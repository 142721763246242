
import {
  CardElement,
  Elements,
  PaymentElement, useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as React from 'react';
import { useState } from "react";
// import { StripeProvider, useStripe } from '@stripe/stripe-react-native';
import { ActivityIndicator, Image, Pressable, Text } from "react-native";
import { api } from "./bas-sdk";
import { basApiHost, defaultBorderRadius, Paragraph, primaryColor } from "./constants";
import { useCart } from "./providers/cart.provider";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// TODO:  Uncomment this line when we're ready for Stripe again.
// const stripePromise = loadStripe("pk_live_UuDa0Mtd2SG3Pbk092uuLJ8p005CDF5tGY");

export const PaymentStatus = () => {

  const stripe = useStripe();
  const [message, setMessage] = useState<string>("");

  React.useEffect(() => {

    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js


    if (!clientSecret) {
      throw new Error("Missing Client Secret");
    }

    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {

        if (!paymentIntent) {
          throw new Error('Mising Payment Intent');
        }

        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage('Success! Payment received.');
            break;


          //  TODO:  Handle the processing case with web-hook!
          case 'processing':
            setMessage("Payment processing. We'll update you when payment is received.");
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage('Payment failed. Please try another payment method.');
            break;

          default:
            setMessage('Something went wrong.');
            break;
        }
      });
  }, [stripe]);


  return <Text>{message}</Text>
};

export const PaymentStatusWrapper = () => {

  return (
    <Elements stripe={stripePromise}>
      <PaymentStatus />
    </Elements>
  );
}


const API_URL = `${basApiHost}/payment-sheet`;

// export default function CheckoutScreen() {
//   const { initPaymentSheet, presentPaymentSheet } = useStripe();
//   const [loading, setLoading] = useState(false);

//   const fetchPaymentSheetParams = async () => {
//     const response = await fetch(`${API_URL}/checkout`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
//     const { paymentIntent, ephemeralKey, customer, publishableKey} = await response.json();

//     return {
//       paymentIntent,
//       ephemeralKey,
//       customer,
//       publishableKey
//     };
//   };

//   const initializePaymentSheet = async () => {
//     const {
//       paymentIntent,
//       ephemeralKey,
//       customer,
//       publishableKey,
//     } = await fetchPaymentSheetParams();

//     const { error } = await initPaymentSheet({
//       customerId: customer,
//       customerEphemeralKeySecret: ephemeralKey,
//       paymentIntentClientSecret: paymentIntent,
//       // Set `allowsDelayedPaymentMethods` to true if your business can handle payment
//       //methods that complete payment after a delay, like SEPA Debit and Sofort.
//       allowsDelayedPaymentMethods: true,
//     });
//     if (!error) {
//       setLoading(true);
//     }
//   };

//   const openPaymentSheet = async () => {
//     const { error } = await presentPaymentSheet();

//     if (error) {
//       Alert.alert(`Error code: ${error.code}`, error.message);
//     } else {
//       Alert.alert('Success', 'Your order is confirmed!');
//     }
//   };

//   React.useEffect(() => {
//     initializePaymentSheet();
//   }, []);

//   return (
//     <Button
//         disabled={!loading}
//         title="Checkout"
//         onPress={openPaymentSheet}
//       />
//   );
// }

// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://stripe.com/docs/payments/accept-a-payment#web

// import { NavigationContainer } from '@react-navigation/native';

//  We want a main page, then a navigator then sub-navigators for the other views:

//  Profile

//  Shop (Gallery)

//  Accounts

//  Works
//  Create
//  List
//  Editor / Detail
//  Delete?  I DON'T think users will have the ability to delete after upload will they? Hmm...

//  Basically each artist can upload works that they want to share.  The idea is if we approve them, then we'll process them and users can buy them in the store hmm... I think that'll work? Hmm...

export const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#87bbfd",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS as any} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const ResetButton = ({ onClick }) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <path
        fill="#FFF"
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
      />
    </svg>
  </button>
);

// const CheckoutForm = ({ product }: { product: Product }) => {

//   const stripe = useStripe();
//   const elements = useElements();
//   const [error, setError] = useState<any>(null);
//   const [cardComplete, setCardComplete] = useState(false);
//   const [processing, setProcessing] = useState(false);
//   const [paymentMethod, setPaymentMethod] = useState<any>(null);
//   const [billingDetails, setBillingDetails] = useState({
//     email: "",
//     phone: "",
//     name: "",
//   });

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements) {
//       // Stripe.js has not loaded yet. Make sure to disable
//       // form submission until Stripe.js has loaded.
//       return;
//     }

//     const card = elements.getElement(CardElement);

//     if (card == null) {
//       return;
//     }

//     if (error) {
//       card.focus();
//       return;
//     }

//     if (cardComplete) {
//       setProcessing(true);
//     }

//     const payload = await stripe.createPaymentMethod({
//       type: "card",
//       card,
//       billing_details: billingDetails,
//     });

//     setProcessing(false);

//     if (payload.error) {
//       setError(payload.error as any);
//     } else {
//       setPaymentMethod(payload.paymentMethod as any);
//     }
//   };

//   const reset = () => {
//     setError(null);
//     setProcessing(false);
//     setPaymentMethod(null);
//     setBillingDetails({
//       email: "",
//       phone: "",
//       name: "",
//     });
//   };

//   return paymentMethod ? (
//     <div className="Result">
//       <div className="ResultTitle" role="alert">
//         Payment successful
//       </div>
//       <div className="ResultMessage">
//         Thanks for trying Stripe Elements. No money was charged, but we
//         generated a PaymentMethod: {paymentMethod.id}
//       </div>
//       <ResetButton onClick={reset} />
//     </div>
//   ) : (
//     <form className="Form" onSubmit={handleSubmit}>
//       <fieldset className="FormGroup">
//         <Field
//           label="Name"
//           id="name"
//           type="text"
//           placeholder="Jane Doe"
//           required
//           autoComplete="name"
//           value={billingDetails.name}
//           onChange={(e) => {
//             setBillingDetails({ ...billingDetails, name: e.target.value });
//           }}
//         />
//         <Field
//           label="Email"
//           id="email"
//           type="email"
//           placeholder="janedoe@gmail.com"
//           required
//           autoComplete="email"
//           value={billingDetails.email}
//           onChange={(e) => {
//             setBillingDetails({ ...billingDetails, email: e.target.value });
//           }}
//         />
//         <Field
//           label="Phone"
//           id="phone"
//           type="tel"
//           placeholder="(941) 555-0123"
//           required
//           autoComplete="tel"
//           value={billingDetails.phone}
//           onChange={(e) => {
//             setBillingDetails({ ...billingDetails, phone: e.target.value });
//           }}
//         />
//       </fieldset>
//       <fieldset className="FormGroup">
//         <CardField
//           onChange={(e) => {
//             setError(e.error);
//             setCardComplete(e.complete);
//           }}
//         />
//       </fieldset>
//       {error && <ErrorMessage>{error.message}</ErrorMessage>}
//       <SubmitButton processing={processing} error={error} disabled={!stripe}>
//         Pay ${ product.price }
//       </SubmitButton>
//     </form>
//   );
// };

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

const CheckoutForm = () => {


  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        //  TODO:  Make this work on server.
        return_url: `http://localhost:19006/VerifyPayment`,
      },
    });


    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button disabled={!stripe}>Submit</button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
}


//  TODO:  We use basically the SAME code in Stripe!  Refactor EVEN if it means building a hook / registration / plugin pattern.  
//         The only real difference is - The lexical context (and those closure variables) AND the freaking function we run to do the estimate.  Maybe build an onCartChange function? Hmm... But this idea of LOADING is still interesting.  Maybe we can just PASS a variable hmm... 
export const onCartChange = (callback, loading?: any) => {

}

//  CONSIDER:  A "Loader" that we can use to track loading of a thing? Hmm...  MAYBE let it be like CSS wher eit can be nested? Hmm...

export const StripeCheckoutButton = ({ }: {}) => {

  // const [clientSecret, setClientSecret] = React.useState(undefined);

  const [loading, setLoading] = React.useState(true);

  const [session, setSession] = React.useState<any>(undefined);

  const cart = useCart();

  const getSession = async () => {

    const session: any = await api.workProductsControllerGetStripeSession({
      body: {
        cart: cart.cartBare
      } as any
    });

    setSession(session);
    setLoading(false);

    // const response = await fetch(`${basApiHost}/payment-sheet`, {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body
    // });
    // const {
    //   paymentIntent,
    //   ephemeralKey,
    //   customer,
    //   publishableKey
    // } = await response.json();
    // setClientSecret(paymentIntent);
  }


  React.useEffect(() => {
    setLoading(true);
    setSession(undefined);
    if (!cart.isEmpty()) {
      getSession();
    } else {
      setLoading(false);
    }
  }, [cart])

  // if (!clientSecret) {
  //   return <Text>Loading</Text>;
  // }

  // const options = {
  //   // passing the client secret obtained in step 2
  //   clientSecret,
  //   // Fully customizable with appearance API.
  //   appearance: {/*...*/ },
  // };

  const isEmpty = !session && !loading;

  return (
    <Pressable disabled={loading || isEmpty} onPress={() => window.location = session?.url} style={{ maxWidth: 300, borderRadius: defaultBorderRadius, backgroundColor: isEmpty ? '#dddddd' : primaryColor, width: '100%', height: 50, alignItems: 'center', justifyContent: 'center' }}>
      {
        loading ? (
          <>
            <ActivityIndicator color="white" />
          </>
        ) : (
          <>
            <Paragraph style={{ color: 'white' }}>Checkout</Paragraph>
            <Image source={require('./assets/stripe.svg')} />
          </>
        )
      }
    </Pressable >
  )

  // return (
  //   <Elements stripe={stripePromise} options={options}>
  //     <CheckoutForm />
  //   </Elements>

  // )




  // return (
  //   <StripeProvider
  //     publishableKey="pk_test_qeBn50F1z1Jn6CyWOZB9bWXx00PeqxcHRG"
  //     // urlScheme="your-url-scheme" // required for 3D Secure and bank redirects
  //     // merchantIdentifier="merchant.com.{{YOUR_APP_NAME}}" // required for Apple Pay
  //   >
  //     <Text>Stripe</Text>
  //     <CheckoutScreen />

  //   </StripeProvider>

  //   // <div className="AppWrapper">
  //   //   <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
  //   //     <CheckoutForm product={ product } />
  //   //   </Elements>
  //   // </div>
  // );
};
