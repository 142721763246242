import { NavigationContainer } from "@react-navigation/native";
import Amplify from 'aws-amplify';
import { HOCRegister } from 'halia-native';
import * as React from 'react';
import { SafeAreaView } from "react-native";
import { MD3LightTheme as DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { View } from 'react-native-web';
import { AuthProvider } from "../../packages/kelp-bar/auth";
import { awsConfig } from '../gallery/constants';
import { useAppType } from "../gallery/sizes-helper";
import { getConfigFromStorage } from './config.service';
import { Hessia2Context, Hessia2Plugin, HessiaApp, System } from "./Hessia2Plugin";
import { NavContext, NavProvider } from './NavigationProvider';
import { SearchProvider } from './SearchProvider';
import { LogProvider } from "./LogProvider";
import { useSet, Widget } from "./utils";

//  TODO:  Replace!
Amplify.configure(awsConfig);


export const HessiaProvider = ({ hessia2Plugin }: { hessia2Plugin: Hessia2Plugin }) => {

  //  TODO:  Re-Enable Navigation to Main Screen
  //  TODO:  Want to generalize this to more than just React... should be able to express systems in audio, text, etc.  THIS then is the App version I suppose.
  //  NOTE:  The settings for the app are ALWAYS stored locally.  Then, we can use the target set there to pull in the app.
  //  TODO:  Build a SYSTEM space where we can log things like app starts, events, etc!  THIS way we have a record of what we've debugged and the app journey!  

  const { registerView } = React.useContext(NavContext);

  const [systems, setSystems] = React.useState<System[]>([]);
  const systemRef = React.useRef(systems);
  systemRef.current = systems;



  const installSystem = (system: System, navigate?: boolean) => {

    //  TODO:  Register an entity for EACH system.

    setSystems(prevSystems => {
      if (!prevSystems.map(prevSystem => prevSystem.name).includes(system.name)) {
        const updatedSystems = [...prevSystems, system];
        return updatedSystems;
      }
      return prevSystems;
    });
  };

  //  CONSIDER:  Make a QUICK GPT integrator that takes a SCHEMA for an object and converts it into a component and then we can QUICKLY render a component!!!  Make a BUNCH of simple AI connected components!  STILL want to have a website for all these things mm!!!  This is part of Oranda and CAN have its own pricing model mm!

  const getSystemById = (id: string): System | undefined => {
    return systems.find(system => system.id === id);
  }
  const [selectedSystem, selectSystem] = React.useState<System | undefined>(undefined);
  const paperTheme = {
    ...DefaultTheme
  };

  //  Load Config
  React.useEffect(() => {
    getConfigFromStorage();
  }, []);

  //  Left Menu Widget Set
  const leftMenuWidgetSet = useSet<Widget>([]);

  const type = useAppType();
  return (
    <PaperProvider theme={paperTheme}>
      <NavigationContainer independent={true}>
        <LogProvider>
          <AuthProvider>
            <NavProvider>
              <SearchProvider>
                <Hessia2Context.Provider value={{ leftMenuWidgetSet, selectedSystem, selectSystem, installSystem, getSystemById, systems, hessia2Plugin }}>
                  <HOCRegister id="hessia2">
                    <SafeAreaView style={{ flexDirection: 'column', backgroundColor: 'white', flex: 1 }}>
                      <HessiaApp />
                    </SafeAreaView>
                  </HOCRegister>
                </Hessia2Context.Provider>
              </SearchProvider>
            </NavProvider>
          </AuthProvider>
        </LogProvider>
      </NavigationContainer>
    </PaperProvider >
  )
}