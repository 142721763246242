import { DrawerContentComponentProps, DrawerItem, createDrawerNavigator } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { NamedObject } from 'habor-sdk';
import { CorePluginClass, Program } from 'halia';
import { HOCRegister, registerHOC, registerHOCRegister, removeHOC } from 'halia-native';
import * as React from 'react';
import { Image, ScrollView, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import { EmojiButton } from '../gallery/components/common';
import { useSizes } from '../gallery/sizes-helper';
import { PluginStore } from '../halia-studio/halia-studio-plugin/plugin-list';
import { App, Apps2Plugin, AppsContext } from '../hessia2/plugins/app.plugin';
import { Entity2Plugin } from '../hessia2/plugins/entity-plugin';
import { Hessia2Plugin } from '../hessia2/Hessia2Plugin';
import { Widget } from '../../packages/widgets';

//  Ideas
//  CONSIDER:  Make it possible to force EACH HaliaREACT Plugin to provide a public method to Register Child.  This way, it will become available to its children.

export const AaroTheme = {
  primaryColor: '#5de8e3',
  primaryColorBorder: '#95f5f1',
  primaryColorLight: '#f5f5f5',
  primaryColorMedium: '#999999',
  primaryColorDark: '#777777',
  primaryColorSubtle: '#f2fcfc'
}

export interface RouteConfig {
  name: string;
  icon: {
    name: string;
    type: string;
  },
  emoji: string;
  component: any;
  filtered?: boolean;
  header?: any;
  isInitial?: boolean;
  logo?: number;
};

export interface IAaroContext {
  routes: RouteConfig[];
  installRoute: (route: RouteConfig) => void;
  installedList: string[];
  setInstalledList: any;
  headerWidgets: Widget[];
  installHeaderWidget: (widget: Widget) => void;
  installDetailWidget: (widget: DetailWidget) => void;
  detailWidgets: DetailWidget[];
}
export const AaroContext = React.createContext<IAaroContext>({ routes: [], installRoute: () => undefined, installDetailWidget: (widget) => null, detailWidgets: [], installedList: [], setInstalledList: undefined, headerWidgets: [], installHeaderWidget: () => undefined });

export interface Extension {
  id: string;
  name: string;
  description: string;
  image: number;
  color: string;
};

// export const ExtensionStore = () => {

//   const { setInstalledList, installedList } = React.useContext(AaroContext);

//   const installPlugin = (name: string) => {
//     setInstalledList([...installedList, name]);
//   }

//   // CONSIDER:  I USED to be able to make new systems in Hessia!!!  I think if I could I MIGHT go back and re-work Hessia itself hmmm.. I MAY still be able to merge the two mm!!
//   // TODO:  NEED to be able to adjust the header for the different contexts INSTEAD of injecting into the global header AND without building hooks for EACH injection site!  INSTEAD do it based on the CONTEXT!  For example, if we're in the "Extensions" context, etc... hmm!  It's a "Extension Header".  Etc MM!  HOW do we determine that???  We CAN pass "context" down from the top!  In other words, we always add something to identify our "context" so things can hook into it!  For example, we have a "parent context", and we can also add our own!  We DID build some of this in the past!!!

//   const myContext = { id: "ExtensionStore" };

//   return (
//     <ScrollView style={{ backgroundColor: 'white' }}>
//       <AaroHeader title="Extensions">
//         <TouchableOpacity style={{ backgroundColor: '#fbfbfb', borderRadius: 25, width: 50, height: 50, borderColor: '#eeeeee', borderWidth: 2, alignItems: 'center', justifyContent: 'center' }} onPress={() => alert("Create a new Aaro Extension!")}>
//           <Icon color="#888888" name="plus" type="font-awesome" size={19} />
//         </TouchableOpacity>
//       </AaroHeader>

//       {/* <SystemHeader breadcrumbs={ false } system={{ name: "Extensions", icon: { name: "plug", type: "font-awesome" } }} /> */}
//       <FlatList
//         data={extensions}
//         ItemSeparatorComponent={() => <View style={{ height: 2, backgroundColor: '#eeeeee' }} />}
//         renderItem={({ item: ext }) => (
//           <View style={{ flexDirection: 'row', width: '100%', height: 150, paddingHorizontal: 15, alignItems: 'center' }}>

//             <View style={{ width: 100 }}>
//               <Image source={ext.image} style={{ height: 95, width: 95 }} resizeMode='contain' />
//             </View>

//             <View style={{ width: 300, marginLeft: 20 }}>
//               <Text style={{ fontFamily: 'Inter-Bold', fontSize: 23, marginBottom: 5 }}>{ext.name}</Text>
//               <Text style={{ fontFamily: 'Inter-SemiBold', fontSize: 14, marginBottom: 15 }}>{ext.description}</Text>

//               {/* TODO:  Install icon */}
//               {/* TODO:  Uninstall */}
//               {/* TODO:  Loading for install to show that it did something! */}

//               <TouchableOpacity onPress={() => installPlugin(ext.id)} style={{ backgroundColor: ext.color, alignItems: 'center', justifyContent: 'center', borderRadius: 10, padding: 5, width: 100 }}>
//                 <Text style={{ fontFamily: 'Inter-Bold', fontSize: 14, color: 'white' }}>{installedList.includes(ext.id) ? "Installed" : "Install"}</Text>
//               </TouchableOpacity>
//             </View>
//           </View>
//         )}
//       />
//     </ScrollView>
//   );
// }

export const AaroPluginContext = React.createContext<AaroPlugin>({} as any);
export const useAaroPlugin = () => {
  return React.useContext(AaroPluginContext);
}

// TODO:  Only check 54pt for native!
// TODO:  SHOULD be able to AUTOMATICALLY add thigns like this for mobile and web and mm!  Be able to use AI to updazte my systems!

// const AaroHeader = ({ borderOpacity, title, navigation, showFilter, additional, style = {} }) => {

//   const type = useAppType();

//   return (

//     <View style={{ paddingTop: type == 'pwa' || type == 'native' ? 54 : 0, backgroundColor: 'white', justifyContent: 'flex-start', ...style }}>
//       <Text>{type}</Text>
//       <>
//         <View style={[styles.menuContainer, { paddingVertical: 15 }]}>
//           <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>

//             <TouchableOpacity style={{ marginRight: 15, alignItems: 'center', justifyContent: 'center' }} onPress={() => navigation.toggleDrawer()}>
//               <Icon name="menu" type="entypo" size={30} color="#555555" />
//             </TouchableOpacity>

//             <View style={{ alignItems: 'center', justifyContent: 'center' }}>
//               <Text style={{ fontFamily: 'Inter-Bold', fontSize: 25, color: "#555555", letterSpacing: -0.5 }}>{title}</Text>
//             </View>

//           </View>

//           <View style={styles.titleContainer}>

//             {
//               additional.map(_additional => (
//                 <View style={{ marginHorizontal: 15 }}>
//                   {_additional}
//                 </View>
//               ))
//             }
//           </View>
//         </View>
//         <Animated.View style={{ height: 2, backgroundColor: '#eeeeee', opacity: borderOpacity }} />
//       </>
//     </View >


//   );
// };

const styles = StyleSheet.create({
  menuContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    width: '100%'
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end'
  },
  title: {
    fontSize: 18,
    fontFamily: 'Inter-SemiBold'
  },
});




const DrawerNav = createDrawerNavigator();

//  TODO-FUNDAMENTAL:  Define components as "entities"


export interface DetailWidget extends NamedObject {
  component: any;  //  TODO:  Type with a prop for habit
  pluginId: string;
}

export interface HabitFilter extends NamedObject {
  component: any;
}

const AaroDrawerContent = (props: DrawerContentComponentProps) => {

  const { routes } = React.useContext(AaroContext);

  const extensionsFocused = props.state.index == props.state.routes.findIndex(route => route.name == "Extensions");

  const { isDesktop } = useSizes();

  const navigation = useNavigation<any>();

  return (
    <>

      <View style={{ height: 65, alignItems: 'center', backgroundColor: 'white', justifyContent: 'flex-start', flexDirection: 'row' }}>
        <View style={{ height: '100%', borderRightWidth: isDesktop ? 2 : 0, borderRightColor: '#eeeeee', width: 200, backgroundColor: 'white', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20 }}>
          <Image style={{ height: 45, width: 45, borderRadius: 15, marginLeft: 0 }} source={require("../../../assets/aaro_logo.png")} />
          <View style={{ width: 12 }} />
          <Text style={{ fontFamily: 'Outfit-SemiBold', fontWeight: "500", fontSize: 22, color: '#555555' }}>Aaro</Text>
        </View>
      </View>

      <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1, paddingVertical: 5 }} {...props}>

        {props.state.routes.map((route, index) => {
          const { name } = route;
          const focused = index === props.state.index;
          const routeConfig = routes.find(route => route.name === name);
          if (!routeConfig) { return null; }
          const { emoji, icon, logo } = routeConfig;

          return (
            <DrawerItem
              label={() => (
                <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                  <EmojiButton icon={icon} logo={logo} emoji={emoji} selected={false} />
                  <Text style={{ fontFamily: 'Inter-Bold', color: focused ? AaroTheme.primaryColorDark : AaroTheme.primaryColorMedium, fontSize: 15, marginLeft: 10 }}>{name}</Text>
                </View>
              )}
              activeBackgroundColor={AaroTheme.primaryColorLight}
              focused={focused}
              style={{ height: 60 }}
              onPress={() => props.navigation.navigate(route.name)}
              key={route.key}
            />
          );
        })}
      </ScrollView>

      {/* Bottom Content */}
      <View style={{ borderTopColor: '#eeeeee', borderTopWidth: 1, paddingVertical: 5 }}>

        {/* Extensions Button */}

        <DrawerItem
          label={() => (
            <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
              <EmojiButton emoji="🔌" selected={extensionsFocused} logo={require("../../assets/stickers/extensions.png")} />
              <Text style={{ fontFamily: 'Inter-Bold', color: extensionsFocused ? AaroTheme.primaryColorDark : AaroTheme.primaryColorMedium, fontSize: 15, marginLeft: 10 }}>Extensions</Text>
            </View>
          )}
          activeBackgroundColor={AaroTheme.primaryColorLight}
          focused={extensionsFocused}
          style={{ height: 60 }}
          onPress={() => props.navigation.navigate("Extensions")}
          key={"Extensions"}
        />

        {!isDesktop && (

          <DrawerItem
            label={() => (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <EmojiButton emoji="🏠" selected={extensionsFocused} logo={require("../../assets/stickers/hessia_gear.png")} />
                <Text style={{ fontFamily: 'Inter-Bold', color: extensionsFocused ? AaroTheme.primaryColorDark : '#777777', fontSize: 15, marginLeft: 15 }}>Hessia</Text>
              </View>
            )}
            activeBackgroundColor={AaroTheme.primaryColorLight}
            focused={false}
            onPress={() => navigation.openDrawer()}
            key={"Hessia"}
          />
        )}



      </View>
    </>

  );
}

const AaroPluginStore = () => {
  return <PluginStore options={{ basePluginId: AaroApp.id, immediateOnly: false }} />
}

const AaroNav = () => {

  const [routes, setRoutes] = React.useState<RouteConfig[]>([]);
  const [detailWidgets, setDetailWidgets] = React.useState<DetailWidget[]>([]);
  const [headerWidgets, setHeaderWidgets] = React.useState<Widget[]>([]);
  const [installedList, setInstalledList] = React.useState<string[]>(['progress', 'rank', 'sub-habits']);
  const { isDesktop } = useSizes();

  //  NOTE:  Plugins register on mount, and because we add HOCs, they can be re-mounted.
  //  TODO:  Mount plugins in a static way.  I DON'T like the HOC pattern.  It's fine to have mutli-way data flow.  It's something that exists anyways with callbacks.

  const installDetailWidget = (widget: DetailWidget) => {
    const alreadyInstalled = !!detailWidgets.find(_widget => widget.name === _widget.name);
    if (!alreadyInstalled) {
      setDetailWidgets([...detailWidgets, widget]);
    }
  }

  const installHeaderWidget = (widget: Widget) => {
    const alreadyInstalled = !!headerWidgets.find(_widget => widget.name === _widget.name);
    if (!alreadyInstalled) {
      setHeaderWidgets([...headerWidgets, widget]);
    }
  }

  const installRoute = (route: RouteConfig) => {
    console.log("Installing Route!:  " + JSON.stringify(route));
    setRoutes((prevRoutes) => [...prevRoutes, route]);
  }

  console.log("Rendering Routes:");
  console.log(JSON.stringify(routes));


  // const CustomHeader = ({ title }) => {

  //   const navigation = useNavigation();

  //   return (
  //     <View style={{ height: 60, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 10, backgroundColor: 'blue' }}>
  //       <TouchableOpacity onPress={() => navigation.goBack()}>
  //         <Text style={{ color: '#fff' }}>Back</Text>
  //       </TouchableOpacity>
  //       <Text style={{ color: '#fff', fontSize: 18 }}>{title}</Text>
  //       <TouchableOpacity onPress={() => navigation.navigate('Home')}>
  //         <Text style={{ color: '#fff' }}>Home</Text>
  //       </TouchableOpacity>
  //     </View>
  //   );
  // };

  return (

    <AaroContext.Provider value={{ routes, installRoute, detailWidgets, installDetailWidget, installedList, setInstalledList, headerWidgets, installHeaderWidget }}>
      <HOCRegister id="aaro">
        <DrawerNav.Navigator drawerContent={AaroDrawerContent} key={isDesktop ? 'desktop' : 'mobile'} initialRouteName={routes.find(route => !!route.isInitial)?.name || "Extensions"} screenOptions={{ drawerType: isDesktop ? 'permanent' : 'front', headerShown: false, drawerStyle: { width: 200 } }}>
          {
            routes.map(route => {
              const { name, icon, emoji, component, header } = route;
              return (
                <DrawerNav.Screen
                  name={name}
                  component={component}
                  options={{ header }}
                />
              );
            })
          }
          <DrawerNav.Screen
            name="Extensions"
            component={AaroPluginStore}
          // options={({ navigation }) => ({
          //   filtered: true,
          //   header: () => <AaroHeader borderOpacity={1} additional={[]} showFilter={true} title="Extensions" navigation={navigation} />,
          // })}
          />
        </DrawerNav.Navigator>
      </HOCRegister>
    </AaroContext.Provider>
  );
};

export const AaroApp: App = {
  focus: true,
  id: "aaro",
  name: "Aaro",
  description: "Extensible Habits",
  component: AaroNav,
  emoji: "📋",
  icon: {
    name: "unicorn-variant",
    type: "material-community"
  },
  color: "#eeeeee",
  primaryColor: "#333333",
  backgroundColor: "#fafafa"
};

export class AaroPlugin extends CorePluginClass {

  //  CONSIDER:  Instead of leaving context global consider adding it to the plugin hmm...
  public AaroContext = AaroContext;

  public static details = {
    name: 'Aaro Plugin',
    description: 'Aaro App',
    dependencies: [Apps2Plugin.details.id, Hessia2Plugin.details.id, Entity2Plugin.details.id],
    id: AaroApp.id
  }

  //  TODO:  Make this Registration PART of HaliaComponent!  THIS way it's possible to register providers and whatnot!
  //  TODO:  When registering an HOC, we SHOULD have an error if it's not already registered, OR we should jut register it then!

  public registerHOC = (wrapper: any) => {
    registerHOC("aaro", wrapper);
  }

  public removeHOC = (wrapper: any) => {
    removeHOC("aaro", wrapper);
  }

  public install = async (program: Program, { apps, hessia2, entity2 }: { apps: Apps2Plugin, hessia2: Hessia2Plugin, entity2: Entity2Plugin }) => {

    registerHOCRegister("aaro");


    hessia2.registerHOC(({ children }) => {
      // const hessia2Context = React.useContext(Hessia2Context);
      const appsContext = React.useContext(AppsContext);

      React.useEffect(() => {
        appsContext.installApp(AaroApp);
      }, []);

      return (
        <>
          {children}
        </>
      )

    });
    return this;
  }
}


