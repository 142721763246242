
import { CorePluginClass, Program } from 'halia';
import * as React from 'react';
import Lottie from 'react-lottie';
import { Image, LayoutChangeEvent, Text, TouchableOpacity, View } from 'react-native';
import animationData from '../../../assets/stickers/star-sparkle.json';
import { PrimitivesPlugin } from '../../hessia2/plugins/content-plugin';
import { Entity2Plugin } from '../../hessia2/plugins/entity-plugin';
import { AaroContext, AaroPlugin } from '../aaro-core';
import { HabitPlugin } from './habits.extension';
import { ProgressContext, ProgressPlugin } from './progress.extension';
import { RankingContext, RankingPlugin } from './ranking.extension';
import { BoxContainer } from '../utils';

//  Reward Type
//  Reward Distribution - Given rewards
//  Reward Balance 
//  Reward Transaction

//  TODO:  Start SIMPLE.  Just give the SAME reward (points) for each task we finish.

//  NOTE:  We don't ALWAYS need a "Type"!  In this example, we have a "Reward Balance".  This is NOT somethin we're going to be reproducing!

const rewardPluginId = "reward";

// const RewardExtension: Extension = {
//   id: rewardPluginId,
//   name: "Reward",
//   description: "Progressive Rank",
//   color: '#6FE3D4',
//   image: require("../../../assets/stickers/RankSystem.png")
// };

export class RewardExtension extends CorePluginClass {

  public static details = {
    name: 'Rewards',
    description: 'Get rewarded for your work',
    dependencies: [HabitPlugin.details.id, PrimitivesPlugin.details.id, Entity2Plugin.details.id, AaroPlugin.details.id],
    id: rewardPluginId,
    image: require("../../../assets/stickers/rewards.png")
  }

  public rewardCounterId = "reward-counter";

  public install = async (program: Program, { habit, primitive2, entity2 }: { entity2: Entity2Plugin, primitive2: PrimitivesPlugin, habit: HabitPlugin, progress: ProgressPlugin, rank: RankingPlugin }) => {

    //  CONSIDER:  HOW can I make it AUTOMATICALLY SCOPED?  Like when I increase the counter???  I don't want to do this much ceremony... I'd like to it scoped to the current context? hmm...

    //  Install the Point Counter
    // const countEntity = await entity2.entityService.getEntityById(this.rewardCounterId);
    // if (!countEntity) {
    //   console.log("Building Point Counter");
    //   const type: TypeSelection = { type: "anonymous", davelType: { type: "number" } };
    //   const entity = { id: this.rewardCounterId, name: "Reward Counter", description: "Total Points", owners: [AaroSystem] };
    //   const countEntity = await primitive2.createEntityPrimitive(entity, type, 0);
    // } else {
    //   console.log("Reward Counter Exists!");
    // }


    // CONSIDER:  SHOULD be able to generate stats and add them as ENTITEIS as auto-geneated / calculated things mmm... MAYBE that can be a davel type?  It's just a VARIABLE from an expression? Hmm...   THEN we can just use THAT to populate things? Hmm...

    const RewardHeaderWidget = () => {

      const { completed } = React.useContext(ProgressContext);
      const [sparkle, setSparkle] = React.useState(false);
      const prevCompleted = React.useRef(completed);

      React.useEffect(() => {
        if (completed > prevCompleted.current) {
          setSparkle(true);
        }
        prevCompleted.current = completed;
      }, [completed])

      const defaultLottieOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

      return (


        <BoxContainer>
          <Image source={require("../../../assets/stickers/points.png")} resizeMode='contain' style={{ height: 30, width: 30 }} />
          <Text style={{ fontSize: 16, fontFamily: 'Inter-SemiBold', marginLeft: 10, color: '#333333' }}>{completed * 10}</Text>
          <View pointerEvents='none' style={{ position: 'absolute', width: '100%', height: '100%' }}>
            {
              sparkle && (
                <Lottie
                  isStopped={!sparkle}
                  eventListeners={[
                    {
                      eventName: 'complete',
                      callback: () => {
                        setSparkle(false)
                      }
                    },
                  ]}
                  options={defaultLottieOptions}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                />
              )
            }

          </View>


        </BoxContainer>
      );

    }

    const RankReward = ({ rank, img, style = {} }) => {
      const { rank: selectedRank, rankImage, rankTitle } = React.useContext(RankingContext);
      const [showReward, setShowReward] = React.useState(false);
      const toggle = () => setShowReward(!showReward);
      const [width, setWidth] = React.useState(0);
      const [hOffset, setHOffset] = React.useState(0);

      //  TODO:  Be able to display the tooltip from an anchor position!
      const adjustPosition = (event: LayoutChangeEvent) => {
        const leftPos = event.nativeEvent.layout.x;
        const boxWidth = event.nativeEvent.layout.width;
        const rightPos = leftPos + boxWidth;
        const margin = 10;
        const desiredRightPos = width - margin;
        console.log({ leftPos, boxWidth, rightPos, margin, desiredRightPos, containerWidth: width });
        if (rightPos >= desiredRightPos) {
          setHOffset(rightPos - desiredRightPos);
        } else {
          setHOffset(0);
        }
      }

      const setContainerWidth = (event: LayoutChangeEvent) => {
        const _width = event.nativeEvent.layout.width;
        setWidth(_width);
      }
      return (
        <TouchableOpacity style={{ height: 30, width: 30, ...style }} onPress={toggle}>
          <Image source={img} tintColor={rank <= selectedRank ? undefined : "#aaaaaa"} resizeMode='contain' style={{ flex: 1 }} />
          {
            // TODO-NEXT:  Finish the tooltip logic
            showReward && (
              <View onLayout={setContainerWidth} style={{ width: '100%', height: '100%', position: 'absolute' }}>
                <View onLayout={adjustPosition} style={{ marginLeft: -hOffset, marginTop: 25, height: 100, width: 100, backgroundColor: 'white', borderRadius: 5, borderColor: '#555555', borderWidth: 2 }}>
                  <Text>Rewards!</Text>
                </View>
              </View>

            )
          }
        </TouchableOpacity>
      );
    }

    //  TODO:  Sort out these annoying dependencies.
    habit.registerHOC(({ children }) => {

      const { installProgressWidgetExtension } = React.useContext(ProgressContext);
      const { installHeaderWidget } = React.useContext(AaroContext);

      //  TODO:  Pull these from the ranking plugin
      const rank1 = require('../../../assets/stickers/rank_1.png');
      const rank2 = require('../../../assets/stickers/rank_2.png');
      const rank3 = require('../../../assets/stickers/rank_3.png');
      const rank4 = require('../../../assets/stickers/rank_4.png');
      const rank5 = require('../../../assets/stickers/rank_5.png');
      const rank6 = require('../../../assets/stickers/rank_6.png');
      const rank7 = require('../../../assets/stickers/rank_7.png');
      const rankImages = [rank1, rank2, rank3, rank4, rank5, rank6, rank7];

      const { rank, rankImage, rankTitle } = React.useContext(RankingContext);

      React.useEffect(() => {

        //  Install Listeners
        // habit.habitObservationEmitter.addListener("UPDATE", ({ observation }: { observation: HabitObservation }) => {
        //   // alert("Updated!");
        //   // alert(JSON.stringify(payload));
        // });

        // habit.habitObservationEmitter.addListener("CREATE", ({ prev, next }: { prev: HabitObservation, next: HabitObservation }) => {
        //   alert("Created!");
        //   alert(JSON.stringify(payload));
        // });


        installHeaderWidget({
          name: "Points",
          description: "Habit Points",
          component: RewardHeaderWidget,
          pluginId: RankingPlugin.details.id
        });


        // TODO:  Generalize to "Widget Overlay" or something
        installProgressWidgetExtension({
          id: "rewards-ext", component: () => {
            return (
              <View style={{ width: '102%', left: "-1%", height: '100%', paddingTop: 28, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'absolute', zIndex: 100 }}>
                {rankImages.map((img, rank) => (
                  <RankReward img={img} rank={rank} />
                ))}
              </View>
            )
          }, name: "Reward Extension", description: "Reward Extension"
        });
      }, []);

      return (
        <>
          {children}
        </>
      );
    });


    return this;
  }
}