
import { loadFromString } from 'elvan';
import { EventEmitter } from 'events';
import { CorePluginClass } from "halia";
import { HaliaComponent, HaliaComponentPlugin } from 'halia-native';
import * as React from 'react';
import { Text, View } from 'react-native';
import { Icon } from "react-native-elements";
import { MenuLayoutPlugin } from '../../elements/menu-layout-plugin/menu-layout-plugin';
import { ConfigurePlugins, PluginExplorer } from "./plugin-list";
import { HaliaPluginContext, HaliaPluginState } from '../halia-context';


//  CONSIDER:  WHYYY Should this need to be DUPLICATED here.. SO much fucking DUPLCIATE... why not SHOW It as a user-level construct?? SOOO MUCH of this can be shown in a HIGHER LEVEL domain ugh!!!  Functions and Variables.. that's a base layer... the idea of ENCODINGS... where we can have a thing that's going to be interpreted to DO thing shm!  ... like MAKE functions and fucking variables ugh! 
//  CONSIDER:  WHY should THIS module know HOW the APp is set??? It shouldn't!  IT doesn't matter as long as it has one hm!  AHH!  The POINT is.. there ARE contexts that don't need to know ahh!!  Because we have a set of ENTIEIS and componsition for them and THIS one doens't need to "know" and REFERENE tha thm!!
//  TODO:  Support options set in OPTIONS Pages hm!  PLUS make that even more COPMELX with coupling plugins and stuff hM!  Plut GROUP Plugins, Generics, etc hm!!!  Make this work for COMMON libraries like Reacy Navigation, etc hm!!!
//  TODO:  Make it DYNAMICALLY adjust soo... when we're on an iPhone / small screen we add a page, OTHERWISE, we split hm!  MIGHT need to add a COMPONENT to listen for this.  EITHER WYA< I think the PLUGINS aREEE part of the React State hm!  They are themselves reactive? hmm.. interesting.


//  UPDATE:  CAN be simple!  ALL we need to do is UGH!... fucking IQ shit fuck.  ALL we need to do it have a Plugin we can use to yeah.. UGH!  I miss Taylor ugh!!!  Have a Plugin we can use to ... if it's a WELL KNOWN game then lots of people ar ein it.... Fucking PROJECT MANGAMENT is.. so WTF... why is this not happening more now??? MAybe it is IDFK... just get out there with your ideas because you're CREATIVE!  You don't need to be a fucking IQ 170 person to do this shit, and that's fine... Think of Node and NPM ... they were normal fucking people and it's fine!  BUT I ALSO like thinking big, and that's GOOD I believe!  I believe it places me in a unique position actually hm!  I have a unique perspective over which more "intelligent" people might miss because they're not looking her ehm!

//  TODO:  DON'T just hard-code? Hmm... have a way to INSTALL plugins for USE, and MAYBE have then available externally too? Hmmmmm  DEFINLTY ... shold b eable to add LOTS of plugins for use in these apps, POSSIBLY in an app-by-app basis.. In fact, we MAY want to just pass the whole fucking Plugin along when we serialize? Hmmmmmm... maybeeee......  CAN also do versiona nd stuf? hmm..
// export const knownPlugins: (typeof CorePluginClass)[] = [];
// // export const knownPlugins = [HaliaComponentPlugin, GalleryPlugin];

// export const registerPlugins = (plugins: (typeof CorePluginClass)[]) => {
//   knownPlugins.push(...plugins);
// }


export const PageLoader = (props) => {
  const { loading, children } = props;
  if (loading) {
    return (<View style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', height: '100%' }}><Text>Loading</Text></View>);
  } else {
    return children;
  }
};


const shadowStyle = {
  shadowColor: 'black',
  shadowRadius: 2,
  shadowOpacity: 0.04,
  shadowOffset: {
    width: 3,
    height: 3
  },
};

export interface CircleIconProps {
  icon: { name: string, type: string };
  backgroundColor: string;
  iconColor: string;
}

export const CircleIcon = ({ icon, backgroundColor, iconColor }: CircleIconProps) => {
  return (
    <View style={{ height: 60, width: 60, borderRadius: 30, backgroundColor, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <Icon name={icon.name} type={icon.type} color={iconColor} size={30} />
    </View>
  );
}

export const SquareIcon = ({ icon, backgroundColor, iconColor }: CircleIconProps) => {
  return (
    <View style={{ height: 70, width: 70, borderRadius: 15, backgroundColor, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <Icon name={icon.name} type={icon.type} color={iconColor} size={30} />
    </View>
  );
}

//  TODO:  Figure out how to extend the View Props.
export const Page = (props?: any) => (<View {...props} style={{ display: 'flex', flexDirection: 'column', flex: 1, ...(props?.style as any || {}) }}></View>);

// //  TODO:  DON'T like to recreate a common thing.. prefer to REFERENCE It... in this case, I prefer to REFERENCE the class thing and have the NAME ONCE... this way it's all linked, and we don't have to KEEP reproducing it ugh!  SO much of this is in violation to general software principles!
// export interface HaliaPluginManagerProps {}
// interface HaliaPluginManagerState {
//   plugins: any[];
// }
// class HaliaPluginManagerBase extends React.Component<HaliaPluginManagerProps, HaliaPluginManagerState> {
//   constructor(props: HaliaPluginManagerProps) {
//     super(props);
//     this.state = {
//       plugins: []
//     }
//   }
//   public render = () => {
//     return (
//       <PageLoader loading={false}>
//         <Page style={{ paddingHorizontal: 20, backgroundColor: '#fafafa' }}>
//         {/* <View style={{ backgroundColor: 'blue' }}>
//           { component }
//         </View> */}

//           {/* TODO:  Abstract Header?  The idea is, we have multiple headers, OR maybe just PARTS that we put together? Hmmm.  Either way, could be helpful just to abstract.  MAYBE with inejctions and the Component Plugin pattern? hmmm! */}
//           <View style={{ paddingTop: 50, display: 'flex', flexDirection: 'row' }}>
//             <Text style={{ flex: 1, color: '#3b3b3b', fontSize: 30, fontFamily: "Poppins-Bold", letterSpacing: -0.5 }}>Plugins</Text>

//             {/* TODO:  Abstract with Hessia? */}
//             <TouchableOpacity onPress={ () => { alert("Should create!") } } style={{ width: 40, height: 40, borderRadius: 20, display: 'flex', flexDirection: 'column', backgroundColor: Colors.Primary1, alignItems: 'center', justifyContent: 'center' }}>
//               <Text style={{ color: 'white', fontSize: 30, fontFamily: "Poppins-Bold" }}>+</Text>
//             </TouchableOpacity>
//           </View>

//           <View style={{ height: 20 }} />

//           <ScrollView style={{ flex: 1, overflow: 'visible' }}>
//             <FlatList
//               style={{ overflow: 'visible' }}
//               data={ this.state.plugins }
//               renderItem={({ item }) =>
//                 // TODO:  Enable the Plugin with a "Plugin Manager" AND show the STATUS of plugis!? HM!  MAYBE show nested, and ALSO other thigns.. MAYBE support plugins FOR the plugin system.. hmm.. too meta for now I thin haha, BUT maybe!  ALSO, show which are enabled / disabled and stuff...
//                 <TouchableOpacity style={{ backgroundColor: 'white', borderRadius: 10, padding: 30, ...shadowStyle }} onPress={ () => enablePlugin(item) }>
//                   <View style={{ display: 'flex', flexDirection: 'row' }}>
//                     <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//                       <Text style={{ color: '#666666', fontSize: 16, fontFamily: "Poppins-Bold" }}>{ item.name }</Text>
//                       <Text style={{ color: '#aaaaaa', fontSize: 13, fontFamily: "Poppins-Bold" }}>{ item.description }</Text>
//                     </View>
//                     <CircleIcon icon={{ type: "material-community", name: "balloon" }}  iconColor="#DD0000" backgroundColor="#eeeeee"/>
//                   </View>
//                 </TouchableOpacity> }
//             />
//           </ScrollView>

//         </Page>
//       </PageLoader>
//     );
//   }
// }
// export const HaliaPluginManager = HaliaPluginManagerBase

export interface Plugin {
  id: string;
  name: string;
  description: string;
  icon: string;
  module: string;
  type: string;
}


export enum HaliaEvents {
  PluginPressed = "plugin-selected",
  PluginsUpdated = "plugins-updated"
}

export class UnknownPluginError extends Error { }

export class HaliaStudioPlugin extends CorePluginClass {

  public static details = {
    name: "Halia",
    description: "Manage Halia Plugins in your App",
    //  NOTE:  I think we can make this more "advanced".. basically make more system / interpreters.  THen we can do thingsl ike depend on a CATEGORY adn require certian connections etc hm!  EITHER WAY... it's all just being plugged into an ontological strucutre.  Whatever conditoins need to be met can perhaps be expressed hM!!  Perhaps systems to verify and show injection sites hm!! I like that hm!  MAYBE a way to look at the "App" in a global view and show the injection sites visually? Hm!  Plus flow-based view, etc hm!
    //  TODO: Instead of injecting in the MENU Layout or the React App, CAN we just inject ourselves more GENERICALLU???Hm
    //  TODO:  Don't couple with HaliaAppPlugin!!!
    //  CONSIDER:  Instead of copuling with its host, IT should NOT "know"!  Meaning.... we should not be able to able to determine it from this!  Instead, it should be coupled externally.  
    dependencies: [MenuLayoutPlugin.details.id],

    //  TODO-GENERAL-ERRORS:  Work on more informatiive / contextual erros, MAYBE not just with debugger and stuff but a WHOLE program to INTERPRET the errors and help us debug.. like an OBSERVER .. the thing looking at our thoguhts hm!  THIS way, we can freaking like...  Yeah all that, UI too, and Pluggable, maybe like the new thing I keep seeing idk, but alsooo like.. ways to replace key internals and maybee a FULLY pluggable app hmm.. not limited to just a few integration oints.. kindalike fucking DOM.. but where there are INTERFACES and stuff hmm COMPOSABLE interfaces hm!  MAYBE some interfaces where are dynamic or non-static hm;asldfjk .. plus want to show the NAME error when I incorrectly use the wrong name of a plugin 
    id: "halia"
  }

  //  TODO:  Consider passing default plugins here?
  //  CONSIDER:  ALSO, consider ability to use the NOde Net / Graph thing / Meta Mesh? to make all this shit woerlkjdf k?  the TAG hierarchy things hm!  Global entity list hM!



  //  TODO:  SHouldn't reach direclty into the Program?  Actually.. Program sholdn't be aware of this.  Use another Halia Stack and pass the other plugins down to it?? Hm!
  //  TODO-NEXT:  Move this INTO the React State Tree.. THIS way, as the PLUGIN state changes, the dependencies can DEPEND on the STATE and REACT HM!



  //  CONSIDER:  MAYBE we can see the FUNCTIONS and interface in the UI for the Plugins and shi thm!


  // public installPlugin;
  // public uninstallPlugin;
  // public registerPrimitivePlugin;
  // public getInstalledPlugins!: () => (typeof CorePluginClass)[];
  // public addPluginOption;
  // public onPluginPressed;
  // public addWindow;
  // public getPlugins;
  // public setPlugins;

  //  TODO:  SHOULD just be able to DEPEND on the thing hm!  EVEN if it's a piece of DERIVED state hm!  LIKE when we update the NUMBER of a thing to indicate how many times it's happened like I do in the Composer hM!
  public onPluginsUpdated;
  public unregisterOnPluginsUpdated;

  //  NOTE:  Will be injected by the Window once mounted... 
  //  TODO:  Work on ways to ENCODE this more reliably and in an interpretable way.. PROBALBY usign UIs and stuff hsadlfkj !  That... soudns idkm sadlkfjasdf ... fuck

  //  Have SPECIFIC contexts, like Installed Plugins, Uninstalled Plugins, etc hmmm AGAIN... the idea of fucking STUFF graph was MINE from LONGGG agho!!! The idea of certain apps associaitn fan dshit hm!  The idea of CONTEXT also came in TrackMine BEFORE HM!ugh!!  It's JUST EAV and Knowekdg Graph shit hasdfj !

  //  REALIZATION:  !! AH!  THis is JUST a builder ... a Fucking VISUAL TOOL for the HaliaComponent tool hm!
  //  TODO:  Do something like Nest.js where we can get the import with a parameter decorator?
  //  TODO:  Generalie errr hndling for plugins with a decratorr or somethign to report the plugin name? HM!  I's JUST a symbol with stuff associated with it, and we INJECT!
  //  TODO:  All Halia Plugins SHOULD load immediately.. LIKE a React RENDER method hm!

  //  CONSIDER:  We're building a NEW stack... SO... what should we do about that?  The IDEA is... keep it simple.. FOR NOW... when the Plugin List changes, we should TOTALLY re-build the stack hm!

  //  TODO:  SHULD check that ALL dependencies have been injected as per the injection rules hm!!

  //  CONSIDER:  SHOULD we register the HOC automatically? Hmm... 
  //  TODO:  NONE of this will go into effect until AFTER the comopnetns are mounted.. hmm... We MAY want to mount AS we INSTALL... this way, DEPENDNCIES can use THIS part of our API hmm... MAY want to separate it from the UI too hm.. a DIFFERENT React Tree but with Plugins? Hmm...
  //         So... consider having ALL Plugins have a STATE container that PINGS plugins when they change and stuff?? Hmmm... maybe like re-render? Hmm...

  //  TODO:  CONSIDER automatically doing this for certain classes of Plugin? Hmm....

  //  NOTE:  We WRAP the component registration in a promise to MAKE SURE it's mounted before we move on... this holds up the rendering AND it just doesn't seem great!  WORK ont his!! HOW can we do it instantly but STILL feedback to the install loop when it's ready? Hmm... keep it simple too sdakljdafsk!

  //  TODO:  Memoize and stuff
  //  TODO:  Don't hard-code the type, COULD be unknown!
  //  FOR NOW, let's just keep the selection here at the global level? Hmm... BUT  one challenging thing is we MAY want to split out those pieces further hm!  Like... expliclt features hm!  THIS way, while this CAN be coupled here... WHY shold it!?? CONCEPTUALLY, ALLL the plugins are PART of this "Halia" system hmmm.. perha hs!  It's up to the USER what they want in their Halia system hm!
  //  CONSIDER:  We COULD return an Observable?  Hmm.. why?  To make it easier to chain and stuff? Hmm... but an observable is a FUNCTION GENERALIZATOIN and we're NOT returing a function here so... hmm..
  //  SO MANY cases where we just freaking have ugh... LINKS betwen thigns.. FLOW!!! So.l.. whatever yghh
  //  TODO:  Instead of coupling with haliaAPP , we might have SEVERAL types of things.. the idea is to do it agnosticalll ymm...... have a PROVIDER to inject us with what we need!fasdlkjdasl;fjkl


  public getPluginForIds = (ids: string[]) => {
    return ids.map(id => this.getPluginById(id));
  }

  /**
   * Returns the immediate dependents of the give plugin.
   * @param {string} pluginId - The Plugin to get immediate dependents for.
   * @returns {string[]} - The list of immediate dependent plugins.
   */
  public getDirectDependents = (pluginId: string) => {
    return this.getPluginForIds(this.getDirectDependentIds(pluginId));
  }

   /**
   * Returns the dependents of the give plugin.
   * @param {string} pluginId - The Plugin to get immediate dependents for.
   * @returns {string[]} - The list of dependent plugins.
   */
   public getDependents = (pluginId: string) => {
    return this.getPluginForIds(this.getDependentIds(pluginId));
  }

  /**
   * Returns the list of immediate dependent IDs for the given plugin.
   * @param {string} pluginId - The Plugin to get immediate dependents for.
   * @returns {string[]} - The list of immediate dependent plugin IDs.
   */
  public getDirectDependentIds = (pluginId: string) => {
    return this.knownPlugins.filter(_plugin => _plugin.details.dependencies.includes(pluginId)).map(_plugin => _plugin.details.id);
  }

  /**
   * Returns the list of all dependents for the given plugin.
   * @param {string} pluginId - The Plugin to get dependents for.
   * @returns {string[]} - The list of dependent plugins IDs.
   */
  public getDependentIds = (pluginId: string): string[] => {

    //  Get Direct Dependents
    const directDeps = this.getDirectDependentIds(pluginId);
    if (!directDeps.length) { return []; }

    //  Get Indirect Dependents for Each Dependent
    const dependents: string[] = [...directDeps];
    directDeps.forEach(dep => {

      //  Get the Indirect Dependents
      const indirectDeps = this.getDependentIds(dep);

      //  Filter Duplicates
      indirectDeps.forEach(indirectDep => {
        if (!dependents.includes(indirectDep)) {
          dependents.push(indirectDep);
        }
      })
    })
    return dependents;
  }

  /**
   * Given a Plugin ID, determines if its dependencis are installed.
   * @param {string} pluginId - The Plugin ID to check dependencies for.
   * @returns {boolean} - True if the plugin's dependencies are installed.
   */
  public areDependenciesInstalled = (pluginId: string) => {
    const plugin = this.getPluginById(pluginId);
    if (!plugin) { throw new UnknownPluginError() }
    const installedPlugins = this.getInstalledPlugins();
    const installedPluginIds = installedPlugins.map(_plugin => _plugin.details.id);
    return plugin.details.dependencies.reduce((prev, current) => !!installedPluginIds.includes(current), true);
  }

  //  TODO:  Consider a new "Class" where we can specity anything like... the TYPE of a thing and CRUD ANYTHING.  Then if there are assocaited functions to solve a thing, we can "find" it.  This basically starts to align with AI.

  public getPluginById = (pluginId: string) => {
    const knownPlugins = this.getKnownPlugins();
    return knownPlugins.find(_plugin => _plugin.details.id === pluginId);
  }
  public knownPlugins: (typeof CorePluginClass)[] = [];
  public installedPlugins: (typeof CorePluginClass)[] = [];

  public haliaEmitter = new EventEmitter();

  constructor(public options: { knownPlugins: (typeof CorePluginClass)[], installedPlugins: (typeof CorePluginClass)[] }) {
    super();

    this.knownPlugins = options.knownPlugins || [];
    this.installedPlugins = options.installedPlugins || [];

    //  NOTE:  Ahh!! This is doing the SAME as the fucking Halia thing ugh!
    this.onPluginsUpdated = (callback) => this.haliaEmitter.addListener(HaliaEvents.PluginsUpdated, callback);
    this.unregisterOnPluginsUpdated = (callback) => this.haliaEmitter.removeListener(HaliaEvents.PluginsUpdated, callback);
  }


  //  TODO:  Support options!
  public installPlugin = async (plugin: typeof CorePluginClass, options?: any) => {
    // this.installedPlugins.push(plugin);

    this.installedPlugins = [...this.installedPlugins, plugin];
    //  TODO:  REfactor this emitter!! LOTS OF common patterns!! Can be ABSTRACTED so we have a NEW domain of discourse with those common tools hm!
    this.haliaEmitter.emit(HaliaEvents.PluginsUpdated, this.installedPlugins);
  }

  public setPlugins = async (plugins: (typeof CorePluginClass)[], options?: any) => {
    this.installedPlugins = [...plugins];
    this.haliaEmitter.emit(HaliaEvents.PluginsUpdated, this.installedPlugins);
  }

  public uninstallPlugin = async (plugin: typeof CorePluginClass) => {

    //  TODO:  This logici is DEFINILTY available in Lodash or soemthing ugh!!!  SOOOOO Many things are just patterns REPEATED UGH!  It's NOT hard to see!  Doens't required fucking top notch matrix skills or whatever.  It's JUST thinking DEEPLY and moving pieces until it becomes more relaxing hm!
    const newInstalledPlugins = [...this.installedPlugins];
    const index = this.installedPlugins.findIndex(_plugin => _plugin.details.id === plugin.details.id);
    if (!index) { throw "No installed Plugin with the given ID: " + plugin.details.id }
    newInstalledPlugins.splice(index, 1);
    this.setPlugins(newInstalledPlugins);
  }


  public registerPrimitivePlugin = async (text: string) => {

    await loadFromString(text, { setPlugin: (plugin: typeof CorePluginClass) => this.installPlugin(plugin) });
  }


  public getKnownPlugins = () => {
    return this.knownPlugins;
  }
  public getInstalledPlugins = (): (typeof CorePluginClass)[] => {

    // const stack = haliaAppPlugin?.selectedApp?.stack;

    // if (!stack) { return []; }

    // const plugins = stack?.map(stackItem => {

    //   let plugin = this.knownPlugins.find(plugin => plugin.details.id == stackItem.id) as (typeof CorePluginClass)
    //   if (!plugin) { plugin = { details: { id: stackItem.id, name: "MISSING", description: 'Missing ' + stackItem.id, dependencies: [] } } as any }
    //   return plugin;
    // });
    return this.installedPlugins;
  }

  public setPluginOption = (plugin: typeof CorePluginClass, options: any) => {
    /**
     * Set Options on the Selected App
     */

    alert("Should set options!")
  }


  public onPluginPressed = (callback: (plugin: typeof CorePluginClass) => void) => {
    this.haliaEmitter.addListener(HaliaEvents.PluginPressed, callback);
  }

  /**
   * Returns the list of plugins which are currently uninstalled by required for the given plugin.
   * 
   * @param {string} pluginId - The plugin to get missing dependencies for.
   * @returns {string[]} - List of missing dependencies.
   */
  public getMissingDependencies = (pluginId: string) => {
    const plugin = this.getPluginById(pluginId);
    if (!plugin) { throw new UnknownPluginError(); }
    const installedPluginIds = this.installedPlugins.map(_plugin => _plugin.details.id);
    return plugin.details.dependencies.filter(dep => !installedPluginIds.includes(dep));
  }

  /**
   * Returns True if the given plugin is installed.
   * @param { string } Plugin ID
   * @returns { bolean } True if the given plugin is installed.
   */
  public isInstalled = (pluginId: string) => {
    const plugin = this.getPluginById(pluginId);
    if (!plugin) { throw new UnknownPluginError(); }
    return this.installedPlugins.includes(plugin);
  }

  public install = async (program: any, { menuLayout }: { menuLayout: MenuLayoutPlugin }) => {

    menuLayout.registerLayoutHOC(({ children }) => {

      const [state, setState] = React.useState<HaliaPluginState>({
        haliaPlugin: this
      });

      return (
        <HaliaPluginContext.Provider value={state}>
          {children}
        </HaliaPluginContext.Provider>
      );
    });

    menuLayout.registerMenuItem("Configure", {
      name: "Configure",
      icon: { name: "plug", type: "font-awesome" },
      component: () => <ConfigurePlugins halia={this} />
    });

    menuLayout.registerMenuItem("Library", {
      name: "Library",
      icon: { name: "book", type: "font-awesome" },
      component: () => <PluginExplorer />
    });

    menuLayout.registerMenuItem("App", {
      name: "App",
      component: App,
      icon: { type: "material", name: "apps" },
      isInitial: true
    });

    console.log("Installed the Halia Plugin!");


    //  TODO:  Serialize and SAVE this.. and for the other non-string plugins, just make sure we have the Class mappin ah!  We can do the SAME with these hm!  THEN after being registered, we STORE the class map hmm.. Oh.. but we need to initialize on each load? Hmm ... itneresting.

    //  NOTE:  This is a COMMON pattern.. the Getter / Setter, where GETTER cannot MODIFY... WHY not be able to do this with explicit read / write modifiers ? hmm... with another system?  WHY limit the ontological strcuture like this???  With JUST the functional interface?? dsfj... RALLY need to track GOALS and PROGRESS on them and stuff ugjlkjafsd
    //  TODO:  Decouple from the APp Plugin!  That's only needed when we want to support multiple apps hmm...
    //  TODO:  Support options!
    //  TODO:  When we do things like "register primtive" OFTEN we have everything we need to make the association based on the position of the original thing in the Graph... then for what we don'thave we can MAP and shit hm!  This way we can auto-bind / guided bind hm!  We can do this in regular languages too perhaps. hmmm... the opint is.. use the informaiotn we have about a thing to auto-bind and shit hmm.. WITHOTU expressing it hard-coded? Hmmmsadfasfdj..  ONE posible thing is the class and parent clsas.. but that's just ONE thing!  Ughjhh  If anythinggg ... show it in a UI where we can do this visually and shit hm.. see which pieces need to be completed and ughh allll that!  We CAN and DO seem to be moving in that direction!!  The more interpretable stuff we have the better ?? Hm!  I WAS planning to have classes and Trackers with inheritance and app-mapped UIS... plus I thoguht of metadata for bglobal list, either way that's public EAV thing!  Dude .. plus aggregate trackers I mean soo much, PLUS I did that before too!
    //  TODO:  Support CONTEXTUAL Plugin activation?  MAYBE let this system be affected by the "Context".. DON'T think we need to store globally, EACH system can manage it itself? Hmm.. perhaps... MAYBE still beneficial to olog globally though? Hmm.. I DO like the Flux pattern to a degree and shit hmm  I like event sourcing hm
    //  TODO:  Generalize this so it can be stored anywhere and yeah.. work on a generic interfac.e. SO similar to SO many other things ugh!!  It's a IMPELEMTAITON of a pattern... and the COMPOSIITON isike a COLOR.. a textureized object hm!  Find a COMPRESSED way to represent this!! It's about COMPRESSIION HM!
    //  CONSIDER:  MAYBE do it in another Halia Stack.. I don't feel like the origianl stack should knwo.. hmm MAYBE it's fine FO RNOW>. but it's all about responsiblity and shit.


    // alert("Installed Halia")
    // menuLayout.registerMenuItem("Plugins", {
    //   name: "Plugins",
    //   icon: { name: "plug", type: "font-awesome" },
    //   component: () => <PluginList pluginOptions={ this.pluginOptions } installPlugin={ this.installPlugin } registerPrimitivePlugin={ this.registerPrimitivePlugin } />
    // });

    //  Install the Selected Plugins


    // await Program.haliaStack?.build();
    // menuLayout.registerMenuItem("Plugins", {
    //   name: "Plugins",
    //   icon: { name: "plug", type: "font-awesome" },
    //   component: PluginList
    // });

    return this;
  }
}

export const App = () => {

  const { haliaPlugin } = React.useContext(HaliaPluginContext);

  if (!haliaPlugin) {
    return <Text>Loading HaliaPluginContext</Text>;
  }

  const [installedPlugins, setInstalledPlugins] = React.useState(haliaPlugin.getInstalledPlugins());

  const handler = app => {
    const installedPlugins = haliaPlugin.getInstalledPlugins();
    setInstalledPlugins(installedPlugins);
  };

  React.useEffect(() => {
    //  NOTE:  We do this because we want to make sure to update after the plugisn change.
    //  TODO:  This is an example of reactive programming and this is a heavy overhead.. NOT in terms of encoding overhead, but the fact that we're not associting direclty with the state and using the event and hmm.. maybe.. seems we could abstract a bit more here hm!
    haliaPlugin.onPluginsUpdated(handler);
    // handler({});
    return () => {
      haliaPlugin.unregisterOnPluginsUpdated(handler);
    }
  }, [])

  return <HaliaComponent plugins={installedPlugins.map(installed => ({ plugin: installed, options: {} }))} props={{}} />;
}
