
/**
 * Associates three entities with a Sub -Verb-> Obj triple.  This structure is what I call a "Universal Modeler", because it seems that it can be used to model ANY higher-order structure.
 * 
 * E.g. Will -isA-> Person
 * 
 */
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";
import * as Davel from 'davel';
import { CorePluginClass } from "halia";
import * as React from 'react';
import ReactFlow, { Handle, addEdge, getBezierPath, getEdgeCenter, getMarkerEnd, removeElements } from "react-flow-renderer";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import { DataTable } from "react-native-paper";
import { DavelForm, registerDavelType } from "../../../packages/davel-ui/davel-ui";
import { registerSDTSchema } from "../../../packages/davel-ui/types/sdt/sdt-field";
import { SystemHeader, SystemIcon } from "../../../packages/kelp-bar/system-header";
import { NounServiceInstanceInternal } from "../../../packages/noun-service/noun-service";
import { Entity2Plugin, EntityContext, EntityListView, EntityTable, PersonalSystem, getEntities } from "./entity-plugin";
import { Entity } from "./entity-service";
import { Hessia2Context, Hessia2Plugin, System } from "../Hessia2Plugin";
import { PrimitivesPlugin, PrimitivePluginContext } from "./content-plugin";
const uuid = require('uuid/v4');
var dagre = require("dagre");


export interface Edge {
  sub: string;
  pred: string;
  obj: string;
}

export interface ExpandedEdge {
  sub: Entity;
  pred: Entity;
  obj: Entity;
  id: string;
}

const EdgeSchema = {
  type: "object" as "object",
  properties: {
    sub: { type: "entityid" },
    pred: { type: "entityid" },
    obj: { type: "entityid" }
  }
};

const PredicateSchema = {
  type: "object" as "object",
  properties: {}
};

// //  Add Dagre Edges
// edges.forEach(edge => {
//   const { srcEntityAddress, destEntityAddress, differentiatorEntityAddress } = edge.payload;

//   //  TODO:  Support 
//   //  NOTE:  AHH!  I remember talking about a SUPER NODE in IFT where I wanted to GROUP things as one hm!  This is an example of abstraction?? mmm..  It BECOMES a thing hmm... 


//   //  CONSIDER:  MAYBE even "Differentiator" is too complex? Hmm... this is directed path hmm...
// });


export const CustomNode = React.memo(({ data, isConnectable }: { data: Entity, isConnectable: boolean }) => {

  const { entities, selectEntity, openEditor } = React.useContext(EntityContext);
  const selectAndEdit = (entity: Entity) => {
    const nounEntity = entities.find(_entity => _entity.payload.id === data.id);
    if (!nounEntity) { return; }
    selectEntity(nounEntity);
    openEditor();
  };

  if (!data) {
    return <Text>Missing Data</Text>;
  }

  return (
    <>
      <Handle
        type="target"
        position={"left" as any}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />

      <TouchableOpacity onPress={() => selectAndEdit(data)} style={{ backgroundColor: '#fafafa', width: 30, height: 30, alignItems: 'center', justifyContent: 'center', borderRadius: 15, borderWidth: 1, borderColor: '#eeeeee' }}>
        <Text>{data.emoji}</Text>
      </TouchableOpacity>
      <Text style={{ position: 'absolute' }}>{data.name || data.id}</Text>

      <Handle
        type="source"
        position={"right" as any}
        id="a"
        style={{ top: 10, background: '#555' }}
        isConnectable={isConnectable}
      />
    </>
  );
});


export const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  label,
  arrowHeadType,
  markerEndId,
}) => {
  // Generate the path for the edge
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  // Determine the center of the edge for label placement
  const [centerX, centerY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  // Define the marker end used for the arrow. This uses React Flow's utility function to get the appropriate marker end.
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const { entities, selectEntity, openEditor } = React.useContext(EntityContext);

  const selectAndEdit = () => {
    const nounEntity = entities.find(_entity => _entity.payload.id === data.pred.id);
    if (!nounEntity) { return; }
    selectEntity(nounEntity);
    openEditor();
  };

  const selectAndEditEdge = () => {
    const nounEntity = entities.find(_entity => _entity.payload.id === data.edge.id);
    if (!nounEntity) { return; }
    selectEntity(nounEntity);
    openEditor();
  };

  return (
    <>
      {/* The actual line/path of the edge */}
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        onClick={selectAndEditEdge}
      />

      <foreignObject width="100" height="50" x={centerX - 50} y={centerY - 25}>
        <div style={{ backgroundColor: '#fafafa', width: '100px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '15px', borderWidth: '1px', borderColor: '#eeeeee', cursor: 'pointer' }} onClick={() => selectAndEdit()}>
          <span>{data.pred.name || data.pred.id}</span>
        </div>
      </foreignObject>

    </>
  );
};


/**
 * React Flow
 * @param reactFlowInstance 
 */
const onLoad = (reactFlowInstance) => {
  console.log('flow loaded:', reactFlowInstance);
  reactFlowInstance.fitView();
};

export const EdgeGraph = ({ nodes, edges }: { nodes: Entity[], edges: ExpandedEdge[] }) => {

  //  REFERENCE:  https://github.com/dagrejs/dagre/wiki
  const g = new dagre.graphlib.Graph();
  g.setGraph({ rankdir: "LR", nodesep: 150, ranksep: 150 });
  g.setDefaultEdgeLabel(function () { return {}; })

  const initialElements: any[] = [];

  //  CONCERN:  It LOOKS Like it doesn't AUTO-POSITION UGH!

  //  Render (For Size)
  //  Layout
  //  Render (Final)


  //  Add Dagre Nodes and Edges

  nodes.forEach(node => {
    g.setNode(node?.id, { label: node?.id, width: 30, height: 30, node: node });
  });

  edges.forEach(edge => {
    const { sub, pred, obj } = edge;
    g.setEdge(sub.id, obj.id);
  });



  dagre.layout(g);

  //  Add React Flow Nodes
  g.nodes().forEach((nodeId) => {
    const node = g.node(nodeId);
    if (!node) { return }
    initialElements.push({
      id: nodeId,
      data: node.node,
      type: 'custom',
      position: { x: node.x, y: node.y },
    });
  });


  //  Add React Flow Edges
  edges.forEach(edge => {

    const { sub, pred, obj } = edge;

    //  NOTE:  In the future, if we have INSTANCES, this and other places need to change

    initialElements.push({
      id: sub.id + pred.id + obj.id,
      source: sub.id,
      target: obj.id,
      label: pred.name,
      data: { pred, edge },
      type: 'customLabel',
      arrowHeadType: 'arrowclosed'
    });

  });

  const [elements, setElements] = React.useState(initialElements);
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els as any) as any);
  const onConnect = (params) => setElements((els) => addEdge(params, els as any) as any);


  return (
    <ReactFlow
      elements={initialElements}
      onElementsRemove={onElementsRemove}
      onConnect={onConnect}
      onLoad={onLoad}
      snapToGrid={true}
      snapGrid={[15, 15]}
      edgeTypes={{
        customLabel: CustomEdge as any,
      }}
      nodeTypes={{
        custom: CustomNode as any,
      }}
    />
  )
}

export interface EdgeEntity extends Entity {
  edge: Edge;
}

//
//  Explorer View
//


export const GraphView = ({ entityNouns, edgeNouns }: { entityNouns?: NounServiceInstanceInternal<Entity>[], edgeNouns?: NounServiceInstanceInternal<Entity>[] }) => {

  const _entityNouns = entityNouns || getEntities();
  const _edgeNouns = edgeNouns || useEdgeEntities();

  const entities = _entityNouns.map(entity => entity.payload);
  const edges = _edgeNouns.map(edge => edge.payload);

  const extendedEdges: ExpandedEdge[] = edges.map(edge => {

    const { sub, pred, obj } = edge.value as Edge;

    const srcNode = entities.find(node => node?.id === sub);
    const predNode = entities.find(node => node?.id === pred);
    const destNode = entities.find(node => node?.id === obj);

    if (!srcNode || !destNode) {
      console.log("Missing nodes for edge: " + JSON.stringify(edge));
      return undefined;
    }

    return (
      {
        sub: srcNode, pred: predNode, obj: destNode, id: edge.id
      }
    );


  }).filter(edge => (edge != undefined)) as ExpandedEdge[];


  //  Show only the entities linked to edges
  //  NOTE:  I SHOULD be able to state this and hav the AI build the system!!!
  //  NOTE:  The VALUE here is in the tools to work withou yur DATA!  It's not about beating AI!

  const linkedEntityIds: string[] = [];
  edges.forEach(edge => {
    const value = edge.value;
    if (!value) { return; }
    const { obj, sub, pred } = value;
    if (obj && !linkedEntityIds.includes(obj)) {
      linkedEntityIds.push(obj);
    }
    if (sub && !linkedEntityIds.includes(sub)) {
      linkedEntityIds.push(sub);
    }
    if (pred && !linkedEntityIds.includes(pred)) {
      linkedEntityIds.push(pred);
    }
  });

  const linkedEntities = linkedEntityIds.map(id => entities.find(entity => entity.id === id));

  return (
    <>
      <EdgeGraph nodes={linkedEntities} edges={extendedEdges} />
    </>
  )
}


// CONSIDER:  We need to be able to mediate these with STATE / CONTEXT (like a TM).
// const EntityExtensions = ({ entity }: { entity: Entity }) => {
//   const graph = React.useContext(Graph2Context);
//   const extensions = graph?.enitityExtensions;
//   return (
//     <View>
//       {
//         extensions ?
//           (
//             <FlatList
//               data={graph?.enitityExtensions}
//               ItemSeparatorComponent={() => <View style={{ height: 20 }} />}
//               renderItem={({ item }) => {
//                 const Comp = item.DetailComponent;
//                 return <Comp entity={entity} />
//               }}
//             />
//           ) :
//           (
//             <Text>No Extensions Registered</Text>
//           )
//       }
//     </View>

//   );
// }

const ExplorerNav = createBottomTabNavigator();
const EdgeStackNav = createStackNavigator();

const useEdgeEntities = (): NounServiceInstanceInternal<Entity<Edge>>[] => {
  const edgePlugin = React.useContext(GraphPluginContext);
  const [entities, setEntities] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);

  const loadData = async () => {
    const _entities = await edgePlugin?.getEdges();
    if (!_entities) { return; }
    setEntities(_entities);
  }

  React.useEffect(() => {
    loadData();
  }, []);

  return entities;
}

const usePredicateEntities = (): NounServiceInstanceInternal<Entity<Edge>>[] => {
  const { entities } = React.useContext(EntityContext);
  const primitive = React.useContext(PrimitivePluginContext);
  if (!primitive) { return []; }
  const predicates = entities.filter(entity => primitive.hasPrimitiveType(entity, "predicate"));
  return predicates;
}

const EdgeList = ({ }) => {
  const { selectEntity, openEditor } = React.useContext(EntityContext);
  const edges = useEdgeEntities();;
  return (
    <EntityTable
      entities={edges}
      onPress={entity => {
        selectEntity(entity);
        openEditor();
      }}
    />
  )
}

const PredicateList = ({ }) => {
  const { selectEntity, openEditor } = React.useContext(EntityContext);
  const predicates = usePredicateEntities();
  return (
    <EntityTable
      entities={predicates}
      onPress={entity => {
        selectEntity(entity);
        openEditor();
      }}
    />
  )
}

export const EntityListGraph = ({ selectedColumns, width, setWidth, setSortColumn, sortColumn, entities, pressHandler }) => {
  return <GraphView entityNouns={entities} />
}


const EdgeExplorer = () => {

  const edgePlugin = React.useContext(GraphPluginContext);
  const entites = useEdgeEntities();

  return (
    <>
      <SystemHeader system={PropertySystem}>
        <TouchableOpacity onPress={() => edgePlugin?.createEdge({ sub: "", pred: "", obj: "" }, true, [PersonalSystem])} style={{ height: 30, width: 30 }}>
          <Icon name="add-circle-outline" type="ionicon" size={30} />
        </TouchableOpacity>
      </SystemHeader>
      <ExplorerNav.Navigator initialRouteName="Graph" screenOptions={{ headerShown: false }}>
        <ExplorerNav.Screen options={{ tabBarLabelStyle: { fontFamily: "Poppins-SemiBold", letterSpacing: -0.25 }, tabBarIcon: () => <Icon name='list' type='material' color='#517fa4' /> }} name="Edges" component={EdgeList} />
        <ExplorerNav.Screen options={{ tabBarLabelStyle: { fontFamily: "Poppins-SemiBold", letterSpacing: -0.25 }, tabBarIcon: () => <Icon name='list' type='material' color='#517fa4' /> }} name="Predicates" component={PredicateList} />
        <ExplorerNav.Screen options={{ tabBarLabelStyle: { fontFamily: "Poppins-SemiBold", letterSpacing: -0.25 }, tabBarIcon: () => <Icon name='vector-square' type='material-community' color='#517fa4' /> }} name="Graph" component={GraphView} />
      </ExplorerNav.Navigator>
    </>


  );
}

const EdgeApp = () => {

  return (
    <EdgeStackNav.Navigator screenOptions={{ headerShown: false }}>
      <EdgeStackNav.Screen name="Explorer" component={EdgeExplorer} />
    </EdgeStackNav.Navigator>
  );

}

//
//  Graph
//

//  Type Creation
export class ExistingNodeError extends Error { }
export class ExistingEdgeError extends Error { }

//  Type Usage
export class MissingNodeError extends Error { }
export class MissingEdgeError extends Error { }

//  General Type Errors
export class MultipleNodesError extends Error { }
export class MultipleEdgesError extends Error { }

export class GraphTypeError extends Error { }


//  NOTE:  The type system we use here is simple and used only to differentiate nodes from edges.  All other application-level types should be expressed on the graph itself with edges.





export const GraphPluginContext = React.createContext<PropertyPlugin | undefined>(undefined);

// const Graph2System: System = {
//   id: "edge",
//   name: "Edge",
//   description: "Edge Modeler",
//   component: EdgeApp,
//   menuItem: { icon: { type: "material", name: "globe" }, backgroundColor: '#111111', iconColor: "#aaaaaa" },
//   labels: ["core"]
// }

const PropertySystem: System = {
  id: "property-system",
  pinned: true,
  priority: 2,
  color: "#aaaaaa",
  name: "Properties",  //  CONSDIER:  Properties / "Edges" / "Props" / "Relations"
  description: "Property Explorer",
  emoji: "🔗",
  component: EdgeApp,
  icon: { type: 'feather', name: 'link' },
  primaryColor: "#e31b54",
  backgroundColor: "#fff7f9",
}


export class PropertyPlugin extends CorePluginClass {

  public static details = {
    name: "Property",
    description: "Property Plugin",
    dependencies: [Hessia2Plugin.details.id, Entity2Plugin.details.id, PrimitivesPlugin.details.id],
    color: "#e31b54",
    icon: { type: 'ionicon', name: 'git-branch-outline' },
    backgroundColor: "fff7f9",
    id: "graph2"
  }

  public getEdges = async (): Promise<NounServiceInstanceInternal<Entity<Edge>>[]> => {
    const allEntities = await this.entity2.entityService.entityNounService.retrieveAll();
    let edges = await allEntities.filter(entity => {
      const { type } = entity.payload;
      if ((type?.type == "anonymous") && (type?.davelType?.type === "edge" as any)) { return true }
      return false;
    }) as NounServiceInstanceInternal<Entity<Edge>>[];
    return edges;
  }

  public filterByPredicate = (edges: NounServiceInstanceInternal<Entity<Edge>>[] = [], predicateId: string) => {
    return edges.filter(edge => edge.payload.value?.pred === predicateId);
  }

  public filterByObject = (edges: NounServiceInstanceInternal<Entity<Edge>>[] = [], objId: string) => {
    return edges.filter(edge => edge.payload.value?.obj === objId);
  }

  public filterBySubject = (edges: NounServiceInstanceInternal<Entity<Edge>>[] = [], subId: string) => {
    return edges.filter(edge => edge.payload.value?.sub === subId);
  }

  public getSubjects = async (edges: NounServiceInstanceInternal<Entity<Edge>>[] = []) => {
    const subjectIds = edges.map(edge => edge.payload?.value?.sub).filter(subId => !!subId) as string[];
    const subjects: NounServiceInstanceInternal<Entity>[] = [];
    for (const subId of subjectIds) {
      const sub = await this.entity2.entityService.getEntityNounById(subId);
      if (sub) {
        subjects.push(sub);
      }
    }
    return subjects;
  }

  public getObjects = async (edges: NounServiceInstanceInternal<Entity<Edge>>[] = []) => {
    const objIds = edges.map(edge => edge.payload.value?.obj).filter(objId => !!objId) as string[];
    const objects: NounServiceInstanceInternal<Entity>[] = [];
    for (const objId of objIds) {
      const obj = await this.entity2.entityService.getEntityNounById(objId);
      if (obj) {
        objects.push(obj);
      }
    }
    return objects;
  }

  //  CONSIDER:  Use the SearchTerms Habor expression system to start!!!
  //  CONSIDER:  Instead of explicit functions use chaining or some sort of extensible expression system.

  //  TODO:  I NEED to stay up to date on research, especially in terms of data science and information theory and AI / ML and all that.

  /**
   * Finds all edges for { predicateId } where { entityId } is the subject and returns the list of objects.
   * @param entityId 
   * @param predicateId 
   */
  public getOutgoingEntities = async (entityId: string, predicateId?: string) => {
    const edges = await this.getOutgoingEdges(entityId, predicateId);
    const objects = await this.getObjects(edges);
    return objects;
  }

  /**
 * Finds all edges for { predicateId } where { entityId } is the object and returns the list of subjects.
 * @param entityId 
 * @param predicateId 
 */
  public getIncomingEntities = async (entityId: string, predicateId?: string) => {
    const edges = await this.getIncomingEdges(entityId, predicateId);
    const subs = this.getSubjects(edges);
    return subs;
  }

  //  CONSIDER:  We could use a chaining feature on "Edge" to be able to filter by Predicate or any other aspect.

  public getOutgoingEdges = async (entityId: string, predicateId?: string): Promise<NounServiceInstanceInternal<Entity<Edge>>[]> => {
    let edges = await this.getEdges();
    edges = this.filterBySubject(edges, entityId);
    if (predicateId) { edges = this.filterByPredicate(edges, predicateId); }
    return edges;
  }

  public getIncomingEdges = async (entityId: string, predicateId?: string): Promise<NounServiceInstanceInternal<Entity<Edge>>[]> => {
    let edges = await this.getEdges();
    edges = this.filterByObject(edges, entityId);
    if (predicateId) { edges = this.filterByPredicate(edges, predicateId); }
    return edges;
  }

  public createEdge = async (edge: Edge, exported: boolean, owners: System[]) => {
    const edgeEntity: Entity = {
      owners: [PropertySystem, ...owners],
      id: uuid(),
      exported: exported,
      name: "Edge!",
      value: edge,
      type: { type: "anonymous", davelType: { type: "edge" as any } }
    };
    return await this.entity2.entityService.createEntity(edgeEntity);
  }


  public entityInSet = (set: NounServiceInstanceInternal<Entity>[], entity: NounServiceInstanceInternal<Entity>) => {
    return (set.find(_entity => _entity.payload.id === entity.payload.id)) ? true : false;
  }

  public getEdgeEntities = async (edge: NounServiceInstanceInternal<Entity<Edge>>) => {

    const subId = edge.payload.value?.sub;
    const predId = edge.payload.value?.pred;
    const objId = edge.payload.value?.obj;

    const sub = subId && await this.entity2.entityService.getEntityNounById(subId);
    const pred = predId && await this.entity2.entityService.getEntityNounById(predId);
    const obj = objId && await this.entity2.entityService.getEntityNounById(objId);

    const entities: NounServiceInstanceInternal<Entity>[] = [edge];
    if (sub && !this.entityInSet(entities, sub)) { entities.push(sub); }
    if (pred && !this.entityInSet(entities, pred)) { entities.push(pred); }
    if (obj && !this.entityInSet(entities, obj)) { entities.push(obj); }

    return entities;
  }

  public getEdgeSetEntities = async (edges: NounServiceInstanceInternal<Entity<Edge>>[]) => {
    const entities: NounServiceInstanceInternal<Entity>[] = [];
    for (const _edge of edges) {
      if (!this.entityInSet(entities, _edge)) { entities.push(_edge); }
      const edgeEntities = await this.getEdgeEntities(_edge);
      edgeEntities.forEach(_entity => {
        if (!this.entityInSet(entities, _entity)) { entities.push(_entity); }
      });
    }
    return entities;
  }

  public getOneHopEntities = async (entity: NounServiceInstanceInternal<Entity>) => {

    //  TODO:  Consider caching for incoming / outgoing and functions in general.

    //  Get Incoming
    const _incoming = await this.getIncomingEdges(entity.payload.id);

    //  Get Outgoing
    const _outgoing = await this.getOutgoingEdges(entity.payload.id);

    //  Combine Sets
    const allEdges = [..._incoming, ..._outgoing];

    //  Get the Entities
    const oneHopEntities = await this.getEdgeSetEntities(allEdges);

    //  Add this Entity
    if (!this.entityInSet(oneHopEntities, entity)) { oneHopEntities.push(entity); }

    return oneHopEntities;
  }

  public getEdgeById = async (edgeId: string) => {

    const allEdges = await this.entity2.entityService.entityNounService.retrieveAll();
    const matchingEdges = await allEdges.filter(edge => edge.payload.id === edgeId);
    if (matchingEdges.length > 1) {
      throw new MultipleEdgesError();
    }

    const matchingEdge = matchingEdges[0];
    if (!matchingEdge) {
      return undefined;
    }

    const entityData = matchingEdge.payload.data;
    if (!entityData) { throw new GraphTypeError(); }

    // if (entityData[Edge2Plugin.details.id]) {
    //   throw new GraphTypeError();
    // }

    return matchingEdge.payload;
  }

  protected entity2!: Entity2Plugin;
  protected primitive2!: PrimitivesPlugin;

  public createPredicate = async (entity: Entity) => {
    const predicate = await this.primitive2.createObject(entity, { type: "anonymous", davelType: { type: "predicate" as any } }, {});
    return predicate;
  }

  public install = async (program: any, { hessia2, entity2, primitive2 }: { primitive2: PrimitivesPlugin, hessia2: Hessia2Plugin, entity2: Entity2Plugin }) => {

    const _me = this;

    this.primitive2 = primitive2;
    this.entity2 = entity2;


    //  Register the Entity List View (the Graph!)
    const EntityListGraphView: EntityListView = {
      id: "graph",
      name: "Graph",
      icon: { name: "git-network-outline", type: "ionicon" },
      component: EntityListGraph
    }

    //  Register "Predicate" Davel Type
    //  NOTE:  We COULD model this on the graph with "Instance Of", but we already have enough primitives.  Might as well just use that.
    //  NOTE:  It's not strictly necessary to register predicates, but this makes for a better UX as we can differentiate those MEANT to act as predicates.
    //  TODO:  Make this valid.  Currently a placeholder.
    Davel.registerSDTDeserializer("predicate", () => {
      const dt = new Davel.DT<any, any>({});
      return Promise.resolve(dt);
    });

    //  Register "Predicate" UI
    registerDavelType({
      id: "predicate",
      name: "Predicate",
      color: "#eeeeee",
      icon: { type: 'ionicon', name: 'git-branch-outline' },
      renderer: (params) => <DavelForm onSubmit={params.update} value={params.value} schema={PredicateSchema} />,
      defaultSDT: { type: "predicate" }
    });

    //  Define the Predicate Metatype (Used to create an instance of the "predicate" type)
    //  TODO:  Make this valid.  Currently a placeholder.
    const SDTPredicateMetatype = {
      type: 'object' as 'object',
      required: true,
      extensible: false,
      properties: {
        ...Davel.SDTSerializedSchemaProperties,
        type: { type: 'option', options: ['keyword'] }
      }
    };

    //  Register SDT Schema
    registerSDTSchema("predicate", SDTPredicateMetatype);


    //  Register "Edge" Davel Type
    //  TODO:  Make this valid.  Currently a placeholder.
    Davel.registerSDTDeserializer("edge", () => {
      const dt = new Davel.DT<any, any>({});
      return Promise.resolve(dt);
    });

    //  Register "Edge" UI
    registerDavelType({
      id: "edge",
      name: "Edge",
      color: "#eeeeee",
      icon: { type: 'ionicon', name: 'git-branch-outline' },
      renderer: (params) => <DavelForm onSubmit={params.update} value={params.value} schema={EdgeSchema} />,
      defaultSDT: { type: "edge" }
    });


    //  Define the Edge Metatype (Used to create an instance of the "edge" type)
    //  TODO:  Make this valid.  Currently a placeholder.
    const SDTEdgeMetatype = {
      type: 'object' as 'object',
      required: true,
      extensible: false,
      properties: {
        ...Davel.SDTSerializedSchemaProperties,
        type: { type: 'option', options: ['keyword'] }
      }
    };

    //  Register SDT Schema
    registerSDTSchema("edge", SDTEdgeMetatype);

    //  TODO-CONSIDER:  Instead of an explicit table consider modifying the entity display row based on this type criteria.

    const EdgeTable = ({ edges = [] }: { edges?: NounServiceInstanceInternal<Entity<Edge>>[] }) => {

      const entityContext = React.useContext(EntityContext);

      const entities = entityContext.entities;

      const titleTextStyle = { fontFamily: 'Outfit-SemiBold', fontSize: 14 };
      const cellTextStyle = { fontFamily: 'Outfit-SemiBold', fontSize: 12 };

      return (

        <ScrollView style={{ backgroundColor: 'white' }}>
          <DataTable style={{ borderWidth: 0 }}>
            <DataTable.Header style={{ backgroundColor: 'white', borderWidth: 0 }}>
              <DataTable.Title textStyle={titleTextStyle}>Subject</DataTable.Title>
              <DataTable.Title textStyle={titleTextStyle}>Predicate</DataTable.Title>
              <DataTable.Title textStyle={titleTextStyle}>Object</DataTable.Title>
              <DataTable.Title textStyle={titleTextStyle}>Owners</DataTable.Title>
            </DataTable.Header>
            {
              edges.map(edge => (
                <DataTable.Row style={{ backgroundColor: 'white', borderBottomColor: '#eeeeee' }}>
                  <DataTable.Cell>
                    <Text style={cellTextStyle}>{entities.find(entity => entity.payload.id === edge.payload.value?.sub)?.payload.name}</Text>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <Text style={cellTextStyle}>{entities.find(entity => entity.payload.id === edge.payload.value?.pred)?.payload.name}</Text>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <Text style={cellTextStyle}>{entities.find(entity => entity.payload.id === edge.payload.value?.obj)?.payload.name}</Text>
                  </DataTable.Cell>
                  <DataTable.Cell>
                    <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                      {edge.payload.owners?.map(owner =>
                        <View style={{ marginLeft: 3 }}>
                          <SystemIcon {...owner} />
                        </View>
                      )}
                    </View>
                  </DataTable.Cell>
                </DataTable.Row>
              ))
            }
          </DataTable>
        </ScrollView>
      );
    }

    //  Register the Entity Extension
    entity2.registerEntityExtension({
      systemId: PropertySystem.id,
      icon: PropertySystem.icon,
      id: "edge-entity-extension",
      name: "Graph",
      description: "Displays Edge Information",
      DetailComponent: ({ entity }) => {

        const entityId = entity?.payload.id;

        const [outgoing, setOutgoing] = React.useState<NounServiceInstanceInternal<Entity<Edge>>[]>([]);
        const [incoming, setIncoming] = React.useState<NounServiceInstanceInternal<Entity<Edge>>[]>([]);
        const [oneHopEntities, setOneHopEntities] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);

        const loadEdges = async () => {
          if (!entityId) { return; }
          const _outgoing = await this.getOutgoingEdges(entityId);
          const _incoming = await this.getIncomingEdges(entityId);
          const _oneHopEntities = await this.getOneHopEntities(entity);

          setOutgoing(_outgoing);
          setIncoming(_incoming);
          setOneHopEntities(_oneHopEntities);
        }

        React.useEffect(() => {
          loadEdges();
        }, []);

        return (
          <View style={{ flex: 1, backgroundColor: 'white' }}>

            {/* Type System Card */}
            <SystemHeader breadcrumbs={false} system={PropertySystem} />

            <ScrollView style={{ padding: 30, flex: 1 }}>

              {/* Outgoing */}
              <View style={{ flexDirection: 'column' }}>
                <Text style={{ fontFamily: "Poppins-Bold", fontSize: 20, color: "#333333" }}>Outgoing</Text>
                <EdgeTable edges={outgoing} />
              </View>

              <View style={{ height: 20 }} />

              {/* Incomoing */}
              <View style={{ flexDirection: 'column' }}>
                <Text style={{ fontFamily: "Poppins-Bold", fontSize: 20, color: "#333333" }}>Incoming</Text>
                <EdgeTable edges={incoming} />
              </View>

              {/* Graph */}
              <View style={{ flexDirection: 'column', width: '100%', height: 700 }}>
                <Text style={{ fontFamily: "Poppins-Bold", fontSize: 20, color: "#333333" }}>Graph</Text>
                <GraphView entityNouns={oneHopEntities} />
              </View>

            </ScrollView>
          </View>


        );
      }
    })

    hessia2.registerHOC(({ children }) => {
      const hessia2Context = React.useContext(Hessia2Context);
      const entityContext = React.useContext(EntityContext);
      React.useEffect(() => {
        hessia2Context?.installSystem(PropertySystem, true);
        entityContext.registerEntityListView(EntityListGraphView)
      }, []);

      return (
        <GraphPluginContext.Provider value={this}>
          {children}
        </GraphPluginContext.Provider>
      );
    });


    return this;
  }
}

