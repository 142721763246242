import { CorePluginClass, Program } from 'halia';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { S3 } from 'aws-sdk';
import React, { useContext, useEffect, useState } from 'react';
import { Text } from 'react-native';
import { GalleryButton } from '../../gallery/components/common';
import { TextField } from '../../gallery/components/fields';
import { Hessia2Context, Hessia2Plugin } from '../Hessia2Plugin';
import { S3Config, getFromS3, saveToS3 } from '../../../packages/noun-service/noun-service';
import { PropertyPlugin } from './property-plugin';

// uploadToS3(bucketName, objectKey, data)
//   .then((response) => {
//     console.log('Object uploaded:', response.Location);
//   })
//   .catch((error) => {
//     console.error('Error uploading object:', error);
//   });

//  REFERENCE:  ChatGPT
async function getAllAsyncStorageItems() {
  // const allKeys = await AsyncStorage.getAllKeys();
  const graphValue = await AsyncStorage.getItem("graph");
  // const result = {};
  // allValues.forEach((value) => {
  //   const key = value[0];
  //   const jsonValue = value[1];
  //   result[key] = JSON.parse(jsonValue || "");
  // });
  return {
    "graph": JSON.parse(graphValue || "{}")
  }
}



export const S3SyncApp = () => {

  const [config, setConfig] = useState<S3Config>({ key: "", secret: "", region: "" });

  const loadConfig = async () => {
    const configStr = await AsyncStorage.getItem("s3_config");
    if (!configStr) { return; }
    const config: S3Config = JSON.parse(configStr);
    setConfig(config);
  }

  React.useEffect(() => {
    loadConfig();
  }, []);

  const save = async () => {
    const data = await getAllAsyncStorageItems();
    await saveToS3(data, config);
    alert("Saved to S3!");
  }

  //  REFERENCE:  ChatGPT
  const restore = async () => {
    const data = await getFromS3(config);
    // const allKeys = Object.keys(data);
    // await AsyncStorage.multiRemove(allKeys);
    // const entries = Object.entries(data) ;
    // await AsyncStorage.multiSet(entries);
    const graphData = data.graph;
    await AsyncStorage.setItem("graph", JSON.stringify(graphData));
  }


  const updateKey = async (_key: string) => {
    await AsyncStorage.setItem("s3_config", JSON.stringify({ ...config, key: _key }));
    await loadConfig();
  }

  const updateSecret = async (_secret: string) => {
    await AsyncStorage.setItem("s3_config", JSON.stringify({ ...config, secret: _secret }));
    await loadConfig();
  }

  return (
    <>
      <Text>Sync to S3</Text>
      <TextField value={config.key} title="Key" onSave={updateKey} mode="automatic" />
      <TextField value={config.secret} title="Secret" onSave={updateSecret} mode="automatic" />
      <GalleryButton onPress={save} title="Save" />
      <GalleryButton onPress={restore} title="Restore" />
    </>

  )
}

export class S3SyncPlugin extends CorePluginClass {

  public static details = {
    name: 'S3Sync Plugin',
    description: 'Syncs State to S3',
    dependencies: [PropertyPlugin.details.id, Hessia2Plugin.details.id],
    id: 's3'
  }

  public install = (program: Program, { graph2, hessia2 }: { graph2: PropertyPlugin, hessia2: Hessia2Plugin }) => {


    hessia2.registerHOC(({ children }) => {

      const hessia2Context = useContext(Hessia2Context);

      useEffect(() => {
        hessia2Context?.installSystem({
          name: "S3 Sync Plugin",
          description: "Syncs Async Storage to S3",
          emoji: "☁️",
          color: "#eeeeee",
          component: S3SyncApp
        })
      }, []);

      return (
        <>
          {children}
        </>
      );
    });

    return this;
  }
}