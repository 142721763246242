import * as React from 'react';
import { TextInput, View, ViewStyle } from "react-native";
import { primaryFontFamily } from "../../packages/kelp-bar/styles";
import { SearchContext } from "./SearchProvider";
import { useSizes } from "../../packages/kelp-bar/sizes-helper";
import { EntityContext, EntityTable } from "./plugins/entity-plugin";
import { flattenObjectToObject } from '../../packages/kelp-bar/utils';

//  NOTE:  We do NOT just want to search entities alone.  We want to be able to search ALL known patterns.  BUT, this is a start.
//  NOTE:  Using 'outline: none' to prevent browsers from giving an outline.  This isn't a known property so I use "any"
//  CONSIDER:  Search multiple hops (match based on neighboring matches = match by association)

export const SearchBar = ({ style }: { style?: ViewStyle }) => {
  const { search, setSearch } = React.useContext(SearchContext);
  return (
    <View style={[{ height: 40, backgroundColor: '#fdfdfd', borderRadius: 20, borderColor: '#eeeeee', borderWidth: 2, alignItems: 'center', flexDirection: 'row', paddingHorizontal: 15 }, style]}>
      <TextInput onChangeText={text => setSearch(text)} value={search} placeholder="Search" style={[{ fontFamily: primaryFontFamily, borderWidth: 0, width: '100%' }, { outline: 'none' } as any]} />
    </View>
  );
}

export const SearchResults = () => {

  const { entities } = React.useContext(EntityContext);
  const { search } = React.useContext(SearchContext);
  const { isDesktop } = useSizes();

  const matchingEntities = React.useMemo(() => {
    return entities.filter(entity => {
      const flatEntity = flattenObjectToObject(entity);
      const matchingKeys: string[] = [];
      Object.keys(flatEntity).forEach(key => {
        const value: string = flatEntity[key];
        if (!!value && value.toLowerCase && value.toLowerCase().includes(search.toLowerCase())) {
          matchingKeys.push(key);
        }
      });
      return matchingKeys.length > 0 ? true : false;
    });

  }, [entities, search]);

  return (
    <View style={{ flex: 1 }}>
      {!isDesktop && (
        <View style={{ width: '100%', padding: 10 }}>
          <SearchBar />
        </View>
      )}
      <EntityTable entities={matchingEntities} />
    </View>
  );
};